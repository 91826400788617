export const config = {
  firebase: {
    apiKey: 'AIzaSyAr-SmbEaGTpovygy6QVpAp0SYQtxeKKm0',
    authDomain: 'snapmentor-1.firebaseapp.com',
    databaseURL: 'https://snapmentor-1.firebaseio.com',
    projectId: 'snapmentor-1',
    storageBucket: 'snapmentor-1.appspot.com',
    messagingSenderId: '302280329608',
    appId: '1:302280329608:web:e2582bd8070065b7',
    measurementId: 'G-YRRK3ZBXHL',
  },
  cloudMessaging: {
    vapidKey:
      'BFXyM2M44MjtaQSH4B3s1jycvLnm9v3DcWgqwC1JoqDnvX0I0dW-8BvDZ4s4OuR1v31pduColECCdI99_jv-E1A',
  },
  backend: {
    url: 'https://europe-west1-snapmentor-1.cloudfunctions.net',
    externalApiUrl: 'https://api-test.snapmentor.no',
  },
  sentry: {
    dsn: 'https://f7e5e7f1902940f09a4732ad668667d2@sentry.io/1757023',
  },

  clientIds: {
    GOOGLE: '302280329608-64e587di9r3g48nk0c7lsdq7t2lkod2m.apps.googleusercontent.com',
    FEIDE: '11e50bf0-17fb-47ab-860f-ab1022c545be',
    VIPPS: '0eb6b0d4-69fb-4b81-81ce-31f91ed76d6a',
  },
  appVersion: process.env.REACT_APP_VERSION,
  stripe: {
    publishableKey: 'pk_test_hFHXs9lIjMZOiaqXp6D2SwSX00AfqbYr2P',
    clientId: 'ca_H8hlkMtlQuapYBun07AZJrs67vwkSqle',
  },
  mixpanel: {
    token: 'b3147091d8b6e435d0dfeb43c9e3669c',
  },
  fallbackTenant: process.env.FALLBACK_TENANT || 'metered-dev',
  azure: {
    tenant: 'snapmentordev.onmicrosoft.com',
    tenantName: 'snapmentordev',
    clientId: 'cfb60a72-57fd-4cdc-b3f0-10eec6dcfff6',
    fallbackPolicy: 'B2C_1A_signup_signin_feide_email',
    scopes: [
      'https://snapmentordev.onmicrosoft.com/cfb60a72-57fd-4cdc-b3f0-10eec6dcfff6/User.Read',
      'offline_access',
    ],
  },
};
