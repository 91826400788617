import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ProfileCircle from '../../_atoms/ProfileCircle';
import { CenteredContainer, EllipsisOverflowH4 } from '../../../styled';
import ImageLoader from '../ImageLoader/ImageLoader';
import { Message } from '../../../services/db';

type Props = {
  name?: string;
  chatRoomId: string;
  latestMessage: string;
  initialMessage?: Message;
  avatarColor?: string;
  active?: boolean;
};

export const ActiveDiscussionCard: React.FC<Props> = props => {
  const { name, latestMessage, initialMessage, chatRoomId, avatarColor, active } = props;
  const { t } = useTranslation('ChatRoom');
  return (
    <Container active={active}>
      <RowContainer>
        <Centered>
          <ProfileCircle width={40} name={name} color={avatarColor} />
        </Centered>
        <TextContainer>
          <EllipsisOverflowH4>{name || t('No mentor yet')}</EllipsisOverflowH4>
          <p>{latestMessage}</p>
        </TextContainer>
      </RowContainer>
      {initialMessage && initialMessage.imageId && (
        <ImageContainer>
          <ImageLoader
            onImageClicked={() => {
              return null;
            }}
            chatRoomId={chatRoomId}
            imageId={initialMessage.imageId}
          />
        </ImageContainer>
      )}
      {initialMessage && initialMessage.text && (
        <p style={{ padding: 20 }}>{initialMessage.text}</p>
      )}
    </Container>
  );
};

const Centered = styled(CenteredContainer)`
  padding: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const TextContainer = styled.div`
  text-align: left;
  max-width: 70%;
  h4,
  p {
    margin: 0;
  }

  h4 {
    font-weight: 350;
    font-size: 17px;
  }

  p {
    font-weight: 325;
    font-size: 13px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  padding: 10px;
  background-color: #f5f4f1;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  ${({ active }) =>
    active ? 'box-shadow: 5px 5px 10px #d9d9d9, -5px -5px 10px #ffffff' : 'none'};
  &:hover {
    cursor: pointer;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15); */
    box-shadow: 5px 5px 10px #d9d9d9, -5px -5px 10px #ffffff;
  }
`;
