import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Topic, ById } from '../../../modules';
import { TopicCollection, NewTopicForm } from '../../_molecules/TopicCollection';
import { StyledButton } from '../../../styled';
import { CircledButton } from '../../_atoms/CircledButton';

type Props = {
  onSave: (topics: ById<Topic>) => void;
  serverTopics?: ById<Topic>;
};

export const TopicsManager: React.FC<Props> = props => {
  const { onSave, serverTopics } = props;
  const { t } = useTranslation('Admin');

  const [topics, setTopics] = useState<ById<Topic>>({});

  const [isCreatingTopic, setIsCreatingTopic] = useState<boolean>(false);

  const handleTopicChanged = (topic: Topic) => {
    setTopics({ ...topics, [topic.id]: topic });
  };

  const handleTopicDeleted = (topicId: string) => {
    const newTopics = { ...topics };
    delete newTopics[topicId];
    setTopics(newTopics);
  };

  useEffect(() => {
    serverTopics && setTopics(serverTopics);
  }, [serverTopics, setTopics]);

  return (
    <Container>
      <TopicCollection
        onDelete={handleTopicDeleted}
        topics={topics}
        onChange={handleTopicChanged}
      />
      <div style={{ paddingBottom: 20 }}>
        {isCreatingTopic && (
          <NewTopicForm
            onCancel={() => setIsCreatingTopic(false)}
            onCreate={topicName => {
              setTopics({
                ...topics,
                [topicName.toLowerCase()]: {
                  id: topicName.toLowerCase(),
                  name: topicName,
                  subTopics: {
                    all: {
                      id: 'all',
                      name: t('All'),
                      level: 0,
                    },
                  },
                },
              });
              setIsCreatingTopic(false);
            }}
          />
        )}

        <CircledButton
          backgroundColor="#5CC9E1"
          onClick={() => setIsCreatingTopic(true)}
          text={t('newCategory', { defaultValue: 'Add a new category' })}
          icon="New"
        />
      </div>

      <StyledButton style={{ width: 100 }} onClick={() => onSave(topics)}>
        {t('Save')}
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
