import React from 'react';
import styled from 'styled-components';

import { Card } from '../../_atoms/Card';
import DescriptionTooltip from '../../_atoms/DescriptionTooltip';

type Props = {
  title: string;
  value?: number | string;
  tooltipText?: string;
};

export const ResultCard: React.FC<Props> = props => {
  const { title, value, tooltipText } = props;
  return (
    <Card>
      <div style={{ padding: '15px 50px' }}>
        <TitleContainer style={{ display: 'flex' }}>
          <Title>{title}</Title>
          {tooltipText && <DescriptionTooltip text={tooltipText} />}
        </TitleContainer>
        <Number>{value !== undefined ? value : 'N/A'}</Number>
      </div>
    </Card>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: #9ea2be;
`;

const Title = styled.span`
  margin: 0;
  font-size: 16px;
  font-weight: 550;
  text-transform: capitalize;
  margin-right: 10px;
`;

const Number = styled.p`
  margin: 0;
  font-size: 45px;
  color: #9ea2be;
`;
