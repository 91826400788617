import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { message, Modal } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useTypedSelector, useIsDesktop, useBlob, useMetrics } from '../../../hooks';
import {
  getMatchMakingState,
  resetMatchMaking,
  fetchMatchMaking,
  getClaimDiscussion,
  claimDiscussion,
  claimDiscussionReset,
  getTopics,
} from '../../../modules';
import FullSizeModal from '../../FullSizeModal';
import DiscussionRequest from '../../_molecules/DiscussionRequest';
import ConditionalWrapper from '../../_atoms/ConditionalWrapper';

type Props = {
  matchMakingId?: string;
  onDone?: () => void;
  visible: boolean;
};
export default (props: Props) => {
  const { matchMakingId, onDone, visible } = props;
  const { t } = useTranslation('claimDiscussion');
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const metrics = useMetrics();
  const history = useHistory();
  const { error, matchmakingElement } = useTypedSelector(getMatchMakingState);
  const topics = useTypedSelector(getTopics);

  const matchMakingBlob = useBlob(
    matchmakingElement?.chatRoomId,
    matchmakingElement?.chatRoomData?.messages &&
      matchmakingElement?.chatRoomData?.messages[0]?.imageId,
  );

  const { error: errorClaimDiscussion, claimedChatRoomId, isLoading } = useTypedSelector(
    getClaimDiscussion,
  );

  const [openPreview, setOpenPreview] = useState(false);

  useEffect(() => {
    if (matchMakingId && visible) {
      dispatch(fetchMatchMaking(matchMakingId));
    }
  }, [dispatch, matchMakingId, visible]);

  useEffect(() => {
    if (error && visible) message.error(error);
  }, [error, visible]);

  useEffect(() => {
    if (visible && matchmakingElement) {
      metrics.logEvent('discussion', { openPreview: true });
      setOpenPreview(true);
    }
  }, [matchmakingElement, metrics, visible]);

  useEffect(() => {
    if (claimedChatRoomId && visible) {
      message.success(t('The discussion was claimed. Go to the overview to find it.'));
      metrics.logEvent('discussion', { claimed: true });
      dispatch(claimDiscussionReset());
      if (isDesktop) {
        history.replace(`/main?chatRoomId=${claimedChatRoomId}`);
      } else {
        history.replace(`/main/chatroom/${claimedChatRoomId}`);
      }
      onDone && onDone();
      setOpenPreview(false);
    }
  }, [claimedChatRoomId, dispatch, history, isDesktop, metrics, onDone, t, visible]);

  useEffect(() => {
    if (errorClaimDiscussion && visible) {
      message.error(
        t(
          'The discussion was not claimed. You will be notified when a new chat is available',
        ),
      );
      metrics.logEvent('discussion.claimed.error');
      dispatch(claimDiscussionReset());
      setOpenPreview(false);
      onDone && onDone();
    }
  }, [dispatch, errorClaimDiscussion, metrics, onDone, t, visible]);

  const handleDecline = () => {
    metrics.logEvent('discussion', { declined: true });
    setOpenPreview(false);
    dispatch(resetMatchMaking());
  };

  const handleAccept = () => {
    if (matchmakingElement) dispatch(claimDiscussion(matchmakingElement.id));
  };

  if (!matchmakingElement) return null;

  const selectedTopics = matchmakingElement?.chatRoomData?.additionalInfo?.topic || [
    undefined,
    undefined,
  ];
  const [topicId, subTopicId] = selectedTopics;
  const topic = topics && topicId && topics[topicId];
  const topicName = topic ? topic.name : '-';
  const subTopic = topic && subTopicId && topic.subTopics && topic.subTopics[subTopicId];
  const subTopicName = subTopic ? subTopic.name : '-';
  return (
    <ConditionalWrapper
      condition={isDesktop}
      falsyWrapper={(children: React.ReactNode) => (
        <FullSizeModal
          visible={openPreview}
          onOk={() => null}
          onCancel={() => setOpenPreview(false)}
        >
          {children}
        </FullSizeModal>
      )}
      truthyWrapper={(children: React.ReactNode) => (
        <StyledModal
          visible={openPreview}
          onOk={() => null}
          onCancel={() => setOpenPreview(false)}
          footer={null}
          closable={false}
          centered
          style={{
            maxHeight: '80%',
          }}
          bodyStyle={{ height: '80%' }}
        >
          {children}
        </StyledModal>
      )}
    >
      <DiscussionRequest
        onDecline={handleDecline}
        onAccept={handleAccept}
        topic={topicName}
        subtopic={subTopicName}
        isLoading={isLoading}
        showCountdown={false}
        textMessage={
          matchmakingElement?.chatRoomData?.messages &&
          matchmakingElement?.chatRoomData?.messages[0].text
        }
        image={matchMakingBlob?.objectUrl}
      />
    </ConditionalWrapper>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 50px;
  }
`;
