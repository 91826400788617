import React from 'react';
import styled from 'styled-components';

import theme from '../../../theme';

type StepStatusBarProps = {
  numsteps: number;
  currentStep: number;
  onSelectStep?: (step: number) => void;
};
export const Steps = (props: StepStatusBarProps) => {
  const { numsteps, currentStep } = props;
  const steps = new Array(numsteps).fill(0).map((e, i) => e + i);
  return (
    <StatusBar>
      {steps.map((s, index) =>
        index <= currentStep ? <ActiveCircle key={s} /> : <Circle key={s} />,
      )}
    </StatusBar>
  );
};

const StatusBar = styled.div`
  display: flex;
  align-items: center;
`;
const Circle = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 25px;
  background-color: ${theme.colors.white};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-right: 2px;
  margin-left: 2px;
`;

const ActiveCircle = styled(Circle)`
  background-color: #364045;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export default Steps;
