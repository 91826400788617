import Home from './Home';
import Settings from './Settings';
import Plus from './Plus';
import Key from './Key';
import User from './User';
import Google from './Google';
import ChevronRight from './ChevronRight';
import ChevronLeft from './ChevronLeft';
import Camera from './Camera';
import Paperclip from './Paperclip';
import Send from './Send';
import Vipps from './Vipps';
import Email from './Email';
import Feide from './Feide';
import New from './New';
import Profile from './Profile';
import Logo from './Logo';
import Close from './Close';
import Send2 from './Send2';
import Dashboard from './Dashboard';
import Reports from './Reports';
import Logout from './Logout';
import Cogs from './Cogs';
import Users from './Users';
import Trash from './Trash';
import ChatBubble from './ChatBubble';
import ArrowLeft from './ArrowLeft';
import Edit from './Edit';
import List from './List';
import Document from './Document';
import Shield from './Shield';

export const Icons = {
  Home,
  Document,
  Shield,
  List,
  Settings,
  Plus,
  ChevronRight,
  Key,
  User,
  Google,
  ChevronLeft,
  Camera,
  Paperclip,
  Send,
  Vipps,
  Email,
  Feide,
  New,
  Profile,
  Logo,
  Close,
  Send2,
  Dashboard,
  Reports,
  Logout,
  Cogs,
  Users,
  Trash,
  ChatBubble,
  ArrowLeft,
  Edit,
};
