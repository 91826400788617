import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Spin, Table, Modal, Dropdown, Menu, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useTypedSelector } from '../../hooks';
import {
  convertToMentor,
  getIsLoadingAdmin,
  getAdminUserTableList,
  getMentorAccessRequests,
  adminDeleteUser,
} from '../../modules';
import { RoleUser, MSClaims } from '../../services/backendService';
import theme from '../../theme';
import { Icons } from '../Icons';
import { UserType } from '../../services/db';

import { adminUserTableColumns } from './AdminTableColumns';
const AdminUserTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Admin');
  const users = useTypedSelector(getAdminUserTableList);
  const mentorAccessRequests = useTypedSelector(getMentorAccessRequests);
  const isLoading = useTypedSelector(getIsLoadingAdmin);
  const setClaims = useCallback(
    (claims: MSClaims, uid: string) => {
      dispatch(convertToMentor(uid, claims));
    },
    [dispatch],
  );
  const getUserNickName = (user: RoleUser & { userType?: UserType }) =>
    user.userType?.nickName || '-';
  const getUserEmail = (user: RoleUser) => user.email || '-';
  const userList = useMemo(
    () =>
      users.map(user => {
        const roles = user.roles;
        return {
          roles: user.roles,
          uid: user.id,
          name: user.name || '-',
          nickName: getUserNickName(user),
          email: getUserEmail(user),
          createdAt: moment(user.createdAt).format('MM/DD/YYYY') || '-',
          creationTime: moment(user.createdAt),
          hasOpenMentorAccessRequest: !!mentorAccessRequests[user.id],
          role: (
            <div>
              {roles.mentor && <Tag color="#7FD4DA">mentor</Tag>}
              {roles.admin && <Tag color="#9ea2be">admin</Tag>}
              {roles.student && <Tag color="#9ea2be">student</Tag>}
            </div>
          ),
          actions: (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="0"
                    disabled={!roles.mentor && !roles.admin}
                    onClick={() =>
                      setClaims(
                        {
                          mentor: false,
                          admin: false,
                          student: true,
                        },
                        user.id,
                      )
                    }
                  >
                    {t('setUser', { defaultValue: 'Set as user' })}
                  </Menu.Item>
                  <Menu.Item
                    key="1"
                    disabled={roles.admin}
                    onClick={() =>
                      setClaims(
                        {
                          mentor: false,
                          admin: true,
                          student: false,
                        },
                        user.id,
                      )
                    }
                  >
                    {t('setAdmin', { defaultValue: 'Set as admin' })}
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    disabled={roles.mentor}
                    onClick={() =>
                      setClaims(
                        {
                          mentor: true,
                          admin: false,
                          student: false,
                        },
                        user.id,
                      )
                    }
                  >
                    {t('setMentor', { defaultValue: 'Set as mentor' })}
                  </Menu.Item>

                  <Menu.Item
                    key="delete"
                    onClick={() => {
                      dispatch(adminDeleteUser(user.id));
                    }}
                  >
                    <DeleteContainer>
                      <Icons.Trash /> {t('deleteUser', { defaultValue: 'Delete user' })}
                    </DeleteContainer>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <MoreOutlined
                style={{
                  fontSize: '20px',
                  padding: '0.5rem',
                  color: '#4f4f4f',
                }}
              />
            </Dropdown>
          ),
        };
      }),
    [users, mentorAccessRequests, t, setClaims, dispatch],
  );

  if (isLoading.loadingUsers)
    return (
      <Container>
        <Spin />
      </Container>
    );

  return (
    <Container>
      <Table
        rowKey="uid"
        dataSource={userList}
        columns={adminUserTableColumns(t)}
        expandRowByClick
      />
      <Modal title={t('Converting')} visible={isLoading.convertingMentor} footer={null}>
        <CenterContainer>
          <Spin />
        </CenterContainer>
      </Modal>
    </Container>
  );
};

const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    height: 16px;
    width: 16px;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  border-radius: 10px;
  text-align: center;
  height: 100%;
  width: 100%;

  .ant-table {
    background: none;
  }
  thead > tr {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    /* background-color: ${theme.colors.tableHeader}; */
  }
  & th.ant-table-cell {
    background-color: ${theme.colors.tableHeader};
    /* background: transparent; */
    /* border-radius: inherit; */
  }
  .ant-table thead > tr > th {
    background-color: ${theme.colors.tableHeader};
    color: #ffffff;
    font-weight: bold;
    border-bottom: none;
  }
  & .ant-table-tbody > tr > td {
    padding: 10px 16px;
  }

  & .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  & .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  .ant-pagination-item {
    &:hover,
    &:focus {
      border-color: ${theme.colors.tableHeader};
      color: ${theme.colors.tableHeader};
    }
    & a {
      &:hover,
      &:focus {
        color: ${theme.colors.tableHeader};
      }
    }
  }

  .anticon {
    color: white;
  }

  & .ant-table-thead > tr > th .anticon-filter {
    color: white;
  }

  & .ant-table-filter-dropdown-link {
    color: ${theme.colors.tableHeader};
  }
  & .ant-pagination-item-active {
    border-color: ${theme.colors.tableHeader};
    /* &:hover {
      border-color: ${theme.colors.tableHeader};
    } */
  }
  & .ant-pagination-item-active a {
    color: ${theme.colors.tableHeader};
    &:hover {
      color: ${theme.colors.tableHeader};
    }
  }
  & .ant-pagination-item-link {
    &::selection {
      border-color: ${theme.colors.tableHeader};
      color: ${theme.colors.tableHeader};
    }
    &:hover {
      border-color: ${theme.colors.tableHeader};
      color: ${theme.colors.tableHeader};
    }
  }

  &
    .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background-color: #d0e6f1 !important;
  }
  & .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: ${theme.colors.tableHeader};
    color: ${theme.colors.tableHeader};
  }
  & .ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
    color: #ffffff;
  }

  & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #eeeeee;
  }
  /* & .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
  } */
`;

export default AdminUserTable;
