import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { RightSideEmptyState } from '../../_molecules/RightSideEmptyState';
import ChatRoom from '../ChatRoom';
import { ChatRoomConnector } from '../ChatRoomRouteConnector/ChatRoomConnector';
import { useQuery } from '../../../hooks';
import { NewDiscussion } from '../NewDiscussion';

export default () => {
  const { path } = useRouteMatch();
  const { query } = useQuery();
  const history = useHistory();

  const chatRoomId = query.get('chatRoomId');
  const newDiscussion = query.get('newDiscussion');
  if (chatRoomId)
    return (
      <ChatRoomConnector chatRoomId={chatRoomId}>
        <ChatRoom />
      </ChatRoomConnector>
    );

  if (newDiscussion) return <NewDiscussion />;

  if (!chatRoomId)
    return (
      <RightSideEmptyState onClick={() => history.push(`${path}?newDiscussion=true`)} />
    );
  return null;
};
