export const QUEUE_REQUESTED = 'MENTOR/QUEUE_REQUESTED';
export const QUEUE_RECEIVED = 'MENTOR/QUEUE_RECEIVED';
export const QUEUE_RECEIVED_ERROR = 'MENTOR/QUEUE_RECEIVED_ERROR';

export const QUEUE_ITEM_CLAIMED = 'MENTOR/QUEUE_ITEM_CLAIMED';
export const QUEUE_ITEM_CLAIMED_SUCCESS = 'MENTOR/QUEUE_ITEM_CLAIMED_SUCCESS';
export const QUEUE_ITEM_CLAIMED_ERROR = 'MENTOR/QUEUE_ITEM_CLAIMED_ERROR';
export const QUEUE_ITEM_CLAIMED_RESET = 'MENTOR/QUEUE_ITEM_CLAIMED_RESET';

export const DISCUSSION_CLAIMED = 'MENTOR/DISCUSSION_CLAIMED';
export const DISCUSSION_CLAIMED_SUCCESS = 'MENTOR/DISCUSSION_CLAIMED_SUCCESS';
export const DISCUSSION_CLAIMED_ERROR = 'MENTOR/DISCUSSION_CLAIMED_ERROR';
export const DISCUSSION_CLAIMED_RESET = 'MENTOR/DISCUSSION_CLAIMED_RESET';
