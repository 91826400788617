import React from 'react';
import { useStore } from 'react-redux';
import AzureAD, { MsalAuthProvider } from 'react-aad-msal';

import { useTypedSelector } from '../hooks';
import { getIsAuthInitializing } from '../modules';
import Loader from '../components/Loader';

export type Auth = firebase.User | null;

const AuthOrLoader = ({ children }: { children: React.ReactChild }) => {
  const isInitializing = useTypedSelector(getIsAuthInitializing);
  if (!isInitializing) return <>{children}</>;
  return <Loader />;
};

export const AuthProviderContext = React.createContext<MsalAuthProvider | undefined>(
  undefined,
);

const AuthSubscriber = ({
  children,
  authProvider,
}: {
  children: React.ReactChild;
  authProvider: MsalAuthProvider;
}) => {
  const store = useStore();
  return (
    <AuthOrLoader>
      <AuthProviderContext.Provider value={authProvider}>
        <AzureAD provider={authProvider} reduxStore={store}>
          {() => <>{children}</>}
        </AzureAD>
      </AuthProviderContext.Provider>
    </AuthOrLoader>
  );
};

export default AuthSubscriber;
