import { combineReducers } from 'redux';

import { APIState, DEFAULT_API_STATE } from '../utils';
import { MatchmakingElement } from '../../services/db';
import { AppState } from '../rootReducer';

import {
  NOTIFICATION_RECEIVED,
  NOTIFICATION_SUBSCRIBE,
  NOTIFICATION_REMOVED,
  MATCHMAKING_REQUESTED,
  MATCHMAKING_RECEIVED,
  MATCHMAKING_RESET,
  MATCHMAKING_RECEIVED_ERROR,
  NEW_VERSION_AVAILABLE,
  UPDATE_SERVICE_WORKER,
  UPDATE_SERVICE_WORKER_SUCCESS,
} from './actionTypes';

import { RootAction } from '..';

export interface NotificationState {
  show: boolean;
  title: string;
  body: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  isListening: boolean;
}

const emptyNotification = {
  show: false,
  title: '',
  body: '',
};

const initialState: NotificationState = {
  ...emptyNotification,
  isListening: false,
};

const notificationsReducer = (
  state = initialState,
  action: RootAction,
): NotificationState => {
  switch (action.type) {
    case NOTIFICATION_RECEIVED:
      return { ...state, ...action.payload, show: true };
    case NOTIFICATION_REMOVED:
      return { ...state, ...emptyNotification };
    case NOTIFICATION_SUBSCRIBE:
      return { ...state, isListening: true };
    default:
      return state;
  }
};

type MatchMakingState = APIState & {
  matchmakingElement?: MatchmakingElement;
};

export const matchMakingReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): MatchMakingState => {
  switch (action.type) {
    case MATCHMAKING_RESET:
      return DEFAULT_API_STATE;
    case MATCHMAKING_REQUESTED:
      return { ...state, isLoading: true };
    case MATCHMAKING_RECEIVED:
      return { ...state, isLoading: false, matchmakingElement: action.payload };
    case MATCHMAKING_RECEIVED_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

type ServiceWorkerState = {
  isNewVersionAvailable?: boolean;
  registration?: ServiceWorkerRegistration;
  isUpdating?: boolean;
};

export const serviceWorkerReducer = (
  state = {},
  action: RootAction,
): ServiceWorkerState => {
  switch (action.type) {
    case NEW_VERSION_AVAILABLE:
      return { ...state, isNewVersionAvailable: true, registration: action.payload.reg };
    case UPDATE_SERVICE_WORKER:
      return { ...state, isUpdating: true };
    case UPDATE_SERVICE_WORKER_SUCCESS:
      return { ...state, isUpdating: false };
    default:
      return state;
  }
};

export const notificationReducer = combineReducers({
  notifications: notificationsReducer,
  matchmaking: matchMakingReducer,
  serviceWorker: serviceWorkerReducer,
});

// selectors
export const getNotifications = (state: AppState) => state.notification.notifications;

export const getMatchMakingState = (state: AppState) => state.notification.matchmaking;
export const getIsNewVersionAvailable = (state: AppState) =>
  state.notification.serviceWorker.isNewVersionAvailable;
export const getServiceWorker = (state: AppState) => state.notification.serviceWorker;
