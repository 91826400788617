import { createAction } from 'typesafe-actions';

import { Auth } from '../../providers/AuthSubscriber';
import { OAuthProvider } from '../../services/backendService';
import { RouterHistory } from '../../globalTypes';

import * as ActionTypes from './actionTypes';
import { Claims } from './reducer';

export const setUser = createAction(
  ActionTypes.SET_USER,
  action => (auth: Auth, claims: Claims) => action({ auth, claims }),
);

export const loadAuth = createAction(ActionTypes.LOAD_AUTH);
export const initFreshchat = createAction(
  ActionTypes.INIT_FRESHCHAT,
  action => (id: string) => action({ id }),
);
export const authLoadedNoUser = createAction(ActionTypes.AUTH_LOADED_NO_USER);

export const signIn = createAction(
  ActionTypes.SIGN_IN,
  action => (data: { email: string; password: string }) => action(data),
);

export const register = createAction(
  ActionTypes.REGISTER,
  action => (
    data: { email: string; password: string; firstName: string; lastName: string },
    history: RouterHistory,
  ) => action({ ...data, history }),
);

export const signInSuccess = createAction(ActionTypes.SIGN_IN_SUCCESS);
export const signInError = createAction(
  ActionTypes.SIGN_IN_ERROR,
  action => (errorMessage?: string) => action(errorMessage),
);
export const registerError = createAction(
  ActionTypes.REGISTER_ERROR,
  action => (errorMessage?: string) => action(errorMessage),
);
export const registerSuccess = createAction(ActionTypes.REGISTER_SUCCESS);
export const signOut = createAction(ActionTypes.SIGN_OUT);
export const signOutSuccess = createAction(ActionTypes.SIGN_OUT_SUCCESS);
export const signInGoogle = createAction(ActionTypes.SIGN_IN_GOOGLE);
export const signInGoogleRedirectReturn = createAction(
  ActionTypes.SIGN_IN_GOOGLE_REDIRECTED_RETURN,
);
export const noUserToRedirect = createAction(ActionTypes.NO_USER_FOR_REDIRECT);
export const isDoneLoading = createAction(ActionTypes.IS_DONE_LOADING);
export const sendResetPasswordEmail = createAction(
  ActionTypes.RESET_PASSWORD_EMAIL,
  action => (email: string) => action(email),
);
export const sendResetPasswordEmailSuccess = createAction(
  ActionTypes.RESET_PASSWORD_EMAIL_SUCCESS,
);

export const deleteUser = createAction(ActionTypes.DELETE_USER);

export const deleteUserSuccess = createAction(ActionTypes.DELETE_USER_SUCCESS);

export const deleteUserFailure = createAction(ActionTypes.DELETE_USER_FAILURE);
export const getOAuthTokenAction = createAction(
  ActionTypes.GET_OAUTH_TOKEN,
  action => (code: string, provider: OAuthProvider, state: string) =>
    action({ code, provider, state }),
);
export const getOAuthTokenSuccess = createAction(
  ActionTypes.GET_OAUTH_TOKEN_SUCCESS,
  action => (token: string) => action(token),
);

export const getOAuthTokenFailureLogin = createAction(
  ActionTypes.SIGN_IN_ERROR,
  action => (error: string) => action(error),
);

export const getOAuthTokenFailureRegister = createAction(
  ActionTypes.REGISTER_ERROR,
  action => (error: string) => action(error),
);
export const resetAction = createAction(ActionTypes.RESET);
