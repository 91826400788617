import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
  active: 0 | 1;
  setActive: (num: 0 | 1) => void;
};
export const Tabs = (props: Props) => {
  const { active, setActive } = props;
  const { t } = useTranslation('home');
  return (
    <Container>
      <Tab active={active === 0} role="button" onClick={() => setActive(0)}>
        <p>{t('Active')}</p>
      </Tab>
      <Tab active={active === 1} role="button" onClick={() => setActive(1)}>
        <p>{t('Finished')}</p>
      </Tab>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  & > div {
    flex: 1;
  }
`;

const Tab = styled.div<{ active: boolean }>`
  position: relative;
  color: ${({ active }) => (active ? '#6c6c6c' : '#d3d3d3')};
  font-size: 20px;
  margin: 5px;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  & p {
    margin: 0;
    font-weight: 350;
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -30%;
    transform: translateX(-50%);
    width: 90%;
    height: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color: ${({ active }) => (active ? '#6c6c6c' : 'transparent')};
    border-radius: 25px;
  }
`;
