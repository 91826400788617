import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import theme from '../../../theme';

interface Props {
  onClick: () => void;
  isAvailable?: boolean;
}

export const MentorButton: React.FC<Props> = props => {
  const { onClick, isAvailable } = props;
  const { t } = useTranslation('home');

  return (
    <Container onClick={onClick} isAvailable={isAvailable}>
      <span>{t(isAvailable ? 'Online' : 'Go online')}</span>
    </Container>
  );
};

const Container = styled.button<{ isAvailable?: boolean }>`
  position: relative;
  background-color: ${({ isAvailable }) =>
    isAvailable ? theme.colors.orange : '#585858'};
  color: #ffffff;
  height: 80px;
  width: 80px;
  border-radius: 1000px;
  border: none;
  span {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  cursor: pointer;
  touch-action: manipulation;

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:active {
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${({ isAvailable }) => (isAvailable ? '#ffac68' : '#7D7D7D')};
  }
`;
