import { createAction } from 'typesafe-actions';

import { PendingReview } from '../../services/db';
import { RatingReview, GeneralUserFeedback } from '../../services/database/types';

import * as ActionTypes from './actionTypes';
import { NotificationTemplate } from './reducer';

export const subscribeToReviews = createAction(ActionTypes.REVIEWS_SUBSCRIBED);
export const reviewsUpdated = createAction(
  ActionTypes.REVIEWS_UPDATED,
  action => (pending: PendingReview[], lastReviewed: number) =>
    action({
      pending,
      lastReviewed,
    }),
);
export const promptReview = createAction(
  ActionTypes.PROMPT_REVIEW,
  action => (pending: PendingReview) => action(pending),
);
export const reviewWasSeen = createAction(
  ActionTypes.REVIEW_SEEN,
  action => (pending: PendingReview) => action(pending),
);

export const sendRating = createAction(
  ActionTypes.RATING_SEND,
  action => (rating: RatingReview) => action(rating),
);

export const sendRatingError = createAction(
  ActionTypes.RATING_SEND_ERROR,
  action => (error: string) => action(error),
);

export const sendRatingSuccess = createAction(ActionTypes.RATING_SEND_SUCCESS);

export const sendGeneralUserFeedback = createAction(
  ActionTypes.SEND_GENERAL_USER_FEEDBACK,
  action => (data: GeneralUserFeedback) => action({ data }),
);

export const searchTags = createAction(ActionTypes.TAGS_SEARCH, action => (tag: string) =>
  action({ value: tag }),
);
export const searchTagsSuccess = createAction(
  ActionTypes.TAGS_SEARCH_SUCCESS,
  action => (data: string[]) => action({ data }),
);

export const fetchTemplate = createAction(
  ActionTypes.FETCH_TEMPLATE,
  action => (templateId: string) => action({ templateId }),
);

export const fetchTemplateError = createAction(
  ActionTypes.FETCH_TEMPLATE_ERROR,
  action => (error: string) => action({ error }),
);
export const fetchTemplateSuccess = createAction(
  ActionTypes.FETCH_TEMPLATE_SUCCESS,
  action => (template: NotificationTemplate) => action({ template }),
);
