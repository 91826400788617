import React from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';

type Props = {
  color?: string;
  width: number;
  name?: string;
};

export const ProfileCircle = (props: Props) => {
  if (props.name && props.name.length > 1) {
    return (
      <Avatar
        style={{
          backgroundColor: props.color,
          verticalAlign: 'middle',
        }}
        size="large"
      >
        {props.name.substring(0, 1)}
      </Avatar>
    );
  }
  return <Circle {...props} />;
};

const Circle = styled.div<Props>`
  height: ${props => props.width}px;
  width: ${props => props.width}px;
  border-radius: 50%;
  border: 4px solid ${props => props.color || '#D3D3D3'};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export default ProfileCircle;
