import React from 'react';
export default () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3289 48.2268C20.5292 48.594 19.6793 47.8237 19.9663 46.9919L25.2965 31.544C25.4356 31.1408 25.8152 30.8702 26.2418 30.8702L54.5566 30.8702C55.63 30.8702 55.9494 32.3311 54.9739 32.779L21.3289 48.2268Z"
        fill="currentColor"
      />
      <path
        d="M54.9826 27.23C55.958 27.678 55.6386 29.1388 54.5653 29.1388L26.2324 29.1388C25.8056 29.1388 25.4258 28.8679 25.2869 28.4644L19.9634 13.0074C19.677 12.1756 20.5268 11.4059 21.3263 11.773L54.9826 27.23Z"
        fill="currentColor"
      />
    </svg>
  );
};
