import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

type Props = {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
};
const FullSizeModal: React.FunctionComponent<Props> = props => {
  const { visible, onCancel, onOk, children } = props;
  return (
    <StyledModal
      title=""
      visible={visible}
      footer={null}
      centered
      style={{
        margin: 0,
        maxWidth: '100%',
        height: '100%',
        top: 0,
        left: 0,
        padding: 0,
      }}
      onOk={onOk}
      onCancel={onCancel}
    >
      {children}
    </StyledModal>
  );
};

export default FullSizeModal;

const StyledModal = styled(Modal)`
  &&&&&& .ant-modal {
    margin: 0;
  }
  & .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 0;
    height: 100%;
    & > div {
      height: 100%;
    }
  }

  .ant-modal-content {
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: #f5f5f5;
  }
  .ant-modal-close {
    display: none;
  }
`;
