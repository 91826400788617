import React, { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../hooks';
import {
  getSelectedChatRoom,
  selectChatRoom,
  chatRoomSubscribe,
  getChatRoomById,
} from '../../../modules';

type Props = {
  children: ReactNode;
  chatRoomId: string;
};
export const ChatRoomConnector = (props: Props) => {
  const { children, chatRoomId } = props;
  const dispatch = useDispatch();
  const chatRoom = useTypedSelector(state => getChatRoomById(state, chatRoomId));
  const showChatRoom = useTypedSelector(getSelectedChatRoom);

  useEffect(() => {
    if (!showChatRoom) {
      dispatch(chatRoomSubscribe(chatRoomId));
    }
  }, [chatRoomId, showChatRoom, dispatch]);

  useEffect(() => {
    if (chatRoom) {
      dispatch(selectChatRoom(chatRoomId));
    }
  }, [chatRoom, chatRoomId, dispatch]);

  if (!showChatRoom) return null;
  return <>{children}</>;
};
