import React from 'react';

export default () => (
  <svg
    width="26"
    height="32"
    viewBox="0 0 26 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3965 6.77222C19.3965 3.23901 16.5332 0.374756 13 0.374756C9.4668 0.374756 6.60352 3.23901 6.60352 6.77222C6.60352 10.3054 9.4668 13.1697 13 13.1697C16.5332 13.1697 19.3965 10.3054 19.3965 6.77222ZM22.7168 29.823C24.2676 29.2048 25.375 27.7681 25.375 26.0986C25.375 19.2649 19.8359 14.7932 13 14.7932C6.16406 14.7932 0.625 19.2649 0.625 26.0986C0.625 27.7681 1.73242 29.2048 3.2832 29.823C6.16406 30.9714 9.47656 31.6252 13 31.6252C16.5234 31.6252 19.8359 30.9714 22.7168 29.823Z"
      fill="currentColor"
    />
  </svg>
);
