import React from 'react';
import styled from 'styled-components';

import theme from '../../../theme';

type Props = {
  standalone?: boolean;
};
export const DesktopContainer: React.FC<Props> = props => {
  const { children, standalone = true } = props;
  return (
    <RootContainer>
      <MainContainer standalone={standalone}>{children}</MainContainer>
    </RootContainer>
  );
};
const RootContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${theme.colors.background.light};
  .ant-layout {
    background: transparent;
    height: 100%;
  }
  .ant-layout-content {
    height: 100%;
  }
`;

const MainContainer = styled.div<{ standalone?: boolean }>`
  height: 100%;
  width: 100%;
  @media screen and (min-width: 1441px) {
    margin: ${({ standalone }) => (standalone ? '17px' : '0px')} auto;
    max-width: 1396px;
    height: ${({ standalone }) => (standalone ? 'calc(100% - 38px)' : '100%')};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
`;
