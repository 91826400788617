import React from 'react';
import styled from 'styled-components';

import theme from '../../../theme';

type Props = {};

export const Card: React.FC<Props> = props => {
  // const { onClick, active, children } = props;
  return <Container>{props.children}</Container>;
};

const Container = styled.div`
  background-color: ${theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
`;
