import React from 'react';

export default () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4741 18.2955C21.1089 18.9318 21.1089 19.8864 20.4741 20.5227C19.8392 21.1591 18.8869 21.1591 18.2521 20.5227L10.3164 12.5682L2.38071 20.5227C2.06328 21.1591 1.111 21.1591 0.476141 20.5227C-0.158714 19.8864 -0.158714 18.9318 0.476141 18.2955L8.41183 10.3409L0.476141 2.70454C-0.158714 2.06818 -0.158714 1.11364 0.476141 0.477273C1.111 -0.159091 2.06328 -0.159091 2.69813 0.477273L10.6338 8.43182L18.5695 0.477273C19.2044 -0.159091 20.1566 -0.159091 20.7915 0.477273C21.1089 1.11364 21.1089 2.06818 20.4741 2.70454L12.5384 10.3409L20.4741 18.2955Z"
      fill="currentColor"
    />
  </svg>
);
