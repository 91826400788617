export const STRIPE_REDIRECT_URL_REQUESTED = 'PAYMENTS/STRIPE_REDIRECT_URL_REQUESTED';
export const STRIPE_REDIRECT_URL_SUCCESS = 'PAYMENTS/STRIPE_REDIRECT_URL_SUCCESS';
export const STRIPE_REDIRECT_URL_ERROR = 'PAYMENTS/STRIPE_REDIRECT_URL_ERROR';

export const PAYMENTS_CONFIRM = 'PAYMENTS/PAYMENTS_CONFIRM';
export const PAYMENTS_CONFIRM_SUCCESS = 'PAYMENTS/PAYMENTS_CONFIRM_SUCCESS';
export const PAYMENTS_CONFIRM_ERROR = 'PAYMENTS/PAYMENTS_CONFIRM_ERROR';

export const PAYMENTS_SAVED_ACCOUNT = 'PAYMENTS/PAYMENTS_SAVED_ACCOUNT';
export const PAYMENTS_SAVED_ACCOUNT_SUCCESS = 'PAYMENTS/PAYMENTS_SAVED_ACCOUNT_SUCCESS';
export const PAYMENTS_SAVED_ACCOUNT_ERROR = 'PAYMENTS/PAYMENTS_SAVED_ACCOUNT_ERROR';

export const PAYMENTS_CREATE_CUSTOMER = 'PAYMENTS/PAYMENTS_CREATE_CUSTOMER';
export const PAYMENTS_CREATE_CUSTOMER_SUCCESS =
  'PAYMENTS/PAYMENTS_CREATE_CUSTOMER_SUCCESS';
export const PAYMENTS_CREATE_CUSTOMER_ERROR = 'PAYMENTS/PAYMENTS_CREATE_CUSTOMER_ERROR';

export const INIT_STRIPE = 'PAYMENTS/INIT_STRIPE';
export const INIT_STRIPE_DONE = 'PAYMENTS/INIT_STRIPE_DONE';

export const SUBSCRIPTION_PLAN = 'PAYMENTS/SUBSCRIPTION_PLAN';
export const SUBSCRIPTION_PLAN_SUCCESS = 'PAYMENTS/SUBSCRIPTION_PLAN_SUCCESS';
export const SUBSCRIPTION_PLAN_ERROR = 'PAYMENTS/SUBSCRIPTION_PLAN_ERROR';

export const PAYMENT_CUSTOMER = 'PAYMENTS/PAYMENT_CUSTOMER';
export const PAYMENT_CUSTOMER_SUCCESS = 'PAYMENTS/PAYMENT_CUSTOMER_SUCCESS';
export const PAYMENT_CUSTOMER_ERROR = 'PAYMENTS/PAYMENT_CUSTOMER_ERROR';

export const CANCEL_SUBSCRIPTION = 'PAYMENTS/CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'PAYMENTS/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'PAYMENTS/CANCEL_SUBSCRIPTION_ERROR';

export const REACTIVATE_SUBSCRIPTION = 'PAYMENTS/REACTIVATE_SUBSCRIPTION';
export const REACTIVATE_SUBSCRIPTION_SUCCESS = 'PAYMENTS/REACTIVATE_SUBSCRIPTION_SUCCESS';
export const REACTIVATE_SUBSCRIPTION_ERROR = 'PAYMENTS/REACTIVATE_SUBSCRIPTION_ERROR';

export const UPDATE_PAYMENT_DETAILS = 'PAYMENTS/UPDATE_PAYMENT_DETAILS';
export const UPDATE_PAYMENT_DETAILS_SUCCESS = 'PAYMENTS/UPDATE_PAYMENT_DETAILS_SUCCESS';
export const UPDATE_PAYMENT_DETAILS_ERROR = 'PAYMENTS/UPDATE_PAYMENT_DETAILS_ERROR';

export const AVAILABLE_SUBSCRIPTIONS_SUBSCRIBE =
  'PAYMENTS/AVAILABLE_SUBSCRIPTIONS_SUBSCRIBE';
export const AVAILABLE_SUBSCRIPTIONS_RECEIVE = 'PAYMENTS/AVAILABLE_SUBSCRIPTIONS_RECEIVE';
export const AVAILABLE_SUBSCRIPTIONS_RECEIVE_ERROR =
  'PAYMENTS/AVAILABLE_SUBSCRIPTIONS_RECEIVE_ERROR';

export const SAVE_AVAILABLE_SUBSCRIPTION = 'PAYMENTS/SAVE_AVAILABLE_SUBSCRIPTION';
export const SAVE_AVAILABLE_SUBSCRIPTION_SUCCESS =
  'PAYMENTS/SAVE_AVAILABLE_SUBSCRIPTION_SUCCESS';
export const SAVE_AVAILABLE_SUBSCRIPTION_ERROR =
  'PAYMENTS/SAVE_AVAILABLE_SUBSCRIPTION_ERROR';

export const FETCH_PLANS = 'PAYMENTS/FETCH_PLANS';
export const FETCH_PLANS_SUCCESS = 'PAYMENTS/FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_ERROR = 'PAYMENTS/FETCH_PLANS_ERROR';

export const SET_CARD = 'PAYMENTS/SET_CARD';

export const SELECT_PLAN_FOR_CHECKOUT = 'PAYMENTS/SELECT_PLAN_FOR_CHECKOUT';

export const CAN_CREATE_DISCUSSION = 'PAYMENTS/CAN_CREATE_DISCUSSION';
export const CAN_CREATE_DISCUSSION_SUCCESS = 'PAYMENTS/CAN_CREATE_DISCUSSION_SUCCESS';
export const CAN_CREATE_DISCUSSION_ERROR = 'PAYMENTS/CAN_CREATE_DISCUSSION_ERROR';
export const CAN_CREATE_DISCUSSION_RESET = 'PAYMENTS/CAN_CREATE_DISCUSSION_RESET';
