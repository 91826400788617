import { Route, Redirect, RouteProps } from 'react-router';
import React from 'react';

import { useTypedSelector } from '../hooks';
import { getIsSignedIn } from '../modules';

interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  redirect: string;
}

const PrivateRoute = ({ component: Component, redirect, ...rest }: PrivateRouteProps) => {
  const isSignedIn = useTypedSelector(getIsSignedIn);
  return (
    <Route
      {...rest}
      render={props =>
        isSignedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
