import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ProfileCircle from '../../_atoms/ProfileCircle';
import { EllipsisOverflowP } from '../../../styled';
import { ChatContext } from '../DynamicVirtualList';
import theme from '../../../theme';

type Props = {
  name?: string;
  message: string;
  avatarColor?: string;
  selected?: boolean;
  index: number;
  tags: string[];
  completedAt?: number;
};
export const ChatRoomListItem = (props: Props) => {
  const { name, message, selected, avatarColor, index, tags, completedAt } = props;
  const { t } = useTranslation('ChatRoom');
  const { setSize, windowWidth } = React.useContext(ChatContext);
  const root = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    setSize(index, root?.current?.getBoundingClientRect().height || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);
  return (
    <Element selected={selected} ref={root}>
      <CenteredContainer>
        <ProfileCircle
          width={40}
          name={name}
          color={avatarColor || (selected ? '#5CC9E1' : '#D3D3D3')}
        />
      </CenteredContainer>
      <Info selected={selected}>
        <Name>{name || t('No mentor yet')}</Name>
        <Message>{message}</Message>
        {completedAt && <Date>{moment(completedAt).format('LLL')}</Date>}
        <TagContainer>
          {tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </TagContainer>
      </Info>
    </Element>
  );
};

export default ChatRoomListItem;

const TagContainer = styled.div`
  display: flex;
  margin-top: 2px;
  flex-flow: wrap;
  & > span {
    margin: 0px 2px 2px 2px;
  }
`;

const Tag = styled.span`
  background-color: ${theme.colors.avatar[3]};
  color: white;
  padding: 1px 8px;
  border-radius: 10px;
  font-size: 12px;
`;

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
  margin-right: 10px;
`;

const Info = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  max-width: 70%;
  padding: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-bottom: 1px solid ${({ selected }) => (selected ? 'transparent' : '#eeeeee')};
  &:hover {
    border-bottom: 1px solid transparent;
  }
`;

const Element = styled.div<{ selected?: boolean }>`
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: ${({ selected }) => (selected ? '#eeeeee' : 'transparent')};
  &:focus {
    background-color: #eeeeee;
  }
  &:hover {
    background-color: #eeeeee;
  }
`;

const Name = styled(EllipsisOverflowP)`
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #364045;
`;
const Message = styled(EllipsisOverflowP)`
  color: #737373;
  font-size: 12px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Date = styled(EllipsisOverflowP)`
  color: #737373;
  font-size: 12px;
  margin: 0;
`;
