import { createAction } from 'typesafe-actions';

import * as ActionTypes from './actionTypes';
import { ServerState, PublicData } from './reducer';

export const subscribeToServerData = createAction(ActionTypes.SERVER_DATA_SUBSCRIBED);

export const serverDataChanged = createAction(
  ActionTypes.SERVER_DATA_CHANGED,
  action => (state: ServerState) => action(state),
);

export const subscribeToPublicData = createAction(ActionTypes.PUBLIC_DATA_SUBSCRIBED);

export const publicDataReceived = createAction(
  ActionTypes.PUBLIC_DATA_RECEIVED,
  action => (state: PublicData) => action(state),
);
