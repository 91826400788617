import { combineEpics } from 'redux-observable';

import {
  notificationRootEpic,
  userEpic,
  rootUserEpic,
  rootAdminEpic,
  chatRoomRootEpic,
  blobsRootEpic,
  serverRootEpic,
  reviewsRootEpic,
  environmentRootEpic,
  mentorRootEpic,
  createDiscussionEpic,
  rootAuthEpic,
} from '../modules';

import { paymentsRootEpic } from './Payments';

export const rootEpic = combineEpics(
  notificationRootEpic,
  chatRoomRootEpic,
  userEpic,
  rootUserEpic,
  blobsRootEpic,
  serverRootEpic,
  reviewsRootEpic,
  environmentRootEpic,
  rootAdminEpic,
  mentorRootEpic,
  createDiscussionEpic,
  paymentsRootEpic,
  rootAuthEpic,
);
