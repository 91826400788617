import React from 'react';
import styled from 'styled-components';

export default (props: { children: React.ReactNode }) => {
  return <StyleContainer>{props.children}</StyleContainer>;
};

const StyleContainer = styled.div`
  width: 100%;
  .ant-input-password,
  .ant-input {
    border-radius: 50px;
    padding: 12px 30px;
  }
`;
