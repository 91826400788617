import { createAction } from 'typesafe-actions';
import {
  AuthenticationActions,
  IAccountInfo,
  IdTokenResponse,
  AccessTokenResponse,
  AuthenticationState,
} from 'react-aad-msal';
import { AuthError } from 'msal';

export const initializing = createAction(AuthenticationActions.Initializing);
export const initialized = createAction(AuthenticationActions.Initialized);
export const loginSuccessful = createAction(
  AuthenticationActions.LoginSuccess,
  action => (data: IAccountInfo) => action(data),
);
export const loginFailed = createAction(AuthenticationActions.LoginFailed);
export const loginError = createAction(
  AuthenticationActions.LoginError,
  action => (error: AuthError) => action(error),
);
export const clearError = createAction(AuthenticationActions.ClearError);
export const logoutSuccessful = createAction(AuthenticationActions.LogoutSuccess);
export const acquireIdTokenSuccess = createAction(
  AuthenticationActions.AcquiredIdTokenSuccess,
  action => (token: IdTokenResponse) => action(token),
);
export const acquireIdTokenError = createAction(
  AuthenticationActions.AcquiredIdTokenError,
  action => (error: AuthError) => action(error),
);
export const acquireAccessTokenSuccess = createAction(
  AuthenticationActions.AcquiredAccessTokenSuccess,
  action => (token: AccessTokenResponse) => action(token),
);
export const acquireAccessTokenError = createAction(
  AuthenticationActions.AcquiredAccessTokenError,
  action => (error: AuthError) => action(error),
);

export const authenticatedStateChanged = createAction(
  AuthenticationActions.AuthenticatedStateChanged,
  action => (state: AuthenticationState) => action(state),
);

export const onResetPasswordRedirect = createAction('AUTH/RESET_PASSWORD_REDIRECT');
