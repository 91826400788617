export type CustomTokenCache = {
  aadAccessToken: string;
  fbCustomToken: string;
  loginHint?: string;
};

const AAD_TOKEN_CACHE_KEY = '@snapmentor/aad-tokens';
export const getCustomTokenCache = () => {
  try {
    const res = localStorage.getItem(AAD_TOKEN_CACHE_KEY);

    if (!res) return undefined;
    const cachedIdToken: CustomTokenCache = JSON.parse(res);
    return cachedIdToken;
  } catch (e) {
    return undefined;
  }
};

export const clearCustomTokenCache = () => {
  localStorage.removeItem(AAD_TOKEN_CACHE_KEY);
};

export const setCustomTokenCache = ({
  aadAccessToken,
  fbCustomToken,
  loginHint,
}: CustomTokenCache) => {
  localStorage.setItem(
    AAD_TOKEN_CACHE_KEY,
    JSON.stringify({
      aadAccessToken: aadAccessToken,
      fbCustomToken: fbCustomToken,
      loginHint,
    }),
  );
};
