type Flags = {
  [flagName: string]: boolean;
};

const flags: { [tenantId: string]: Flags } = {
  pvs: {
    googleAnalytics: true,
  },
  lotustutors: {
    googleAnalytics: true,
  },
  nhh: {
    googleAnalytics: true,
  },
  'snapmentor-dev': {
    googleAnalytics: false,
  },
};

export const isFlagEnabled = (flag: string, tenantId?: string) => {
  if (!tenantId) return false;
  const tenantFlags = flags[tenantId];
  if (!tenantFlags) return false;
  return !!tenantFlags[flag];
};
