import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import FormInput from '../../_atoms/FormInput';
import { StyledButton } from '../../../styled';

type Props = {
  name?: string;
  onSave: (name: string) => void;
};
export default (props: Props) => {
  const { t } = useTranslation('admin');
  const { name, onSave } = props;
  const [value, setValue] = useState(name);

  useEffect(() => {
    setValue(name);
  }, [name]);
  return (
    <>
      <FormInput>
        <Input
          value={value}
          placeholder={t('Name')}
          onChange={e => setValue(e.target.value)}
        />
      </FormInput>
      <StyledButton
        style={{ marginTop: 10, width: 100 }}
        onClick={() => onSave(value || '')}
      >
        {t('Save')}
      </StyledButton>
    </>
  );
};
