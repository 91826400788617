import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Collapse, Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

import { Topic, SubTopic, ById } from '../../../modules';
import { SubTopicInput } from '../SubTopicInput';
import { CircledButton } from '../../_atoms/CircledButton';
import { Icons } from '../../Icons';
import { InputStyled } from '../../../styled/InputStyled';
import { IconWrapper } from '../../../styled/IconWrapper';
import { AntStyleOverrider } from '../../../styled/Accordion';
const { Panel } = Collapse;

type Props = {
  topics: ById<Topic>;
  onChange: (topic: Topic) => void;
  onDelete: (topicId: string) => void;
};

export const NewTopicForm = ({
  onCreate,
  autoAdd,
  onCancel,
}: {
  onCreate: (value: string) => void;
  autoAdd?: boolean;
  onCancel: () => void;
}) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation('Admin');

  const handleBlur = () => {
    if (!value) return onCancel();
    autoAdd && onCreate(value);
  };
  return (
    <InputStyler>
      <InputStyled
        value={value}
        onChange={e => setValue(e.target.value)}
        onBlur={handleBlur}
        autoFocus
      />
      {!autoAdd && <Button onClick={() => onCreate(value)}>{t('Create')}</Button>}
    </InputStyler>
  );
};

const InputStyler = styled.div`
  display: flex;
  .ant-input:hover,
  .ant-input:focus {
    border-color: black;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  }
`;

const SubTopicManager = ({
  subTopics,
  onSubTopicAdded,
  onSubTopicChanged,
  onSubTopicDeleted,
}: {
  subTopics: ById<SubTopic>;
  onSubTopicAdded: (value: string) => void;
  onSubTopicChanged: (value: string, subTopicId: string) => void;
  onSubTopicDeleted: (id: string) => void;
}) => {
  const { t } = useTranslation('Admin');

  const [isCreatingSubTopic, setIsCreatingSubTopic] = useState<boolean>(false);

  const handleSubTopicAdded = (value: string) => {
    onSubTopicAdded(value);
    setIsCreatingSubTopic(false);
  };
  const handleSubTopicChanged = (value: string, subTopicId: string) => {
    onSubTopicChanged(value, subTopicId);
  };

  return (
    <ManagerContainer>
      <SubTopicFormsContainer>
        {Object.values(subTopics).map((sub, i) => (
          <FormWrapper key={`${sub.id}:${sub.name}`}>
            <SubTopicInput
              id={sub.id}
              level={i}
              name={sub.name}
              onChange={value => handleSubTopicChanged(value, sub.id)}
            />
            <Popconfirm
              title={t('confirmdeletesubcategory', {
                defaultValue: 'Are you sure you want to delete this sub category?',
              })}
              onConfirm={() => {
                onSubTopicDeleted(sub.id);
              }}
              okText={t('Yes')}
              cancelText={t('No')}
            >
              <IconWrapper>
                <Icons.Trash />
              </IconWrapper>
            </Popconfirm>
          </FormWrapper>
        ))}
      </SubTopicFormsContainer>
      {isCreatingSubTopic && (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <NewTopicForm
            autoAdd
            onCancel={() => setIsCreatingSubTopic(false)}
            onCreate={handleSubTopicAdded}
          />
        </div>
      )}
      <CircledButton
        backgroundColor="#5CC9E1"
        onClick={() => setIsCreatingSubTopic(true)}
        text={t('Create sub category')}
        icon="New"
      />
    </ManagerContainer>
  );
};

const SubTopicFormsContainer = styled.div`
  margin-bottom: 20px;
  & > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const ManagerContainer = styled.div``;

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  ${IconWrapper} {
    flex: 0;
    margin-left: 10px;
  }
  & div:first-child {
    flex: 1;
  }
`;

export const TopicCollection: React.FC<Props> = props => {
  const { topics, onChange, onDelete } = props;
  const handleSubTopicAdded = (name: string, topic: Topic) => {
    const id = name
      .toLowerCase()
      .split(' ')
      .join('');
    onChange({
      ...topic,
      subTopics: {
        ...topic.subTopics,
        [id]: {
          id,
          name,
          level: 0,
        },
      },
    });
  };

  const handleSubTopicChanged = (value: string, subTopicId: string, topic: Topic) => {
    onChange({
      ...topic,
      subTopics: {
        ...topic.subTopics,
        [subTopicId]: {
          id: subTopicId,
          name: value,
          level: 0,
        },
      },
    });
  };

  const handleTopicChanged = (name: string, topic: Topic) => {
    onChange({
      ...topic,
      name,
    });
  };

  const handleSubTopicDeleted = (id: string, topic: Topic) => {
    const subTopics: ById<SubTopic> = { ...topic.subTopics };
    delete subTopics[id];
    onChange({
      ...topic,
      subTopics,
    });
  };

  const handleTopicDeleted = (id: string) => {
    onDelete(id);
  };

  const customPanelStyle = {
    marginBottom: 30,
    border: 0,
    overflow: 'hidden',
  };

  const PanelHeader = ({
    onDelete,
    onChange,
    name,
  }: {
    onDelete: () => void;
    onChange: (name: string) => void;
    name: string;
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const { t } = useTranslation('admin');
    const [value, setValue] = useState<string>('');
    const onDone = () => {
      onChange(value);
      setIsEditing(false);
    };
    useEffect(() => {
      if (isEditing) setValue(name);
    }, [isEditing, name]);

    return (
      <PanelHeaderStyled>
        {!isEditing && <p>{name}</p>}
        {isEditing && (
          <InputStyled
            value={value}
            onChange={e => setValue(e.target.value)}
            onBlur={onDone}
            onPressEnter={onDone}
            autoFocus
          />
        )}
        <ActionsContainer onClick={e => e.stopPropagation()}>
          <IconWrapper onClick={() => setIsEditing(true)}>
            <Icons.Edit />
          </IconWrapper>
          <Popconfirm
            title={t('confirmdeletecategory', {
              defaultValue: 'Are you sure you want to delete this category?',
            })}
            onConfirm={() => {
              onDelete();
            }}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <IconWrapper>
              <Icons.Trash />
            </IconWrapper>
          </Popconfirm>
        </ActionsContainer>
      </PanelHeaderStyled>
    );
  };

  return (
    <AntStyleOverrider>
      <Collapse>
        {Object.values(topics).map(topic => (
          <Panel
            header={
              <PanelHeader
                name={topic.name}
                onDelete={() => handleTopicDeleted(topic.id)}
                onChange={name => handleTopicChanged(name, topic)}
              />
            }
            key={topic.id}
            style={customPanelStyle}
          >
            <SubTopicManager
              subTopics={topic.subTopics || {}}
              onSubTopicAdded={value => handleSubTopicAdded(value, topic)}
              onSubTopicChanged={(value, subTopicId) =>
                handleSubTopicChanged(value, subTopicId, topic)
              }
              onSubTopicDeleted={id => handleSubTopicDeleted(id, topic)}
            />
          </Panel>
        ))}
      </Collapse>
    </AntStyleOverrider>
  );
};

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin-left: 20px;
  }
`;

const PanelHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p {
    margin: 0;
  }
`;
