import { createAction } from 'typesafe-actions';

import * as ActionTypes from './actionTypes';

export const selectTopic = createAction(
  ActionTypes.SELECT_TOPIC,
  action => (topicId: string) => action({ topicId }),
);
export const selectSubTopic = createAction(
  ActionTypes.SELECT_SUBTOPIC,
  action => (subTopicId: string) => action({ subTopicId }),
);
export const addTextMessage = createAction(
  ActionTypes.ADD_TEXT_MESSAGE,
  action => (textMessage: string) => action({ textMessage }),
);

export const addImage = createAction(ActionTypes.ADD_IMAGE, action => (imageId: string) =>
  action({ imageId }),
);
export const initCreateDiscussion = createAction(ActionTypes.INIT);
export const nextStep = createAction(ActionTypes.NEXT_STEP);
export const previousStep = createAction(ActionTypes.PREVIOUS_STEP);
export const startDiscussion = createAction(ActionTypes.START_DISCUSSION);
export const startDiscussionSuccess = createAction(
  ActionTypes.START_DISCUSSION_SUCCESS,
  action => (chatRoomId: string) => action({ chatRoomId }),
);
export const startDiscussionFailed = createAction(
  ActionTypes.START_DISCUSSION_FAILED,
  action => (error: string) => action({ error }),
);
