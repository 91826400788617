import React from 'react';
export interface HomeProps {
  color: string;
  size: number;
}
const Home = (props: HomeProps) => {
  const { color, size = 24 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="24 / basic / home">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.36084 12.5L14.8625 4.99834L22.3642 12.5H22.3626V25H7.36255V12.5H7.36084ZM4.86255 14.9983L3.0165 16.8443L1.25 15.0778L13.0962 3.23161C14.0717 2.25613 15.6533 2.25613 16.6288 3.23161L28.475 15.0778L26.7085 16.8443L24.8626 14.9984V25C24.8626 26.3807 23.7433 27.5 22.3626 27.5H7.36255C5.98184 27.5 4.86255 26.3807 4.86255 25V14.9983Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Home;
