import styled from 'styled-components';

export const UnseenLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #c44137;
  border-radius: 1000px;
  height: 20px;
  width: 20px;
  font-size: 11px;
  line-height: 20px;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`;
