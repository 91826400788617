import { config } from '../../config/environments.production';
import { AppState } from '../rootReducer';
import { getConfig } from '../../store';

import { INIT_ENVIROMENT, VIPPS_REDIRECT_URL_RECEIVED } from './actionTypes';

import { RootAction } from '..';

export type EnvironmentConfig = typeof config;
export type EnvironmentState = {
  config: EnvironmentConfig;
  vippsRedirectUrl: string;
};

const init: EnvironmentState = {
  config: getConfig(),
  vippsRedirectUrl: '',
};

export const environmentReducer = (
  state: EnvironmentState = init,
  action: RootAction,
): EnvironmentState => {
  switch (action.type) {
    case INIT_ENVIROMENT:
      return { ...state, config: action.payload as EnvironmentConfig };
    case VIPPS_REDIRECT_URL_RECEIVED:
      return { ...state, vippsRedirectUrl: action.payload };
    default:
      return state;
  }
};

export const getBackendUrl = (state: AppState) => state.env.config.backend.url;
export const getVippsRedirectUrl = (state: AppState) => state.env.vippsRedirectUrl;

export const getClientId = (state: AppState, provider: 'GOOGLE' | 'FEIDE' | 'VIPPS') =>
  state.env.config.clientIds[provider];

export const getAppVersion = (state: AppState) =>
  state.env.config.appVersion?.substring(0, 7);

export const getStripePublishableKey = (state: AppState) =>
  state.env.config.stripe.publishableKey;
export const getStripeClientId = (state: AppState) => state.env.config.stripe.clientId;
