import React, { useMemo, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import theme from '../../../theme';
import { Message } from '../../../services/db';
import { ById } from '../../../modules';
import { LazyImage } from '../LazyImage';

import WaitForMentorMessage from './WaitForMentorMessage';
import MessageBubble from './MessageBubble';

type Props = {
  messages: ById<Message>;
  uid: string;
  chatRoomId: string;
  onImageClicked: (img: string) => void;
  mentorName?: string;
};

const isPreviousSentByMe = (message: Message[], i: number, uid: string) => {
  if (i === 0) return false;
  const prev = message[i - 1];
  return prev.createdBy === uid;
};

export const Messages = (props: Props) => {
  const { messages, uid, chatRoomId, onImageClicked, mentorName } = props;
  const ref = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (ref && ref.current) {
      const el = ref!.current;
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  };
  const messageArray = useMemo(() => Object.values(messages), [messages]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messageArray, ref, chatRoomId]);

  return (
    <>
      <div style={{ width: '100%', flex: '1' }} />
      {Object.keys(messages).map((key, i) => {
        const m = messages[key];
        const sentByMe = m.createdBy === uid;
        return (
          <NonFlexContainer key={key}>
            <MessageContainer
              sentByMe={sentByMe}
              sentByPrevious={i === 0 ? false : isPreviousSentByMe(messageArray, i, uid)}
            >
              {sentByMe ? (
                m.text ? (
                  <RightBubble>{m.text}</RightBubble>
                ) : (
                  <LazyImage
                    onImageClicked={onImageClicked}
                    chatRoomId={chatRoomId}
                    imageId={m.imageId!}
                  />
                )
              ) : m.text ? (
                <LeftBubble>{m.text}</LeftBubble>
              ) : (
                <LazyImage
                  onImageClicked={onImageClicked}
                  chatRoomId={chatRoomId}
                  imageId={m.imageId!}
                />
              )}
            </MessageContainer>
          </NonFlexContainer>
        );
      })}
      <WaitForMentorMessage mentorName={mentorName} />
      <div ref={ref} style={{ width: '100%', height: 1 }} />
    </>
  );
};

export default Messages;

const LeftBubble = styled(MessageBubble)`
  color: #737373;
  background-color: ${theme.colors.white};
`;

const RightBubble = styled(MessageBubble)`
  color: ${theme.colors.white};
  background-color: #7fd4da;
`;

const NonFlexContainer = styled.div`
  width: 100%;
  padding: 0 8%;
`;

const MessageContainer = styled.div<{
  sentByMe: boolean;
  sentByPrevious: boolean;
}>`
  width: 100%;
  display: flex;
  margin: ${({ sentByPrevious }) => (sentByPrevious ? '2px' : '10px')} 0;
  justify-content: ${({ sentByMe }) => (sentByMe ? 'flex-end' : 'flex-start')};
  align-items: center;
`;
