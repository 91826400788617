import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const CompleteActionPanel = () => {
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation('ChatRoom');
  return (
    <Container onClick={() => setIsActive(false)}>
      <Panel active={isActive}>
        <PanelContainer>
          <div>
            <p>{t('This discussion is completed')}</p>
          </div>
          {isActive && (
            <ActionContainer>
              <button>Add a review</button>
              <button>Ask another question</button>
            </ActionContainer>
          )}
        </PanelContainer>
      </Panel>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
`;

const Panel = styled.div<{ active: boolean }>`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%) ${({ active }) => active && 'translateY(-240px)'};
  transition: 0.3s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 90%;
  min-height: 60px;
  height: ${({ active }) => (active ? '300px' : '60px')};
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #cfc5bb;
`;

const PanelContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
