import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
export const Loader = () => (
  <SpinnerContainer>
    <Spin />
  </SpinnerContainer>
);

export default Loader;
