import React from 'react';

export default () => (
  <svg
    width="187"
    height="32"
    viewBox="0 0 187 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.920001 24.5493C0.435796 24.2588 0 23.6777 0 22.9514C0 21.8862 0.82316 21.063 1.88841 21.063C2.22736 21.063 2.56631 21.1599 2.80841 21.3051C4.40629 22.3219 5.95574 22.8062 7.31151 22.8062C8.66729 22.8062 9.29674 22.3219 9.29674 21.5472V21.4988C9.29674 20.482 7.69888 20.143 5.8589 19.562C3.53471 18.8841 0.871593 17.7704 0.871593 14.5747V14.5262C0.871593 11.1368 3.63155 9.24841 6.97256 9.24841C8.7157 9.24841 10.5073 9.73261 12.1536 10.5073C12.8315 10.8463 13.2672 11.4758 13.2672 12.2989C13.2672 13.3642 12.4441 14.1873 11.3788 14.1873C11.0399 14.1873 10.7978 14.1389 10.5073 13.9936C9.15148 13.3642 7.89257 12.9284 6.92416 12.9284C5.76206 12.9284 5.181 13.4126 5.181 14.0905V14.1389C5.181 15.0589 6.77888 15.4946 8.57044 16.1241C10.8946 16.8988 13.6062 18.0125 13.6062 21.063V21.1114C13.6062 24.7914 10.8462 26.4861 7.21467 26.4861C5.13259 26.4377 2.95366 25.8566 0.920001 24.5493Z"
      fill="currentColor"
    />
    <path
      d="M16.9473 11.6693C16.9473 10.3619 18.0125 9.29666 19.3199 9.29666C20.6272 9.29666 21.6925 10.3619 21.6925 11.6693V11.863C22.7578 10.4588 24.1619 9.19983 26.583 9.19983C30.1661 9.19983 32.2482 11.5724 32.2482 15.3976V24.0165C32.2482 25.3239 31.1829 26.3891 29.8755 26.3891C28.5682 26.3891 27.503 25.3239 27.503 24.0165V16.8987C27.503 14.6713 26.4377 13.5093 24.6461 13.5093C22.8546 13.5093 21.6925 14.6713 21.6925 16.8987V24.0165C21.6925 25.3239 20.6272 26.3891 19.3199 26.3891C18.0125 26.3891 16.9473 25.3239 16.9473 24.0165V11.6693Z"
      fill="currentColor"
    />
    <path
      d="M35.3477 21.3533V21.3049C35.3477 17.6734 38.1076 15.9787 42.0781 15.9787C43.7728 15.9787 44.9834 16.2692 46.1455 16.6566V16.366C46.1455 14.4292 44.9349 13.3155 42.5623 13.3155C41.255 13.3155 40.1897 13.5092 39.3181 13.7997C39.0276 13.8966 38.834 13.945 38.6403 13.945C37.575 13.945 36.7035 13.1219 36.7035 12.0082C36.7035 11.185 37.2361 10.4587 37.9624 10.1682C39.4634 9.58716 41.0613 9.29663 43.2886 9.29663C45.8549 9.29663 47.7433 9.97453 48.9054 11.185C50.1643 12.444 50.697 14.284 50.697 16.5113V24.1133C50.697 25.3722 49.6801 26.3891 48.3728 26.3891C47.017 26.3891 46.097 25.4207 46.097 24.4523V24.4038C44.9349 25.6628 43.3855 26.5343 41.0613 26.5343C37.914 26.4375 35.3477 24.6459 35.3477 21.3533ZM46.1939 20.2397V19.4165C45.3707 19.0292 44.3055 18.7871 43.1918 18.7871C41.1581 18.7871 39.9476 19.6102 39.9476 21.1112V21.1597C39.9476 22.4186 41.0129 23.1933 42.5139 23.1933C44.6928 23.1933 46.1939 21.9828 46.1939 20.2397Z"
      fill="currentColor"
    />
    <path
      d="M54.7148 11.6692C54.7148 10.3619 55.7801 9.29662 57.0875 9.29662C58.3948 9.29662 59.4601 10.3619 59.4601 11.6692V11.8629C60.6222 10.3135 62.1716 9.15137 64.641 9.15137C68.5147 9.15137 72.2431 12.2019 72.2431 17.7702V17.8186C72.2431 23.387 68.6115 26.4375 64.641 26.4375C62.1232 26.4375 60.5737 25.2754 59.4601 23.968V28.9554C59.4601 30.2627 58.3948 31.328 57.0875 31.328C55.7801 31.328 54.7148 30.2627 54.7148 28.9554V11.6692ZM67.4979 17.8186V17.7702C67.4979 15.0102 65.6095 13.1703 63.4305 13.1703C61.2032 13.1703 59.4116 15.0102 59.4116 17.7702V17.8186C59.4116 20.5786 61.2516 22.4186 63.4305 22.4186C65.6579 22.4186 67.4979 20.627 67.4979 17.8186Z"
      fill="currentColor"
    />
    <path
      d="M113.157 26.5344C108.17 26.5344 104.441 22.9997 104.441 17.9155V17.8671C104.441 13.1219 107.831 9.19983 112.673 9.19983C118.241 9.19983 120.711 13.7514 120.711 17.2376C120.711 18.5934 119.742 19.5134 118.483 19.5134H109.138C109.622 21.6439 111.075 22.7576 113.206 22.7576C114.513 22.7576 115.578 22.3702 116.547 21.6439C116.885 21.4018 117.176 21.2565 117.709 21.2565C118.774 21.2565 119.549 22.0797 119.549 23.1449C119.549 23.7744 119.258 24.2102 118.919 24.5491C117.467 25.7112 115.578 26.5344 113.157 26.5344ZM116.208 16.5113C115.917 14.3808 114.707 12.9766 112.673 12.9766C110.736 12.9766 109.429 14.3808 109.09 16.5113H116.208Z"
      fill="currentColor"
    />
    <path
      d="M124.295 11.6693C124.295 10.3619 125.36 9.29666 126.668 9.29666C127.975 9.29666 129.04 10.3619 129.04 11.6693V11.863C130.105 10.4588 131.51 9.19983 133.931 9.19983C137.514 9.19983 139.596 11.5724 139.596 15.3976V24.0165C139.596 25.3239 138.531 26.3891 137.223 26.3891C135.916 26.3891 134.851 25.3239 134.851 24.0165V16.8987C134.851 14.6713 133.785 13.5093 131.994 13.5093C130.202 13.5093 129.04 14.6713 129.04 16.8987V24.0165C129.04 25.3239 127.975 26.3891 126.668 26.3891C125.36 26.3891 124.295 25.3239 124.295 24.0165V11.6693Z"
      fill="currentColor"
    />
    <path
      d="M144.439 21.4019V13.5093H144.293C143.18 13.5093 142.26 12.5893 142.26 11.4756C142.26 10.362 143.18 9.442 144.293 9.442H144.439V7.35991C144.439 6.05255 145.504 4.9873 146.811 4.9873C148.119 4.9873 149.184 6.05255 149.184 7.35991V9.442H151.266C152.38 9.442 153.3 10.362 153.3 11.4756C153.3 12.5893 152.38 13.5093 151.266 13.5093H149.184V20.6271C149.184 21.6924 149.668 22.225 150.685 22.225C150.83 22.225 151.169 22.1766 151.266 22.1766C152.331 22.1766 153.203 23.0482 153.203 24.1134C153.203 24.9366 152.622 25.6144 151.992 25.905C151.121 26.2439 150.298 26.3892 149.281 26.3892C146.424 26.4376 144.439 25.2755 144.439 21.4019Z"
      fill="currentColor"
    />
    <path
      d="M155.283 17.9155V17.8671C155.283 13.0735 159.157 9.19983 164.338 9.19983C169.519 9.19983 173.344 13.025 173.344 17.8187V17.8671C173.344 22.6607 169.47 26.5344 164.289 26.5344C159.108 26.5344 155.283 22.7092 155.283 17.9155ZM168.647 17.9155V17.8671C168.647 15.3977 166.856 13.2671 164.241 13.2671C161.529 13.2671 159.932 15.3492 159.932 17.8187V17.8671C159.932 20.3365 161.723 22.467 164.338 22.467C167.049 22.4186 168.647 20.3365 168.647 17.9155Z"
      fill="currentColor"
    />
    <path
      d="M176.734 11.6692C176.734 10.3619 177.8 9.29663 179.107 9.29663C180.414 9.29663 181.48 10.3619 181.48 11.6692V12.3956C182.157 10.7977 183.271 9.29663 184.675 9.29663C186.128 9.29663 187 10.265 187 11.6208C187 12.8798 186.176 13.6061 185.208 13.8482C182.835 14.4292 181.48 16.2208 181.48 19.6102V24.0165C181.48 25.3238 180.414 26.3891 179.107 26.3891C177.8 26.3891 176.734 25.3238 176.734 24.0165V11.6692Z"
      fill="currentColor"
    />
    <path
      d="M93.5972 7.0694C95.5494 7.0694 97.1319 5.48685 97.1319 3.53469C97.1319 1.58253 95.5494 0 93.5972 0C91.6451 0 90.0625 1.58253 90.0625 3.53469C90.0625 5.48685 91.6451 7.0694 93.5972 7.0694Z"
      fill="currentColor"
    />
    <path
      d="M93.5976 9.15137C90.5956 9.15137 88.0293 10.8945 86.8188 13.4608C85.7535 12.8797 84.543 12.5892 83.2841 12.5892C79.1199 12.5892 75.7305 15.9787 75.7305 20.1428V23.968C75.7305 25.2754 76.7957 26.3406 78.1031 26.3406C79.4104 26.3406 80.4757 25.2754 80.4757 23.968V20.1428C80.4757 18.5934 81.7346 17.3344 83.2841 17.3344C84.8335 17.3344 86.0925 18.5934 86.0925 20.1428V23.968C86.0925 25.2754 87.1577 26.3406 88.4651 26.3406C89.7724 26.3406 90.8377 25.2754 90.8377 23.968V20.1428V16.705C90.8377 15.1555 92.0966 13.8966 93.6461 13.8966C95.1955 13.8966 96.4544 15.1555 96.4544 16.705V23.968C96.4544 25.2754 97.5197 26.3406 98.8271 26.3406C100.134 26.3406 101.2 25.2754 101.2 23.968V16.705C101.151 12.5408 97.7618 9.15137 93.5976 9.15137Z"
      fill="currentColor"
    />
    <path
      d="M83.2359 10.5072C85.188 10.5072 86.7706 8.92472 86.7706 6.97256C86.7706 5.0204 85.188 3.43787 83.2359 3.43787C81.2837 3.43787 79.7012 5.0204 79.7012 6.97256C79.7012 8.92472 81.2837 10.5072 83.2359 10.5072Z"
      fill="currentColor"
    />
  </svg>
);
