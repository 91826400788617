import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  chatRoomRequested,
  subscribeToUserType,
  subscribeToServerData,
  subscribeToReviews,
  subscribeToPublicData,
  getUserId,
  getIsSignedIn,
} from '../modules';
import { useTypedSelector, useMetrics } from '../hooks';

const RootSubscriber = ({ children }: { children: React.ReactChild }) => {
  const dispatch = useDispatch();
  const uid = useTypedSelector(getUserId);
  const isSignedIn = useTypedSelector(getIsSignedIn);
  const history = useHistory();
  const metrics = useMetrics();

  useEffect(() => {
    //subscribe to public data
    dispatch(subscribeToPublicData());
  }, [dispatch]);

  useEffect(() => {
    metrics.logEvent('Route.Change', {
      pathname: history.location.pathname,
      search: history.location.search,
    });
    return history.listen(location => {
      metrics.logEvent('Route.Change', {
        pathname: location.pathname,
        search: location.search,
      });
    });
  }, [history, metrics]);

  useEffect(() => {
    if (isSignedIn && uid) {
      dispatch(chatRoomRequested());
      dispatch(subscribeToUserType(uid));
      dispatch(subscribeToServerData());
      dispatch(subscribeToReviews());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, isSignedIn]);
  return <>{children}</>;
};

export default RootSubscriber;
