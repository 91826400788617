import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation('reports');

  return (
    <Empty>
      <h4>{t('No data yet')}</h4>
    </Empty>
  );
};
export const Empty = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
