import { createAction } from 'typesafe-actions';

import * as ActionTypes from './actionTypes';
import { EnvironmentConfig } from './reducer';

export const initEnvironment = createAction(
  ActionTypes.INIT_ENVIROMENT,
  action => (env: EnvironmentConfig) => action(env),
);
export const requestVippsUrl = createAction(ActionTypes.VIPPS_REDIRECT_URL_REQUESTED);
export const receiveVippsUrl = createAction(
  ActionTypes.VIPPS_REDIRECT_URL_RECEIVED,
  action => (url: string) => action(url),
);
