import React from 'react';

import theme from '../../theme';
export interface Props {
  color?: string;
  size?: number;
}
const Camera = (props: Props) => {
  const { color = theme.colors.iconGrey, size = 20 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.78101 4.87115C5.84005 4.78907 5.90563 4.68859 5.98924 4.5531C6.01979 4.5036 6.10311 4.36614 6.16514 4.2638L6.235 4.14868C6.92582 3.01866 7.42799 2.5 8.3335 2.5H11.6668C12.5723 2.5 13.0745 3.01866 13.7653 4.14868L13.8353 4.26395C13.8973 4.36627 13.9805 4.50362 14.0111 4.5531C14.0947 4.68859 14.1603 4.78907 14.2193 4.87115C14.2582 4.92527 14.2923 4.96828 14.32 5H16.6668C18.0475 5 19.1668 6.11929 19.1668 7.5V15C19.1668 16.3807 18.0475 17.5 16.6668 17.5H3.3335C1.95278 17.5 0.833496 16.3807 0.833496 15V7.5C0.833496 6.11929 1.95278 5 3.3335 5H5.68033C5.70807 4.96828 5.74208 4.92527 5.78101 4.87115ZM3.33341 6.66665C2.87318 6.66665 2.50008 7.03974 2.50008 7.49998V15C2.50008 15.4602 2.87318 15.8333 3.33341 15.8333H16.6667C17.127 15.8333 17.5001 15.4602 17.5001 15V7.49998C17.5001 7.03974 17.127 6.66665 16.6667 6.66665H14.1667C13.6131 6.66665 13.2398 6.36365 12.8662 5.84436C12.7803 5.72488 12.6951 5.59441 12.5927 5.42839C12.5576 5.37148 12.4663 5.22097 12.4038 5.11776L12.4038 5.11775L12.3433 5.01797C11.9663 4.40141 11.739 4.16665 11.6667 4.16665H8.33341C8.26114 4.16665 8.03383 4.40141 7.65691 5.01797L7.59631 5.11785C7.53377 5.22105 7.44259 5.3715 7.40747 5.42839C7.30501 5.59441 7.21987 5.72488 7.13393 5.84436C6.76039 6.36365 6.38703 6.66665 5.83341 6.66665H3.33341ZM16.6667 8.33333C16.6667 8.79357 16.2937 9.16667 15.8334 9.16667C15.3732 9.16667 15.0001 8.79357 15.0001 8.33333C15.0001 7.8731 15.3732 7.5 15.8334 7.5C16.2937 7.5 16.6667 7.8731 16.6667 8.33333ZM5.8335 10.8333C5.8335 13.1345 7.69898 15 10.0002 15C12.3013 15 14.1668 13.1345 14.1668 10.8333C14.1668 8.53213 12.3013 6.66665 10.0002 6.66665C7.69898 6.66665 5.8335 8.53213 5.8335 10.8333ZM12.5001 10.8334C12.5001 12.2141 11.3808 13.3334 10.0001 13.3334C8.61937 13.3334 7.50008 12.2141 7.50008 10.8334C7.50008 9.45264 8.61937 8.33335 10.0001 8.33335C11.3808 8.33335 12.5001 9.45264 12.5001 10.8334Z"
        fill={color}
      />
    </svg>
  );
};

export default Camera;
