import {
  filter,
  delay,
  mapTo,
  switchMap,
  map,
  catchError,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { from, of, empty, fromEventPattern } from 'rxjs';
import { combineEpics } from 'redux-observable';
import * as Sentry from '@sentry/browser';

import { catchHandler } from '../utils';
import { SIGN_OUT } from '../User';
import { getUserId, getIsMentor } from '../Auth';

import {
  removeNotification,
  NotificationPayload,
  receiveNotification,
  matchMakingError,
  matchMakingReceived,
  updateServiceWorkerError,
  updateServiceWorkerSuccess,
} from './actions';
import {
  NOTIFICATION_RECEIVED,
  NOTIFICATION_SUBSCRIBE,
  MATCHMAKING_REQUESTED,
  UPDATE_SERVICE_WORKER,
} from './actionTypes';

import { RootEpic, getServiceWorker } from '..';

const notificationEpic: RootEpic = action$ =>
  action$.pipe(
    filter(isOfType(NOTIFICATION_RECEIVED)),
    delay(5000),
    mapTo(removeNotification()),
  );

export type NotificationMessage = {
  data: {
    title: string;
    body: string;
  };
};

const toNotificationPayload = (message: NotificationMessage) => {
  return {
    title: message.data.title,
    body: message.data.body,
    data: message.data,
  };
};

const notificationSubscriptionEpic: RootEpic = (
  action$,
  state$,
  { database, notifications },
) =>
  action$.pipe(
    filter(isOfType([NOTIFICATION_SUBSCRIBE])),
    filter(() => getIsMentor(state$.value)),
    mergeMap(() => {
      if (!('Notification' in window)) return empty();
      const permission = Notification.requestPermission();
      if (!permission) return empty();
      return from(permission).pipe(
        tap(permission => {
          // eslint-disable-next-line no-console
          console.log('notification permission', permission);
        }),
        filter(permission => permission === 'granted'),
      );
    }),
    switchMap(() =>
      notifications.getToken().pipe(
        tap(token => {
          // eslint-disable-next-line no-console
          console.log('token', token);
        }),
        mergeMap(token =>
          from(database.usertype.setNotificationToken(token, getUserId(state$.value)!)),
        ),
        mergeMap(() =>
          notifications.onPushNoticationMessage().pipe(
            takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
            catchError(e => {
              // eslint-disable-next-line no-console
              console.error('An error occurred while retrieving token. ', e);
              return empty();
            }),
          ),
        ),
        map((message: NotificationMessage) => {
          return toNotificationPayload(message);
        }),
        map((notification: NotificationPayload) => {
          if (notification.data.type === 'chatRoomAvailableForClaim') {
            return receiveNotification({
              title: notification.title,
              body: notification.body,
              data: notification.data,
            });
          } else {
            return receiveNotification(notification);
          }
        }),
      ),
    ),
    catchError(catchHandler),
  );

const matchmakingEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(MATCHMAKING_REQUESTED)),
    switchMap(({ payload: { id } }) =>
      from(backendService.retrieveMatchMaking({ id })).pipe(
        map(mm => matchMakingReceived(mm)),
        catchError(e => {
          Sentry.captureException(e);
          return of(matchMakingError('Failed to retrieve the matchmaking element'));
        }),
      ),
    ),
  );

const updateServiceWorkerEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isOfType(UPDATE_SERVICE_WORKER)),
    switchMap(() => {
      const { registration } = getServiceWorker(state$.value);
      const registrationWaiting = registration?.waiting;

      if (registrationWaiting) {
        setTimeout(() => {
          // Must have a small timeout so postmessage is called after the eventlistener
          // has been setuip
          registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        }, 100);
        return fromEventPattern<Event>(handler =>
          registrationWaiting.addEventListener('statechange', handler),
        ).pipe(
          filter(e => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            return e.target?.state === 'activated';
          }),
          tap(() => window.location.reload()),
          mapTo(updateServiceWorkerSuccess()),
          catchError(e => {
            Sentry.captureException(e);
            return of(updateServiceWorkerError('Failed to update the service worker'));
          }),
        );
      } else {
        Sentry.captureException(new Error('PWA installation did not work'));
        return empty();
      }
    }),
  );

export const notificationRootEpic = combineEpics(
  notificationSubscriptionEpic,
  notificationEpic,
  matchmakingEpic,
  updateServiceWorkerEpic,
);

// helpers
