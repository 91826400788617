import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import Backend from 'i18next-locize-backend';
import moment from 'moment';

const LANGUAGE_DETECTOR_KEY = 'i18nextLng';
export const getDefaultLanguageFromLocalStorage = () => {
  const initLang = localStorage.getItem(LANGUAGE_DETECTOR_KEY);
  return initLang;
};

export const SUPPORTED_LANGAUGES = ['en', 'nb-NO', 'tr'];

const development =
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'staging' ||
  process.env.NODE_ENV === 'development';
if (development) SUPPORTED_LANGAUGES.push('de');
const initOptions = {
  fallbackLng: ['en'],
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  lng: getDefaultLanguageFromLocalStorage() || undefined,
  saveMissing: development, // auto add missing translations during development
  backend: {},
  // react i18next special options (optional)
  // override if needed - omit if ok with defaults
  /*
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  }
  */
};

if (development) {
  // Localhost loads/saves resources from the network.
  i18n.use(Backend);
  initOptions.backend = {
    // Locize specific keys
    projectId: '82081248-4597-485a-8a16-ca9695f6957d',
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
    referenceLng: 'en',
    allowedAddOrUpdateHosts: [
      'localhost',
      'snapmentor-dev.snapmentor.no',
      'metered-dev.snapmentor.no',
    ],
  };
  initOptions.saveMissing = true;
} else {
  // Staging and production use local resources.
  i18n.use(XHR);
  initOptions.backend = {
    loadPath: `${window.origin}/locales/{{lng}}/{{ns}}.json`,
  };
}
i18n.use(initReactI18next).init(initOptions);

i18n.on('languageChanged', (lang: string) => {
  const momentKey = lang.split('-')[0];
  if (momentKey !== 'en') {
    import(`moment/locale/${momentKey}`).then(() => {
      // Do something with the module.
      moment.locale(lang);
    });
  }
  localStorage.setItem(LANGUAGE_DETECTOR_KEY, lang);
});

export default i18n;
