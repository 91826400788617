import React from 'react';

import theme from '../../theme';
export interface Props {
  color?: string;
  size?: number;
}
const Send = (props: Props) => {
  const { color = theme.colors.primary, size = 20 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.2832 1.87671L3.42071 9.83923L4.06383 10L3.42071 10.1608L2.2832 18.1233L18.5298 10L2.2832 1.87671ZM4.91217 8.49414L4.38301 4.79L14.8031 10L4.38301 15.2101L4.91217 11.5059L10.9357 10L4.91217 8.49414Z"
        fill={color}
      />
    </svg>
  );
};

export default Send;
