import React, { useEffect } from 'react';
import { Row, Col, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { SectionTitle } from '../_atoms/SectionTitle';
import AnnouncementsInput from '../Admin/AnnouncementsInput';
import { TopicsManager } from '../_organisms/TopicsManager';
import { useTypedSelector } from '../../hooks';
import {
  getTopics,
  saveServerTopics,
  updateLoginProviders,
  saveDefaultLanguage,
  getDefaultLanguage,
  getAdminAnnouncements,
  fetchAdminAnnouncements,
  saveAnnouncement,
  getServiceName,
  saveServiceName,
  getIsExternalApiEnabled,
  getIsCustomLogoEnabled,
  getDefaultRole,
  saveDefaultRole,
  saveMeteredDiscussionConfig,
  getIsMeteredDiscussionsEnabled,
  selectMeteredDiscussionConfig,
  getMeteredDiscussionConfig,
} from '../../modules';
import CustomLogoUploader from '../_molecules/CustomLogoUploader';
import LoginProviderToggles from '../_molecules/LoginProviderToggles';
import { DefaultLanguage } from '../_molecules/DefaultLanguage';
import AdminServiceNameConfig from '../_organisms/AdminServiceNameConfig';
import ApiKeys from '../ApiKeys';
import { DefaultRole } from '../_molecules/DefaultRole';

export const AdminConfiguration = () => {
  const { t } = useTranslation('Admin');
  const dispatch = useDispatch();
  const serverTopics = useTypedSelector(getTopics);
  const isExternalApiEnabled = useTypedSelector(getIsExternalApiEnabled);
  const serviceName = useTypedSelector(getServiceName);
  const isCustomLogoEnabled = useTypedSelector(getIsCustomLogoEnabled);
  const isMeteredDiscussionEnabled = useTypedSelector(getIsMeteredDiscussionsEnabled);
  const { announcements: adminAnnouncements } = useTypedSelector(getAdminAnnouncements);
  const { config } = useTypedSelector(selectMeteredDiscussionConfig);

  useEffect(() => {
    if (!adminAnnouncements) dispatch(fetchAdminAnnouncements());
  }, [adminAnnouncements, dispatch]);

  useEffect(() => {
    if (isMeteredDiscussionEnabled) {
      dispatch(getMeteredDiscussionConfig());
    }
  }, [dispatch, isMeteredDiscussionEnabled]);
  const defaultLanguage = useTypedSelector(getDefaultLanguage);
  const defaultRole = useTypedSelector(getDefaultRole);
  const onSaveSuccess = (description: string) => {
    notification.success({
      message: t('Saved'),
      duration: 2,
      description: t(description),
    });
  };

  return (
    <Row style={{ padding: '40px 60px', minHeight: '100%' }} gutter={[40, 40]}>
      <Col span={12}>
        <CustomRow>
          <SectionTitle>{t('Categories')}</SectionTitle>
          <TopicsManager
            serverTopics={serverTopics}
            onSave={topics => {
              dispatch(saveServerTopics(topics));
              onSaveSuccess(t('Updated the categories'));
            }}
          />
        </CustomRow>
        {isExternalApiEnabled && (
          <CustomRow>
            <SectionTitle>Api Keys</SectionTitle>
            <ApiKeys />
          </CustomRow>
        )}
      </Col>
      <Col span={12}>
        <CustomRow>
          <SectionTitle>
            {t('information_to_all_mentors', {
              defaultValue: 'Announcement to all mentors',
            })}
          </SectionTitle>
          <AnnouncementsInput
            onSave={sentences => {
              dispatch(saveAnnouncement(sentences, 'mentors'));
              onSaveSuccess(t('Updated the announcement'));
            }}
            messages={adminAnnouncements && adminAnnouncements['mentors']?.messages}
          />
        </CustomRow>
        <CustomRow>
          <SectionTitle>
            {t('information_to_all_students', {
              defaultValue: 'Announcement to all students',
            })}
          </SectionTitle>
          <AnnouncementsInput
            onSave={sentences => {
              dispatch(saveAnnouncement(sentences, 'students'));
              onSaveSuccess(t('Updated the announcement'));
            }}
            messages={adminAnnouncements && adminAnnouncements['students']?.messages}
          />
        </CustomRow>
        <CustomRow>
          <SectionTitle>{t('Default Language')}</SectionTitle>
          <DefaultLanguage
            onSave={lang => {
              dispatch(saveDefaultLanguage(lang));
              onSaveSuccess(t('Updated the default language'));
            }}
            selectedLang={defaultLanguage}
          />
        </CustomRow>
        <CustomRow>
          <SectionTitle>{t('Default Role')}</SectionTitle>
          <DefaultRole
            onSave={role => {
              dispatch(saveDefaultRole(role));
              onSaveSuccess(t('Updated the default role'));
            }}
            selectedRole={defaultRole}
          />
        </CustomRow>
        <CustomRow>
          <SectionTitle>{t('Name of service')}</SectionTitle>
          <AdminServiceNameConfig
            onSave={name => {
              dispatch(saveServiceName(name));
              onSaveSuccess(t('Updated the service name'));
            }}
            name={serviceName}
          />
        </CustomRow>
        {isCustomLogoEnabled && (
          <CustomRow>
            <SectionTitle>{t('Upload a logo')}</SectionTitle>
            <CustomLogoUploader />
          </CustomRow>
        )}
        {isMeteredDiscussionEnabled && (
          <CustomRow>
            <SectionTitle>Metered timer text</SectionTitle>
            <AnnouncementsInput
              onSave={sentences => {
                dispatch(
                  saveMeteredDiscussionConfig({
                    timerStartedMessage: sentences.join(''),
                  }),
                );
                onSaveSuccess(t('Updated the metered discussion config'));
              }}
              messages={config && config.timerStartedMessage.split('\n')}
            />
          </CustomRow>
        )}
        <CustomRow>
          <SectionTitle>{t('Login providers')}</SectionTitle>
          <LoginProviderToggles
            onSave={loginProviders => {
              dispatch(updateLoginProviders(loginProviders));
              onSaveSuccess(t('Updated the login providers'));
            }}
          />
        </CustomRow>
      </Col>
    </Row>
  );
};

const CustomRow = styled.div`
  width: 100%;
`;
export default AdminConfiguration;
