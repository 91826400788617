import React from 'react';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useTypedSelector } from '../../../hooks';
import { getBlob, requestBlob } from '../../../modules';
import { Image } from '../../_atoms/Image';
type Props = {
  imageId: string;
  chatRoomId: string;
  onImageClicked: (img: string) => void;
};

export const LazyImage = (props: Props) => {
  const { imageId, chatRoomId, onImageClicked } = props;
  const dispatch = useDispatch();
  const blob = useTypedSelector(state => getBlob(imageId, state));
  React.useEffect(() => {
    dispatch(requestBlob(chatRoomId, imageId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return blob ? (
    <Image src={blob.objectUrl} onClick={() => onImageClicked(blob.objectUrl)} />
  ) : (
    <Spin indicator={antIcon} />
  );
  // </div>
};
