import React from 'react';
import styled from 'styled-components';

import { Icons } from '../../Icons';
import theme from '../../../theme';
import { LeftMenu } from '../../_molecules/LeftMenu';

type Props = {};
export const AdminLeftMenuContent: React.FC<Props> = () => {
  return (
    <Container>
      <LogoContainer>
        <Icons.Logo />
      </LogoContainer>
      <LeftMenu />
    </Container>
  );
};

export default AdminLeftMenuContent;

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  padding-left: 30px;
  & svg {
    height: 50px;
    width: 120px;
    color: ${theme.colors.white};
  }
  margin-bottom: 50px;
`;
