import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../styled';
import { UserRole } from '../../../services/database/types';

const { Option } = Select;
type Props = {
  onSave: (role: UserRole) => void;
  selectedRole?: UserRole;
};

export const DefaultRole: React.FC<Props> = props => {
  const { onSave, selectedRole } = props;
  const { t } = useTranslation('Admin');
  const [role, setRole] = useState<UserRole>('student');

  useEffect(() => {
    if (selectedRole) setRole(selectedRole);
  }, [selectedRole, setRole]);
  return (
    <Container>
      <SelectWrapper>
        <Select value={role} onChange={(role: UserRole) => setRole(role)}>
          <Option value="student">{t('student')}</Option>
          <Option value="mentor">{t('tutor')}</Option>
          <Option value="tenantAdmin">{t('admin')}</Option>
        </Select>
      </SelectWrapper>
      <StyledButton style={{ width: 100 }} onClick={() => onSave(role)}>
        {t('Save')}
      </StyledButton>
    </Container>
  );
};

const SelectWrapper = styled.div`
  width: 100%;
  .ant-select {
    width: 100%;
  }
  & div.ant-select-selector {
    border-radius: 25px !important;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin: 10px 0;
  }
`;
