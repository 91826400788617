import { empty } from 'rxjs';
import { ActionsObservable } from 'redux-observable';
import { filter, takeUntil } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import * as Sentry from '@sentry/browser';

import { Metrics } from '../services/metrics';

import { SIGN_OUT } from './User';
import { RootAction } from '.';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const catchHandler = (error: any) => {
  // eslint-disable-next-line no-console
  console.error(error);
  Metrics.getLogger().logEvent('error', error.message);
  Sentry.captureException(error);
  return empty();
};

export const unSubscribeOnSignOut = (action$: ActionsObservable<RootAction>) =>
  takeUntil<RootAction>(action$.pipe(filter(isOfType(SIGN_OUT))));

export type APIState = {
  isLoading: boolean;
  error?: string;
};

export const DEFAULT_API_STATE: APIState = {
  isLoading: false,
};

export const errorAction = <T extends Function>(action: T) => (error: string) =>
  action({ error });
