import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IconWrapper, StyledButton } from '../../../styled';
import { useTypedSelector } from '../../../hooks';
import { getBlobState, getHasCustomLogo, uploadCustomLogo } from '../../../modules';
import FileSelector from '../../FileSelector';
import Loader from '../../Loader';
import { Icons } from '../../Icons';

export default () => {
  const { t } = useTranslation('admin');
  const { isLoading, blob } = useTypedSelector(state =>
    getBlobState('customLogo', state),
  );
  const dispatch = useDispatch();
  const hasCustomLogo = useTypedSelector(getHasCustomLogo);

  const [, setIsEditingCustomLogo] = useState(false);

  const handleFileSelected = (imgDataUrl: string) => {
    dispatch(uploadCustomLogo(imgDataUrl));
    setIsEditingCustomLogo(false);
  };
  return (
    <CustomLogoContainer>
      {!hasCustomLogo && (
        <FileSelector onFileSelected={handleFileSelected}>
          <StyledButton style={{ width: 250 }}>{t('Click to select image')}</StyledButton>
        </FileSelector>
      )}
      {hasCustomLogo && isLoading && <Loader />}
      {hasCustomLogo && blob && !isLoading && (
        <>
          <ImgStyled src={blob.objectUrl} />
          <EditIconWrapper color={'#a5a5a5'} onClick={() => setIsEditingCustomLogo(true)}>
            <FileSelector onFileSelected={handleFileSelected}>
              <Icons.Edit />
            </FileSelector>
          </EditIconWrapper>
        </>
      )}
    </CustomLogoContainer>
  );
};

const EditIconWrapper = styled(IconWrapper)`
  align-items: flex-start;
  height: 100%;
`;

const CustomLogoContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgStyled = styled.img`
  height: 100px;
  margin-right: 10px;
`;
