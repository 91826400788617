import React from 'react';
import PinchToZoom from 'react-pinch-and-zoom';
import styled from 'styled-components';

import { useIsDesktop } from '../../../hooks';

type Props = {
  src?: string;
};
export const PreviewImage = (props: Props) => {
  const isDesktop = useIsDesktop();
  return isDesktop ? (
    <FullSizeImage src={props.src} />
  ) : (
    <PinchToZoom
      debug={false}
      className=""
      minZoomScale={1}
      maxZoomScale={10}
      boundSize={{ height: window.innerHeight, width: window.innerWidth }}
      contentSize={{ height: window.innerHeight, width: window.innerWidth }}
    >
      <FullSizeImage src={props.src} />
    </PinchToZoom>
  );
};

const FullSizeImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 25px;
`;
