import firebase, { firestore } from 'firebase/app';
import * as Sentry from '@sentry/browser';
import { fromCollectionRef, fromDocRef, docData } from 'rxfire/firestore';
import 'firebase/firestore';
import { map, catchError, mergeMap, mapTo } from 'rxjs/operators';
import { empty, of, from, forkJoin, combineLatest } from 'rxjs';

import { LoginProviders, ById } from '../../modules';
import {
  UserType,
  MentorPreferences,
  PendingReview,
  Message,
  ChatRoomEntity,
} from '../db';
import { notUndefined } from '../../utils/typeUtils';

import {
  RatingReview,
  ChatRoomMetrics,
  UserRating,
  GeneralUserFeedback,
  PopularTag,
  MentorAccessRequest,
  Announcements,
  PaymentSubscription,
  DiscussionLiveInfo,
  ChatRoomTag,
  MeteredDiscussion,
  UserRole,
  MeteredDiscussionConfig,
  AvailableSubscriptionRef,
  Discussion,
} from './types';
export default class Database {
  private store: firebase.firestore.Firestore;
  private tenantId: string;
  private root: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  private ratings: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;
  private ratingItems: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;
  private popularTags: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;
  private publicRef: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;
  private discussionsRef: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;
  private allDiscussionsRef: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;
  private ratingUsers: firebase.firestore.DocumentReference<
    firebase.firestore.DocumentData
  >;
  private announcementsRef: firebase.firestore.DocumentReference<
    firebase.firestore.DocumentData
  >;
  private generalUserFeedback: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;

  private adminRef: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;
  private usertypeRef: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;

  private adminMentorAccessRequestsRef: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;

  private serverRef: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;
  private chatRoomTags: firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  >;
  private now = () => firebase.firestore.Timestamp.now();

  constructor(tenantId: string) {
    this.store = firebase.firestore();
    this.tenantId = tenantId;
    this.root = this.store.collection('tenants').doc(this.tenantId);
    this.serverRef = this.root.collection('server');
    this.announcementsRef = this.serverRef.doc('announcements');
    this.ratings = this.root.collection('ratings');
    this.discussionsRef = this.root.collection('discussions');
    this.allDiscussionsRef = this.discussionsRef.doc('all').collection('discussions');
    this.ratingItems = this.ratings.doc('list').collection('items');
    this.ratingUsers = this.ratings.doc('users');
    this.chatRoomTags = this.root
      .collection('tags')
      .doc('chatRooms')
      .collection('byId');
    this.popularTags = this.root
      .collection('tags')
      .doc('reports')
      .collection('items');
    this.generalUserFeedback = this.root.collection('generalUserFeedback');
    this.publicRef = this.root.collection('public');
    this.adminRef = this.root.collection('admin');
    this.usertypeRef = this.root.collection('usertype');
    this.adminMentorAccessRequestsRef = this.adminRef
      .doc('mentorAccessRequests')
      .collection('items');
  }

  public submitRating = (ratingReview: RatingReview) => {
    return this.ratingItems.doc().set({ ...ratingReview, createdAt: this.now() });
  };

  public submitGeneralUserFeedback = (feedback: GeneralUserFeedback) => {
    return this.generalUserFeedback.doc().set({ ...feedback, createdAt: this.now() });
  };

  admin = {
    usertype: {
      list: () =>
        fromCollectionRef(this.usertypeRef).pipe(
          map(col =>
            col.docs.reduce<ById<UserType>>((acc, doc) => {
              const d = doc.data() as UserType;
              return {
                ...acc,
                [doc.id]: d,
              };
            }, {}),
          ),
          catchError(e => {
            Sentry.captureException(e);
            return empty();
          }),
        ),
    },
  };

  reviewQueue = {
    pending: {
      list: (uid: string) =>
        fromCollectionRef(
          this.root
            .collection('reviewQueue')
            .doc(uid)
            .collection('pending'),
        ).pipe(map(snap => snap.docs.map(doc => doc.data() as PendingReview))),
      delete: async (id: string, uid: string) => {
        await this.root
          .collection('reviewQueue')
          .doc(uid)
          .collection('pending')
          .doc(id)
          .delete();
      },
    },
    lastReviewed: {
      set: async (uid: string) => {
        await this.root
          .collection('reviewQueue')
          .doc(uid)
          .set({
            lastSeen: new Date().getTime(),
          });
      },
      get: (uid: string) =>
        fromDocRef(this.root.collection('reviewQueue').doc(uid)).pipe(
          map(doc => doc.data() as { lastSeen: number } | undefined),
          map(data => data?.lastSeen),
        ),
    },
  };

  usertype = {
    get: (uid: string) =>
      fromDocRef(this.usertypeRef.doc(uid)).pipe(
        mergeMap(d => {
          const usertype = d.data();
          if (!usertype) throw Error('Something is wrong. Cannot find usertype');
          let lastSeenInfoBanner = usertype.lastSeenInfoBanner;
          if (lastSeenInfoBanner) lastSeenInfoBanner = lastSeenInfoBanner.toDate();
          return of({
            ...usertype,
            lastSeenInfoBanner,
          } as UserType);
        }),
      ),
    setAvailable: async (available: boolean, uid: string) => {
      await this.usertypeRef.doc(uid).update({ isAvailable: available });
    },
    setNotificationToken: async (token: string, uid: string) => {
      this.store.runTransaction(async t => {
        //Run transactional because of multiple devices
        const existingInstances = await t.get(this.usertypeRef.doc(uid));
        const data = existingInstances.exists && existingInstances.data();
        let instanceIds = (data && data.instanceIds) || {};
        const exists = Object.values(instanceIds).some(id => id === token);
        if (!exists) {
          instanceIds = { ...instanceIds, [new Date().getTime()]: token };
        }
        await t.update(this.usertypeRef.doc(uid), { instanceIds });
      });
    },
    setMentorSupportedTopic: async (
      uid: string,
      mentorPreferences: MentorPreferences,
    ) => {
      await this.usertypeRef.doc(uid).update({ mentorPreferences });
    },
    setNickName: async (nickName: string, uid: string) => {
      await this.usertypeRef.doc(uid).update({ nickName });
    },
    setLastSeenInfoBanner: async (uid: string) => {
      await this.usertypeRef.doc(uid).update({ lastSeenInfoBanner: this.now() });
    },
  };

  tags = {
    get: (chatRoomId: string) =>
      docData<ChatRoomTag>(this.chatRoomTags.doc(chatRoomId)).pipe(
        map(tag => ({ [tag.chatRoomId]: tag.tags })),
      ),
    fetchByIds: (ids: string[]) =>
      forkJoin(
        ids.map(id => from(this.chatRoomTags.doc(id).get()).pipe(map(s => s.data()))),
      ).pipe(
        map(docs =>
          docs
            .filter(doc => doc !== undefined)
            .reduce<ById<string[]>>((acc, doc) => {
              const data = doc as ChatRoomTag;
              return {
                ...acc,
                [data.chatRoomId]: data.tags,
              };
            }, {}),
        ),
      ),
  };

  announcements = {
    save: async (messages: string[], docId: string) => {
      await this.announcementsRef
        .collection('messages')
        .doc(docId)
        .set({ messages, updatedAt: this.now() });
    },
    get: (docId: string) =>
      fromDocRef(this.announcementsRef.collection('messages').doc(docId)).pipe(
        mergeMap(doc => {
          const data = doc.data();
          if (!data) return empty();
          return of({ ...data, updatedAt: data.updatedAt.toDate() } as Announcements);
        }),
      ),
    admin: {
      list: () =>
        fromCollectionRef(this.announcementsRef.collection('messages')).pipe(
          map(snap =>
            snap.docs.reduce<ById<Announcements>>((acc, doc) => {
              const d = doc.data();
              return {
                ...acc,
                [doc.id]: {
                  ...d,
                  updatedAt: new Date(d.updatedAt),
                } as Announcements,
              };
            }, {}),
          ),
        ),
    },
  };

  public getChatRoomMetrics = () => {
    return fromCollectionRef(this.root.collection('chatRoomMetrics')).pipe(
      map(snap =>
        snap.docs.map(doc => {
          const d = doc.data();
          return {
            ...d,
            completedAt: d.completedAt ? d.completedAt.toDate() : undefined,
            createdAt: d.createdAt ? d.createdAt.toDate() : undefined,
            claimedAt: d.claimedAt ? d.claimedAt.toDate() : undefined,
          } as ChatRoomMetrics;
        }),
      ),
      catchError(e => {
        Sentry.captureException(e);
        return empty();
      }),
    );
  };

  public getUserRatings = () => {
    return fromCollectionRef(this.ratingUsers.collection('byId')).pipe(
      map(snap =>
        snap.docs.map(d => {
          const entity = d.data();
          return { ...entity, uid: d.id } as UserRating;
        }),
      ),
      catchError(e => {
        Sentry.captureException(e);
        return empty();
      }),
    );
  };

  public getPopularTags = () => {
    return fromCollectionRef(this.popularTags).pipe(
      map(snap =>
        snap.docs.map(doc => {
          const data = doc.data();
          return {
            ...data,
            timestamp: new Date(data.timestamp),
          } as PopularTag;
        }),
      ),
    );
  };
  public searchTags = (value: string) => {
    if (value.length === 0) return of([]);
    return fromCollectionRef(
      this.root
        .collection('tags')
        .doc('aggregated')
        .collection('byTag')
        .where(`searchableIndex.${value}`, '==', true),
    ).pipe(
      map(snap =>
        snap.docs
          .map(doc => doc.data())
          .sort((a, b) => b.entries - a.entries)
          .map(d => d.value as string),
      ),
    );
  };

  public getPublic = () =>
    fromCollectionRef(this.root.collection('public')).pipe(
      map(snap =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        snap.docs.reduce<{ [id: string]: any }>(
          (acc, cur) => ({ ...acc, [cur.id]: cur.data() }),
          {},
        ),
      ),
    );

  public setDefaultLanguage = (lang: string) => {
    return this.publicRef.doc('defaultLanguage').set({ lang });
  };

  public setDefaultRole = (role: UserRole) => {
    return this.publicRef.doc('defaultRole').set({ role });
  };

  public setLoginProviders = (loginProviders: LoginProviders) => {
    return this.publicRef.doc('loginProviders').set(loginProviders);
  };

  public setHasCustomLogo = (hasCustomLogo = true) => {
    return this.publicRef.doc('custom').update({ logo: hasCustomLogo });
  };

  public setServiceName = (name: string) => {
    return this.publicRef.doc('custom').update({ name });
  };

  mentorAccessRequests = {
    admin: {
      list: () =>
        fromCollectionRef(this.adminMentorAccessRequestsRef).pipe(
          map(snap =>
            snap.docs.map(doc => {
              const data = doc.data();
              return {
                ...data,
                timestamp: new Date(data.timestamp),
              } as MentorAccessRequest;
            }),
          ),
        ),
      removeMentorAccessRequest: async (uid: string) => {
        await this.adminMentorAccessRequestsRef.doc(uid).delete();
      },
    },
    requestAccess: async (uid: string) => {
      await this.adminMentorAccessRequestsRef
        .doc(uid)
        .set({ uid, timestamp: this.now() });
    },
  };

  discussions = {
    setLiveInfo: (chatroomId: string, uid: string, typing: boolean) =>
      this.discussionsRef
        .doc('live')
        .collection('discussions')
        .doc(chatroomId)
        .collection('info')
        .doc(uid)
        .set({ typing, lastSeen: this.now() }),
    subscribeToLiveInfo: (chatRoomId: string, uid: string) =>
      fromDocRef(
        this.discussionsRef
          .doc('live')
          .collection('discussions')
          .doc(chatRoomId)
          .collection('info')
          .doc(uid),
      ).pipe(
        mergeMap(snap => {
          if (!snap.exists) return empty();
          const data = snap.data();
          if (!data) return empty();

          return of({
            typing: data.typing,
            lastSeen: data.lastSeen.toDate().getTime(),
          } as DiscussionLiveInfo);
        }),
      ),
    startMeteredDiscussions: (chatroomId: string) =>
      from(
        this.discussionsRef
          .doc('meteredDiscussions')
          .collection('discussions')
          .doc(chatroomId)
          .set({
            startedAt: this.now(),
          }),
      ),
    getMeteredDiscussionById: (chatroomId: string) =>
      fromDocRef(
        this.discussionsRef
          .doc('meteredDiscussions')
          .collection('discussions')
          .doc(chatroomId),
      ).pipe(
        mergeMap(doc => {
          const data = doc.data();
          if (!data) return empty();
          return of({
            ...data,
            startedAt: data.startedAt.toDate(),
          } as MeteredDiscussion);
        }),
      ),
    saveMeteredDiscussionConfig: (config: MeteredDiscussionConfig) =>
      from(this.discussionsRef.doc('meteredDiscussions').set(config)),
    getMeteredDiscussionConfig: () =>
      from(this.discussionsRef.doc('meteredDiscussions').get()).pipe(
        mergeMap(doc => {
          const data = doc.data();
          return data ? of(data as MeteredDiscussionConfig) : empty();
        }),
      ),
    addDiscussionToUser: async (uid: string, discussionId: string) => {
      await this.root
        .collection('students')
        .doc(uid)
        .collection('discussions')
        .doc(discussionId)
        .set({ id: discussionId });
    },
    getDiscussionIds: (uid: string, type: 'mentors' | 'students') =>
      fromCollectionRef(
        this.root
          .collection(type)
          .doc(uid)
          .collection('discussions'),
      ).pipe(map(snap => snap.docs.map(doc => (doc.data() as { id: string }).id))),

    getDiscussion: async (discussionId: string) => {
      try {
        const snap = await this.allDiscussionsRef.doc(discussionId).get();
        const messagesSnap = await this.allDiscussionsRef
          .doc(discussionId)
          .collection('messages')
          .get();

        return {
          ...(snap.data() as ChatRoomEntity),
          messages: messagesSnap.docs.reduce<ById<Message>>(
            (acc, cur) => ({ ...acc, [cur.id]: cur.data() as Message }),
            {},
          ),
        } as ChatRoomEntity;
      } catch (e) {
        Sentry.captureException(e);
        return undefined;
      }
    },
    listDiscussions: (discussionIds: string[]) =>
      of(discussionIds).pipe(
        mergeMap(ids =>
          forkJoin(ids.reverse().map(id => this.discussions.getDiscussion(id))),
        ),
        map(chatRooms => chatRooms.filter(notUndefined)),
      ),
    create: async (discussionId: string, discussion: Discussion, messages: Message[]) => {
      await this.allDiscussionsRef
        .doc(discussionId)
        .set({ ...discussion, id: discussionId, createdAt: new Date().getTime() });
      const batch = this.store.batch();
      messages.forEach((msg, id) => {
        batch.set(
          this.allDiscussionsRef
            .doc(discussionId)
            .collection('messages')
            .doc(String(id)),
          msg,
        );
      });
      await batch.commit();
    },
    resolve: async (discussionId: string, uid: string) => {
      await this.allDiscussionsRef.doc(discussionId).update({
        isComplete: true,
        completedBy: uid,
        completedAt: new Date().getTime(),
        completedAtISO: new Date().toISOString(),
      });
    },
    sendMessage: async (discussionId: string, message: Message) => {
      if (!message.id) throw Error('Message not created in old db');
      await this.allDiscussionsRef
        .doc(discussionId)
        .collection('messages')
        .doc(message.id)
        .set(message);
    },
    subscribeMessages: (discussionId: string) =>
      from(
        this.allDiscussionsRef
          .doc(discussionId)
          .collection('messages')
          .get(),
      ).pipe(
        map(docs =>
          docs.docs.reduce<ById<Message>>(
            (acc, doc) => ({ ...acc, [doc.id]: doc.data() as Message }),
            {},
          ),
        ),
      ),

    subscribe: (discussionId: string) => {
      const discussionMetadata$ = fromDocRef(
        this.allDiscussionsRef.doc(discussionId),
      ).pipe(map(doc => ({ ...doc.data() } as ChatRoomEntity)));

      const messages$ = fromCollectionRef(
        this.allDiscussionsRef.doc(discussionId).collection('messages'),
      ).pipe(
        map(docs =>
          docs.docs.reduce<ById<Message>>(
            (acc, doc) => ({ ...acc, [doc.id]: doc.data() as Message }),
            {},
          ),
        ),
      );
      return combineLatest([discussionMetadata$, messages$]).pipe(
        map(([metadata, messages]) => ({ ...metadata, messages } as ChatRoomEntity)),
      );
    },
    fullDiscussionsById: (query: firestore.Query<firestore.DocumentData>) => {
      return from(query.get()).pipe(
        map(snap => snap.docs.map(doc => doc.data() as ChatRoomEntity)),
        mergeMap(discussions => {
          return forkJoin(
            discussions.map(discussion =>
              this.discussions.subscribeMessages(discussion.id!).pipe(
                map(messages => ({ ...discussion, messages } as ChatRoomEntity)),
                catchError(e => {
                  // eslint-disable-next-line no-console
                  console.error('getCompletedDiscussions error', e);
                  return of(undefined);
                }),
              ),
            ),
          );
        }),
        map(
          entities =>
            entities.filter(discussion => discussion !== undefined) as ChatRoomEntity[],
        ),
      );
    },
    getCompletedDiscussions: () =>
      this.discussions.fullDiscussionsById(
        this.allDiscussionsRef.where('isComplete', '==', true),
      ),

    getInCompletedDiscussions: () =>
      this.discussions.fullDiscussionsById(
        this.allDiscussionsRef.where('isComplete', '==', false),
      ),
    getUnClaimedDiscussions: () =>
      this.discussions.fullDiscussionsById(
        this.allDiscussionsRef.where('claimedAt', '==', null),
      ),
  };

  payments = {
    getSavedAccount: () =>
      fromDocRef(this.root.collection('payments').doc('savedAccount')).pipe(
        map(ref => {
          const data = ref.data() as { id: string } | undefined;
          return data;
        }),
      ),
    getActiveSubscription: () =>
      fromDocRef(this.root.collection('payments').doc('availableSubscription')).pipe(
        map(ref => (ref.exists ? (ref.data() as PaymentSubscription) : undefined)),
      ),
    listAllAvailableSubscriptions: () =>
      fromCollectionRef(
        this.root
          .collection('payments')
          .doc('subscriptions')
          .collection('available'),
      ).pipe(
        map(snap =>
          snap.docs.reduce<ById<AvailableSubscriptionRef>>((acc, doc) => {
            return {
              ...acc,
              [doc.id]: doc.data() as AvailableSubscriptionRef,
            };
          }, {}),
        ),
        catchError(e => {
          Sentry.captureException(e);
          throw e;
        }),
      ),
    saveAvailableSubscription: (availableSubscription: AvailableSubscriptionRef) =>
      from(
        this.root
          .collection('payments')
          .doc('subscriptions')
          .collection('available')
          .doc(availableSubscription.productId)
          .set({
            ...availableSubscription,
            unit: availableSubscription.unit ? availableSubscription.unit : null,
          }),
      ).pipe(
        mapTo(availableSubscription),
        catchError(e => {
          Sentry.captureException(e);
          throw e;
        }),
      ),
  };
}
