import React from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Icons } from '../../Icons';
import { useTypedSelector, useMetrics } from '../../../hooks';
import {
  updateLastSeenInfoBanner,
  getLastSeenInfoBanner,
  getAnnouncement,
} from '../../../modules';

export default () => {
  const announcement = useTypedSelector(getAnnouncement);
  const lastSeen = useTypedSelector(getLastSeenInfoBanner);
  const { t } = useTranslation('home');
  const dispatch = useDispatch();
  const metrics = useMetrics();
  const handleClose = () => {
    dispatch(updateLastSeenInfoBanner());
    metrics.logEvent('close-info-banner');
    message.info(
      t('close-info-banner', {
        defaultValue:
          'You will get a new message when your administrator has a new message for you',
      }),
    );
  };

  if (!announcement?.messages) return null;
  if (
    lastSeen &&
    announcement?.updatedAt.getTime() &&
    lastSeen.getTime() > announcement?.updatedAt.getTime()
  )
    return null;
  return (
    <Container>
      <Banner>
        <p>{announcement.messages}</p>
        <IconWrapper onClick={handleClose}>
          <Icons.Close />
        </IconWrapper>
      </Banner>
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 20px;
  width: 100%;
`;

const IconWrapper = styled.div`
  flex: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  svg {
    height: 12px;
    width: 12px;
  }
`;

const Banner = styled.div`
  padding: 10px;
  background-color: #9ea2be;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  display: flex;
  & p {
    flex: 1;
    margin: 0;
    padding-right: 5px;
  }
`;
