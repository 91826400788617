import React, { useEffect } from 'react';
import { notification, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { fetchTemplate, templateById } from '../modules';
import { PendingReview } from '../services/db';
import { getPromptedReview, reviewWasSeen } from '../modules';
import { useTypedSelector, useMetrics, useQuery } from '../hooks';

export const ReviewNotificationProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const dispatch = useDispatch();
  const metrics = useMetrics();
  const { t } = useTranslation('review');
  const { search } = useQuery();
  const history = useHistory();
  const promptedReview = useTypedSelector(getPromptedReview);

  useEffect(() => {
    if (promptedReview?.templateId) {
      dispatch(fetchTemplate(promptedReview.templateId));
    }
  }, [dispatch, promptedReview]);
  const { template, isLoading } = useTypedSelector(state =>
    templateById(state, promptedReview?.templateId),
  );

  useEffect(() => {
    if (promptedReview && template && !isLoading) {
      const openNotificationWithIcon = (promptedReview: PendingReview) => {
        const close = () => {
          // TODO add metric
          metrics.logEvent('notification.dismissed', {
            templateId: promptedReview.templateId,
          });
          dispatch(reviewWasSeen(promptedReview));
        };

        const onConfirm = (key: string) => {
          metrics.logEvent('notification.accepted', {
            templateId: promptedReview.templateId,
          });
          dispatch(reviewWasSeen(promptedReview));
          const newPath = `/main/review${search}`;
          history.push(newPath, {
            templateId: promptedReview.templateId,
            mentorId: promptedReview.metadata?.mentorId,
            chatRoomId: promptedReview.metadata?.chatRoomId,
          });
          notification.close(key);
        };
        const key = promptedReview.id;
        const confirmButton = (
          <Button type="primary" size="small" onClick={() => onConfirm(key)}>
            {t('Answer here')}
          </Button>
        );
        notification.info({
          message: t(template.title),
          description: t(template.description),
          duration: 60,
          btn: confirmButton,
          key,
          onClose: close,
        });
      };
      openNotificationWithIcon(promptedReview);
    }
  }, [dispatch, promptedReview, template, isLoading, metrics, search, t, history]);

  return <>{children}</>;
};

export default ReviewNotificationProvider;
