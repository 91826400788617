import { createAction } from 'typesafe-actions';

import { ChatRoomEntity, Message } from '../../services/db';
import { NamesByIdsResponse } from '../../services/backendService';
import { DiscussionLiveInfo, MeteredDiscussion } from '../../services/database/types';

import * as ActionTypes from './actionTypes';

import { ById } from '..';

export const chatRoomRequested = createAction(ActionTypes.CHATROOMS_REQUESTED);

export const chatRoomReceived = createAction(
  ActionTypes.CHATROOMS_RECEIVED,
  action => (chatRooms: ChatRoomEntity[]) => action(chatRooms),
);

export const getNames = createAction(
  ActionTypes.GET_NAMES_BY_ID,
  action => (uids: string[]) => action(uids),
);

export const getNamesSuccess = createAction(
  ActionTypes.GET_NAMES_BY_ID_SUCCESS,
  action => (uids: NamesByIdsResponse) => action(uids),
);
export const getNamesFailed = createAction(ActionTypes.GET_NAMES_BY_ID_FAILED);
export const completeChatRoom = createAction(
  ActionTypes.CHATROOM_COMPLETED,
  action => (id: string) => action(id),
);

export const addChatRoomToUser = createAction(
  ActionTypes.ADD_CHATROOM_TO_USER,
  action => (chatRoomId: string) => action(chatRoomId),
);

export const chatRoomSubscribe = createAction(
  ActionTypes.CHATROOM_SUBSCRIBE,
  action => (chatRoomId: string) => action(chatRoomId),
);

export const chatRoomUpdated = createAction(
  ActionTypes.CHATROOM_UPDATED,
  action => (chatRoom: ChatRoomEntity) => action(chatRoom),
);

export const sendMessage = createAction(
  ActionTypes.SEND_MESSAGE,
  action => (message: Message, chatRoomId: string) => action({ message, chatRoomId }),
);

export const uploadImage = createAction(
  ActionTypes.UPLOAD_IMAGE,
  action => (chatRoomId: string, imgDataUrl: string, uid: string) =>
    action({ chatRoomId, imgDataUrl, uid }),
);

export const selectChatRoom = createAction(
  ActionTypes.CHATROOM_SELECTED,
  action => (chatRoomId: string) => action({ chatRoomId }),
);

export const selectTab = createAction(ActionTypes.SELECT_TAB, action => (tabId: 0 | 1) =>
  action({ tabId }),
);

export const deSelectChatRoom = createAction(ActionTypes.DESELECT_CHATROOM);

export const setDiscussionLiveInfo = createAction(
  ActionTypes.SET_DISCUSSION_LIVE_INFO,
  action => (chatRoomId: string, isTyping: boolean) => action({ chatRoomId, isTyping }),
);

export const subscribeDiscussionLiveInfo = createAction(
  ActionTypes.SUBSCRIBE_DISCUSSION_LIVE_INFO,
  action => (chatRoomId: string) => action({ chatRoomId }),
);
export const receiveDiscussionLiveInfo = createAction(
  ActionTypes.RECEIVE_DISCUSSION_LIVE_INFO,
  action => (chatRoomId: string, uid: string, data: DiscussionLiveInfo) =>
    action({ chatRoomId, uid, data }),
);

export const receiveTags = createAction(
  ActionTypes.RECEIVE_TAGS,
  action => (tags: ById<string[]>) => action({ tags }),
);
export const requestTags = createAction(
  ActionTypes.REQUEST_TAGS,
  action => (chatRoomId: string) => action({ chatRoomId }),
);

export const startMeteredDiscussionTimer = createAction(
  ActionTypes.START_DISCUSSION_TIMER,
  action => (chatRoomId: string) => action({ chatRoomId }),
);
export const meteredDiscussionReceived = createAction(
  ActionTypes.METERED_DISCUSSION_RECEIVED,
  action => (chatRoomId: string, data: MeteredDiscussion) => action({ chatRoomId, data }),
);
