import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import icon from '../../../assets/images/icon.png'; // Tell Webpack this JS file uses this image
import theme from '../../../theme';
import { Icons } from '../../Icons';

import MessageBubble from './MessageBubble';

const WAIT_FOR_MENTOR_MESSAGE =
  'Hey! We are finding a mentor for you. In the mean time, feel free tostart writing your questions!';
const MENTOR_ASSIGNED_MESSAGE = 'You are now discussing with {{name}}. Enjoy!';

type Props = {
  mentorName?: string;
};

export default (props: Props) => {
  const { mentorName } = props;
  const { t } = useTranslation('chatroom');

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (mentorName) {
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    } else {
      setVisible(true);
    }
  }, [mentorName]);

  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <Container visible={visible}>
      <ImageContainer>
        <img src={icon} alt="logo" />
      </ImageContainer>
      <MessageContainer>
        <CloseIcon onClick={handleClick}>
          <Icons.Close />
        </CloseIcon>
        <p>
          {mentorName
            ? t('foundmentor', {
                defaultValue: MENTOR_ASSIGNED_MESSAGE,
                name: mentorName,
              })
            : t('waitformentor', { defaultValue: WAIT_FOR_MENTOR_MESSAGE })}
        </p>
      </MessageContainer>
    </Container>
  );
};

const CloseIcon = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 100px;
  text-align: center;
  height: 20px;
  width: 20px;
  background-color: #474747;
  svg {
    height: 10px;
    width: 10px;
  }
`;

const ImageContainer = styled.div<{ visible?: boolean }>`
  width: 40px;
  height: 40px;
  flex: 0;
  margin-right: 5px;
  img {
    width: 40px;
    height: 40px;
  }
`;

const MessageContainer = styled(MessageBubble)<{ visible?: boolean }>`
  position: relative;

  flex: 1;
  max-width: 200px;
  margin-bottom: 10px;
  background-color: ${theme.colors.orange};
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: ${theme.colors.white};
  & p {
    margin: 0;
  }
`;

const Container = styled.div<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: flex-end;
  margin-left: 20px;
  flex: 0;
`;
