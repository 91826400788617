import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ById, Topic } from '../../../modules';
import { StyledButton } from '../../../styled';
import theme from '../../../theme';

import { Title } from './Title';

type Props = {
  topics: ById<Topic>;
  selectedTopicId?: string;
  onSelect: (topicId: string, subTopic: string) => void;
};
export function SelectOption(props: Props) {
  const { topics, onSelect } = props;

  const [selectedTopic, setSelectedTopic] = useState<string>();

  const handleSelectTopic = (topicId: string) => {
    setSelectedTopic(topicId);
  };
  const handleSelectSubTopic = (topicId: string, subTopicId: string) => {
    onSelect(topicId, subTopicId);
  };

  const { t } = useTranslation('newDiscussion');

  const subTopics = selectedTopic && topics[selectedTopic].subTopics;

  if (selectedTopic && subTopics && Object.values(subTopics).length === 1)
    handleSelectSubTopic(selectedTopic, Object.values(subTopics)[0].id);

  return (
    <StepContainer>
      {!selectedTopic ? (
        <>
          <div>
            <Title>
              {t('topic-help', {
                defaultValue: 'What do you need help with?',
              })}
            </Title>
          </div>
          {Object.values(topics).map(topic => (
            <ButtonContainer key={topic.id}>
              <SuperStyledButton
                backgroundColor="#FFFFFF"
                color="#8C8C8C"
                onClick={() => handleSelectTopic(topic.id)}
              >
                {topic.name}
              </SuperStyledButton>
            </ButtonContainer>
          ))}
        </>
      ) : (
        <>
          <div>
            <Title>
              {t('topic-help', { defaultValue: 'What do you need help with?' })}
            </Title>
          </div>
          {subTopics &&
            Object.values(subTopics).map(subTopic => (
              <ButtonContainer key={subTopic.id}>
                <SuperStyledButton
                  backgroundColor="#FFFFFF"
                  color="#8C8C8C"
                  onClick={() => handleSelectSubTopic(selectedTopic, subTopic.id)}
                >
                  {subTopic.name}
                </SuperStyledButton>
              </ButtonContainer>
            ))}
        </>
      )}
    </StepContainer>
  );
}

const SuperStyledButton = styled(StyledButton)`
  &:hover {
    background-color: ${theme.colors.orange};
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
