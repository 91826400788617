import React from 'react';
import styled from 'styled-components';

import { Icons } from '../../Icons';
import { CircleButton } from '../../../styled';

interface Props {
  onClick: () => void;
  icon: 'New';
  text?: string;
  backgroundColor: string;
}

export const CircledButton = (props: Props) => {
  const { onClick, icon, text, backgroundColor } = props;
  const Icon = Icons[icon];
  return (
    <Container>
      <CircleButton onClick={onClick} backgroundColor={backgroundColor}>
        <Icon />
      </CircleButton>
      {text && <p>{text}</p>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    padding-left: 10px;
  }
`;
