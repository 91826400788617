import { AppState } from '../rootReducer';

import { BLOB_RECEIVED, BLOB_REQUESTED, CUSTOM_LOGO_RECEIVED } from './actionTypes';

import { RootAction } from '..';

export interface BlobsState {
  byId: { [id: string]: { id: string; objectUrl: string } };
  loadingById: { [id: string]: boolean };
}

const initialState: BlobsState = {
  byId: {},
  loadingById: {},
};

export const blobsReducer = (state = initialState, action: RootAction): BlobsState => {
  switch (action.type) {
    case CUSTOM_LOGO_RECEIVED: {
      const objectUrl = URL.createObjectURL(action.payload.blob);
      return {
        ...state,
        byId: { ...state.byId, customLogo: { id: 'customLogo', objectUrl } },
        loadingById: { ...state.loadingById, customLogo: false },
      };
    }
    case BLOB_RECEIVED: {
      const objectUrl = URL.createObjectURL(action.payload.blob);
      const { id } = action.payload;
      return {
        ...state,
        byId: { ...state.byId, [id]: { id, objectUrl } },
        loadingById: { ...state.loadingById, [id]: false },
      };
    }
    case BLOB_REQUESTED:
      return {
        ...state,
        loadingById: { ...state.loadingById, [action.payload.id]: true },
      };
    default:
      return state;
  }
};

// selectors
export const getIsLoading = (id: string, state: AppState) => state.blobs.loadingById[id];

export const getBlob = (id: string, state: AppState) => state.blobs.byId[id];

export const getBlobState = (id: string, state: AppState) => ({
  blob: getBlob(id, state),
  isLoading: getIsLoading(id, state),
});
