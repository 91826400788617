import React from 'react';

export default () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="14.2285"
      y="0.0908203"
      width="8.77754"
      height="37.0607"
      rx="4.38877"
      fill="currentColor"
    />
    <rect
      x="37.1504"
      y="14.2327"
      width="8.77754"
      height="37.0607"
      rx="4.38877"
      transform="rotate(90 37.1504 14.2327)"
      fill="currentColor"
    />
  </svg>
);
