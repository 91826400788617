export const NOTIFICATION_RECEIVED = 'NOTIFICATION/RECEIVED';
export const NOTIFICATION_REMOVED = 'NOTIFICATION/REMOVED';
export const NOTIFICATION_SUBSCRIBE = 'NOTIFICATION/SUBSCRIBE';

export const MATCHMAKING_REQUESTED = 'NOTIFICATION/MATCHMAKING_REQUESTED';
export const MATCHMAKING_RECEIVED = 'NOTIFICATION/MATCHMAKING_RECEIVED';
export const MATCHMAKING_RECEIVED_ERROR = 'NOTIFICATION/MATCHMAKING_RECEIVED_ERROR';
export const MATCHMAKING_RESET = 'NOTIFICATION/MATCHMAKING_RESET';

export const NEW_VERSION_AVAILABLE = 'SW/VERSION_AVAILABLE';
export const UPDATE_SERVICE_WORKER = 'SW/UPDATE';
export const UPDATE_SERVICE_WORKER_SUCCESS = 'SW/UPDATE_SUCCESS';
export const UPDATE_SERVICE_WORKER_ERROR = 'SW/UPDATE_ERROR';
