import { combineReducers } from 'redux';

import {
  notificationReducer,
  chatRoomReducer,
  userReducer,
  userTypeReducer,
  environmentReducer,
  blobsReducer,
  serverReducer,
  reviewsReducer,
  adminReducer,
  createDiscussionReducer,
  mentorReducer,
  RootAction,
  paymentsReducer,
  authReducer,
} from '../modules';

const appReducer = combineReducers({
  notification: notificationReducer,
  chatRoom: chatRoomReducer,
  userType: userTypeReducer,
  auth: authReducer,
  user: userReducer,
  env: environmentReducer,
  blobs: blobsReducer,
  server: serverReducer,
  reviews: reviewsReducer,
  admin: adminReducer,
  mentor: mentorReducer,
  createDiscussion: createDiscussionReducer,
  payments: paymentsReducer,
});

export type AppState = ReturnType<typeof appReducer>;

export const rootReducer = (state: AppState, action: RootAction) => {
  if (action.type === 'RESET_APP') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
