import React, { Component } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';

import Background from '../assets/images/background_grey.png';
import theme from '../theme';
import { BaseButton } from '../styled';

interface ErrorState {
  hasError: boolean;
}
interface ErrorProps {
  children: React.ReactChild;
}
class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any, info: any) {
    this.setState({
      hasError: true,
    });
    // eslint-disable-next-line no-console
    console.error('componentDidCatch error', error);
    // eslint-disable-next-line no-console
    console.error('componentDidCatch info', info);
    if (process.env.REACT_APP_ENV !== 'development') {
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <h2 style={{ color: 'white' }}>Whoops, an error occurred.</h2>
          <StyledButton
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              const url = location.origin;
              // eslint-disable-next-line no-restricted-globals
              location.replace(url);
            }}
          >
            Click to reload the page
          </StyledButton>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

const StyledButton = styled(BaseButton)`
  padding: 20px;
  &:hover {
    background-color: ${theme.colors.orange};
    color: ${theme.colors.white};
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  background-image: url(${Background});
  background-color: ${theme.colors.orange};
`;
