import { createAction } from 'typesafe-actions';

import { MatchmakingElement } from '../../services/db';

import * as ActionTypes from './actionTypes';

export interface NotificationPayload {
  title: string;
  body: string;
  data: { [key: string]: string };
}

export const receiveNotification = createAction(
  ActionTypes.NOTIFICATION_RECEIVED,
  action => (data: NotificationPayload) => action(data),
);

export const removeNotification = createAction(ActionTypes.NOTIFICATION_REMOVED);

export const subscribeToNotifications = createAction(ActionTypes.NOTIFICATION_SUBSCRIBE);
export const fetchMatchMaking = createAction(
  ActionTypes.MATCHMAKING_REQUESTED,
  action => (id: string) => action({ id }),
);

export const matchMakingReceived = createAction(
  ActionTypes.MATCHMAKING_RECEIVED,
  action => (mm: MatchmakingElement) => action(mm),
);
export const matchMakingError = createAction(
  ActionTypes.MATCHMAKING_RECEIVED_ERROR,
  action => (errorMessage: string) => action(errorMessage),
);
export const resetMatchMaking = createAction(ActionTypes.MATCHMAKING_RESET);

export const updateServiceWorker = createAction(ActionTypes.UPDATE_SERVICE_WORKER);
export const updateServiceWorkerSuccess = createAction(
  ActionTypes.UPDATE_SERVICE_WORKER_SUCCESS,
);
export const updateServiceWorkerError = createAction(
  ActionTypes.UPDATE_SERVICE_WORKER_ERROR,
  action => (error: string) => action({ error }),
);
export const alertNewVersionAvailable = createAction(
  ActionTypes.NEW_VERSION_AVAILABLE,
  action => (reg: ServiceWorkerRegistration) => action({ reg }),
);
