import React from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FlagFilled } from '@ant-design/icons';

import { LeftMenuItem } from '../../_atoms/LeftMenuItem';
import { Icons } from '../../Icons';
import { setUnAvailable, signOut, getIsBillingEnabled } from '../../../modules';
import { useTypedSelector, useMetrics } from '../../../hooks';

type Props = {};
export const LeftMenu = (_: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('LeftMenu');
  const isBillingEnabled = useTypedSelector(getIsBillingEnabled);
  const match = useRouteMatch<{ page: string }>('/admin/:page');
  const metrics = useMetrics();

  if (!match) return <Redirect to="/admin/overview" />;

  return (
    <Container>
      <NavigationWrapper>
        <LinkStyled to={'/admin/overview'}>
          <LeftMenuItem
            text={t('overview')}
            active={match.params.page === 'overview'}
            icon={<Icons.Dashboard />}
          />
        </LinkStyled>
        <LinkStyled to={'/admin/users'}>
          <LeftMenuItem
            text={t('users')}
            active={match.params.page === 'users'}
            icon={<Icons.Users />}
          />
        </LinkStyled>
        <LinkStyled to={'/admin/reports'}>
          <LeftMenuItem
            text={t('reports')}
            active={match.params.page === 'reports'}
            icon={<Icons.Reports />}
          />
        </LinkStyled>
        <LinkStyled to={'/admin/configuration'}>
          <LeftMenuItem
            text={t('configuration')}
            active={match.params.page === 'configuration'}
            icon={<Icons.Cogs />}
          />
        </LinkStyled>
        {isBillingEnabled && (
          <LinkStyled to={'/admin/billing'}>
            <LeftMenuItem
              text={t('billing')}
              active={match.params.page === 'billing'}
              icon={<Icons.Document />}
            />
          </LinkStyled>
        )}
        <LinkStyled to={'/admin/discussions'}>
          <LeftMenuItem
            active={match.params.page === 'discussions'}
            text="Discussions"
            icon={<Icons.Dashboard />}
          />
        </LinkStyled>
        <div
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            //@ts-ignore
            if (window.fcWidget) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              //@ts-ignore
              window.fcWidget.open();
              metrics.logEvent('Contact support');
            }
          }}
        >
          <LeftMenuItem
            active={false}
            text={t('Contact support')}
            icon={<FlagFilled />}
          />
        </div>
      </NavigationWrapper>
      <LogoutContainer>
        <ButtonContainer
          onClick={() => {
            dispatch(setUnAvailable());
            dispatch(signOut());
          }}
        >
          <LeftMenuItem text={t('logout')} active={false} icon={<Icons.Logout />} />
        </ButtonContainer>
      </LogoutContainer>
    </Container>
  );
};

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 10%;

  & > * {
    margin-top: 20px;
  }
`;

const LogoutContainer = styled.div`
  margin-bottom: 30px;
  padding-left: 5%;
`;

const LinkStyled = styled(Link)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 10%;

  & > * {
    margin-top: 20px;
  }
`;
