import React from 'react';
import styled from 'styled-components';

type Props = {
  src?: string;
  onClick?: () => void;
};

export const Image = (props: Props) => {
  const { src, onClick } = props;
  return <ImageWrapper onClick={onClick} src={src} />;
};

export default Image;

const ImageWrapper = styled.img`
  display: block;
  max-width: 50%;
  max-height: 100%;
  border-radius: 20px;
  object-fit: cover;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    cursor: pointer;
  }
`;
