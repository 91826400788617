import React from 'react';
import styled from 'styled-components';

import theme from '../../../theme';

interface Props {
  text: string;
  isCollapsed?: boolean;
  icon: React.ReactNode;
  active: boolean;
}

export const LeftMenuItem = (props: Props) => {
  const { text, icon, active } = props;
  return (
    <Container active={active}>
      <IconWrapper>{icon}</IconWrapper>
      <p>{text}</p>
    </Container>
  );
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

const Container = styled.div<{ active: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50px;
  color: ${({ active }) => (active ? '#6C6C6C' : theme.colors.white)};
  background: ${({ active }) => (active ? theme.colors.white : 'transparent')};
  ${({ active }) => active && 'box-shadow: 5px 5px 10px #ae6a31, -5px -5px 10px #ffc45b'};
  padding: 10px 25px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  & p {
    text-transform: capitalize;
    margin-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;

    box-shadow: 5px 5px 10px #ae6a31, -5px -5px 10px #ffc45b;
  }

  &:active {
    cursor: pointer;
    box-shadow: 2px 2px 10px #ae6a31, -2px -2px 10px #ffc45b;
  }
`;
