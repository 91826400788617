import React from 'react';

export default () => (
  <svg
    width="25"
    height="32"
    viewBox="0 0 25 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.375 0.247135L1.65278 4.70272C0.652778 5.16117 0 6.19269 0 7.3245V14.058C0 22.0093 5.33333 29.4448 12.5 31.25C19.6667 29.4448 25 22.0093 25 14.058V7.3245C25 6.19269 24.3472 5.16117 23.3472 4.70272L13.625 0.247135C12.9167 -0.0823782 12.0833 -0.0823782 11.375 0.247135ZM12.5 15.4764H22.2222C21.4861 21.3789 17.6667 26.6368 12.5 28.2844V15.4907H2.77778V7.3245L12.5 2.86891V15.4764Z"
      fill="currentColor"
    />
  </svg>
);
