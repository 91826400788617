import { AppState } from '../rootReducer';
import { AdditionalInfo } from '../../services/db';

import {
  SELECT_TOPIC,
  SELECT_SUBTOPIC,
  ADD_IMAGE,
  INIT,
  NEXT_STEP,
  PREVIOUS_STEP,
  ADD_TEXT_MESSAGE,
  START_DISCUSSION,
  START_DISCUSSION_SUCCESS,
  START_DISCUSSION_FAILED,
} from './actionTypes';

import { RootAction } from '..';

export type CreateDiscussionState = {
  step: number;
  topicId?: string;
  subTopicId?: string;
  imageId?: string;
  textMessage?: string;
  isCreatingDiscussion?: boolean;
  error?: string;
  createdChatRoomId?: string;
};

const initialState: CreateDiscussionState = {
  step: 0,
};

export const createDiscussionReducer = (
  state = initialState,
  action: RootAction,
): CreateDiscussionState => {
  switch (action.type) {
    case INIT:
      return initialState;
    case NEXT_STEP:
      return { ...state, step: state.step + 1 };
    case PREVIOUS_STEP:
      return { ...state, step: state.step - 1 };
    case SELECT_TOPIC:
      return { ...state, topicId: action.payload.topicId };
    case SELECT_SUBTOPIC:
      return { ...state, subTopicId: action.payload.subTopicId };
    case ADD_IMAGE:
      return { ...state, imageId: action.payload.imageId };
    case ADD_TEXT_MESSAGE:
      return { ...state, textMessage: action.payload.textMessage };
    case START_DISCUSSION:
      return { ...state, isCreatingDiscussion: true };
    case START_DISCUSSION_SUCCESS:
      return {
        ...state,
        isCreatingDiscussion: false,
        createdChatRoomId: action.payload.chatRoomId,
      };
    case START_DISCUSSION_FAILED:
      return {
        ...state,
        isCreatingDiscussion: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

// selectors
export const getCreateDiscussion = (state: AppState) => state.createDiscussion;
export const getCanCreateDiscussion = (state: AppState) =>
  !!(
    state.createDiscussion.textMessage &&
    state.createDiscussion.textMessage.length > 0 &&
    state.createDiscussion.textMessage.trim().length > 0
  );

export const toChatRoomAdditionalInfo = (state: AppState): AdditionalInfo => {
  const data = getCreateDiscussion(state);
  return {
    product: 'sevenMinutes',
    questionType: 'other',
    topic: [data.topicId!, data.subTopicId!].filter(s => s !== undefined),
  };
};
