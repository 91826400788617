import React from 'react';
import styled from 'styled-components';

import { UIDiscussion } from '../../../modules';
type Props<T> = {
  items: T[];
  active: boolean;
  selected?: UIDiscussion;
  onChatRoomSelected: (chatRoom: T) => void;
  renderItem: (props: T & { selected: boolean }) => React.ReactNode;
  emptyState: React.ReactNode;
};

export function ChatRoomList<T extends UIDiscussion>(props: Props<T>) {
  const { active, items, selected, onChatRoomSelected, renderItem, emptyState } = props;

  if (items.length === 0) return <>{emptyState}</>;
  return (
    <>
      {active && (
        <List style={{ height: '100%' }}>
          {items.map(item => (
            <ListItem key={item.id} onClick={() => onChatRoomSelected(item)}>
              {renderItem({ ...item, selected: item.id === selected?.id })}
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
}

const List = styled.ul`
  padding: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  margin: 0;
  list-style-type: none;
`;

const ListItem = styled.li`
  width: 100%;
  margin: 10px 0;
  padding: 10px 20px;
`;
