import React from 'react';

const Feide = () => {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 38 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M32.2302 19.365H27.9902V29.545H32.2302V19.365Z" fill="#1F4698" />
      <path
        d="M18.23 22.335H13.99V33.785H4.24V19.365H0V34.635H0.02V38.025H32.23V33.785H18.23V22.335Z"
        fill="#1F4698"
      />
      <path
        d="M16.1103 18.0949C17.5131 18.0949 18.6503 16.9577 18.6503 15.5549C18.6503 14.1521 17.5131 13.0149 16.1103 13.0149C14.7075 13.0149 13.5703 14.1521 13.5703 15.5549C13.5703 16.9577 14.7075 18.0949 16.1103 18.0949Z"
        fill="#1F4698"
      />
      <path
        d="M16.11 3.265C17.6696 3.26237 19.2145 3.56713 20.6563 4.16185C22.0981 4.75657 23.4085 5.6296 24.5128 6.73103C25.617 7.83246 26.4933 9.14071 27.0917 10.581C27.69 12.0213 27.9987 13.5654 28 15.125H32.25C32.25 10.8484 30.5511 6.74693 27.5271 3.7229C24.5031 0.698879 20.4016 -1 16.125 -1C11.8484 -1 7.74693 0.698879 4.7229 3.7229C1.69888 6.74693 0 10.8484 0 15.125H4.25C4.25265 11.9803 5.50303 8.96525 7.72664 6.74164C9.95025 4.51803 12.9653 3.26765 16.11 3.265V3.265Z"
        fill="#1F4698"
      />
    </svg>
  );
};

export default Feide;
