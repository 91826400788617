import React from 'react';
import styled from 'styled-components';
import { Progress, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../styled';
import theme from '../../../theme';
import { useIsDesktop } from '../../../hooks';
import ConditionalWrapper from '../../_atoms/ConditionalWrapper';

type Props = {
  onDecline: () => void;
  onAccept: () => void;
  topic: string;
  subtopic: string;
  showCountdown: boolean;
  textMessage?: string;
  image?: string;
  isLoading: boolean;
};

export default (props: Props) => {
  const {
    onDecline,
    onAccept,
    topic,
    subtopic,
    showCountdown,
    textMessage,
    image,
    isLoading,
  } = props;
  const { t } = useTranslation('DiscussionRequest');
  const isDesktop = useIsDesktop();

  return (
    <Container isDesktop={isDesktop}>
      <ConditionalWrapper
        condition={isLoading}
        truthyWrapper={(children: React.ReactNode) => (
          <Spin tip={t('claiming discussion...')}>{children}</Spin>
        )}
      >
        <>
          <Header>
            <GhostButton onClick={onDecline}>{t('Decline')}</GhostButton>
          </Header>
          <Body>
            <TopicInfo>
              <div>
                <TopicHeader>{t('Category')}</TopicHeader>
                <TopicName>{topic}</TopicName>
              </div>
              <div>
                <TopicHeader>{t('Sub category')}</TopicHeader>
                <TopicName>{subtopic}</TopicName>
              </div>
            </TopicInfo>
            {showCountdown && (
              <div>
                <Progress
                  type="line"
                  strokeWidth={20}
                  percent={50}
                  showInfo={false}
                  strokeColor="#9EBEB8"
                />
              </div>
            )}
            <MessageContainer>
              {textMessage && <p>{textMessage}</p>}
              {image && <StyledImg src={image} alt="Problem" />}
            </MessageContainer>
          </Body>
          <Footer>
            <StyledButton backgroundColor={theme.colors.orange} onClick={onAccept}>
              {t('Accept the request')}
            </StyledButton>
          </Footer>
        </>
      </ConditionalWrapper>
    </Container>
  );
};

const StyledImg = styled.img`
  padding: 20px;
  max-width: 100%;
  max-height: 450px;
  border-radius: 50px;
`;

const TopicHeader = styled.h5`
  font-size: 11px;
  color: #a7a7a7;
  text-transform: uppercase;
`;

const TopicName = styled.p`
  font-size: 16px;
  font-weight: 550;
`;

const GhostButton = styled.button`
  background-color: transparent;
  color: #a7a7a7;
  border: none;
  font-weight: 500;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
`;

const TopicInfo = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 20px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Header = styled.div`
  flex: 0;
  height: 60px;
  text-align: right;
`;
const Body = styled.div`
  flex: 1;
  min-height: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;
const Footer = styled.div`
  flex: 0;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
`;

const Container = styled.div<{ isDesktop: boolean }>`
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${({ isDesktop }) => (isDesktop ? '50px' : 0)};
`;
