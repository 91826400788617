import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getBlob, requestBlob } from '../modules';

import { useTypedSelector } from './';
export const useBlob = (chatRoomId?: string, imageId?: string) => {
  const dispatch = useDispatch();
  const blob = useTypedSelector(state => getBlob(imageId || '', state));
  useEffect(() => {
    if (!blob && imageId && chatRoomId) dispatch(requestBlob(chatRoomId, imageId));
  }, [chatRoomId, imageId, blob, dispatch]);

  return blob;
};
