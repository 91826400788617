import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import theme from '../../../theme';
import { Card } from '../../_atoms/Card';

type Props = {
  title: string;
  total: number;
  headerColor: string;
  items: {
    name: string;
    value: number;
  }[];
};

export const GroupedAggregateCard: React.FC<Props> = props => {
  const { title, total, items, headerColor } = props;
  return (
    <Card>
      <Header color={headerColor}>
        <Col span={20}>{title}</Col>
        <Col span={4}>{total}</Col>
      </Header>
      <Row>
        <Col span={24}>
          {items.map(item => (
            <ListItem key={item.name} name={item.name} value={item.value} />
          ))}
        </Col>
      </Row>
    </Card>
  );
};

type ListItemProps = {
  name: string;
  value: number;
};
const ListItem = (props: ListItemProps) => {
  return (
    <ListItemRow>
      <Col span={20}>{props.name}</Col>
      <Col span={4}>{props.value}</Col>
    </ListItemRow>
  );
};

const ListItemRow = styled(Row)`
  color: #58778d;
  padding: 15px 40px;
  border-bottom: 1px solid #eeeeee;
  &:last-of-type {
    border-bottom: none;
  }
`;

const Header = styled(Row)<{ color: string }>`
  background-color: ${props => props.color};
  padding: 15px 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${theme.colors.white};
  border-radius: 7px;
  text-transform: uppercase;
`;
