import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { AdminLayout } from '../components/_templates/AdminLayout';
import { AdminLeftMenuContent } from '../components/_organisms/AdminLeftMenuContent';
import theme from '../theme';
import AdminOverview from '../components/AdminOverview/AdminOverview';
import {
  fetchCompletedChatRooms,
  fetchInCompleteChatRooms,
  fetchUnClaimedChatRooms,
  fetchUserTypes,
  fetchUsers,
  subscribeMentorAccessRequests,
  fetchRatings,
  getIsBillingEnabled,
} from '../modules';
import { AdminUsers } from '../components/AdminUsers';
import AdminConfiguration from '../components/AdminConfiguration/AdminConfiguration';
import AdminReports from '../components/AdminReports';
import AdminBilling from '../components/AdminBilling';
import { useTypedSelector } from '../hooks';
import DesktopLayout from '../components/_templates/DesktopLayout';
import { OverviewController } from '../components/_templates/OverviewController';
import DesktopMain from '../components/_organisms/DesktopMain';

export const AdminPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompletedChatRooms());
    dispatch(fetchInCompleteChatRooms());
    dispatch(fetchUnClaimedChatRooms());
    dispatch(fetchUserTypes());
    dispatch(fetchUsers());
    dispatch(subscribeMentorAccessRequests());
    dispatch(fetchRatings());
  }, [dispatch]);

  const isBillingEnabled = useTypedSelector(getIsBillingEnabled);

  return (
    <AdminLayout
      renderSideContent={() => <AdminLeftMenuContent />}
      renderMainContent={() => (
        <Switch>
          <Route exact path={`/admin/overview`}>
            <ContentWrapper>
              <AdminOverview />
            </ContentWrapper>
          </Route>
          <Route exact path={`/admin/reports`}>
            <ContentWrapper>
              <AdminReports />
            </ContentWrapper>
          </Route>
          <Route exact path={`/admin/configuration`}>
            <ContentWrapper>
              <AdminConfiguration />
            </ContentWrapper>
          </Route>
          <Route exact path={`/admin/users`}>
            <ContentWrapper>
              <AdminUsers />
            </ContentWrapper>
          </Route>
          <Route exact path={`/admin/discussions`}>
            <DiscussionAdmin>
              <DesktopLayout
                standalone={false}
                renderSideContent={() => (
                  <OverviewController hideFloatingActionButton hideProfile />
                )}
                renderMainContent={() => <DesktopMain />}
              />
            </DiscussionAdmin>
          </Route>
          {isBillingEnabled && (
            <Route exact path={`/admin/billing`}>
              <ContentWrapper>
                <AdminBilling />
              </ContentWrapper>
            </Route>
          )}
        </Switch>
      )}
    />
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${theme.colors.background.light};
`;

const DiscussionAdmin = styled(ContentWrapper)`
  background-color: red;
  height: 100%;
`;
export default AdminPage;
