import React from 'react';

export default () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.21 4.71C9.82 4.32 9.19 4.32 8.8 4.71L5.21 8.3C4.82 8.69 4.82 9.32 5.21 9.71L8.8 13.3C9.19 13.69 9.82 13.69 10.21 13.3C10.6 12.91 10.6 12.28 10.21 11.89L8.33 10L17 10C17.55 10 18 9.55 18 9C18 8.45 17.55 8 17 8L8.33 8L10.21 6.12C10.6 5.73 10.59 5.09 10.21 4.71ZM2 18L16 18C17.11 18 18 17.1 18 16L18 13C18 12.45 17.55 12 17 12C16.45 12 16 12.45 16 13L16 15C16 15.55 15.55 16 15 16L3 16C2.45 16 2 15.55 2 15L2 3C2 2.45 2.45 2 3 2L15 2C15.55 2 16 2.45 16 3L16 5C16 5.55 16.45 6 17 6C17.55 6 18 5.55 18 5L18 2C18 0.9 17.1 -7.86805e-08 16 -1.74846e-07L2 -1.39876e-06C0.900001 -1.49493e-06 1.49493e-06 0.899998 1.39876e-06 2L1.74846e-07 16C7.86805e-08 17.1 0.9 18 2 18Z"
      fill="currentColor"
    />
  </svg>
);
