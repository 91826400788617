import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { Typography } from 'antd';
import styled from 'styled-components';

import { createApiKey, listApiKeys, getApiKeys, deleteApiKey } from '../modules';
import { useTypedSelector } from '../hooks';
import { IconWrapper } from '../styled';

import { Icons } from './Icons';
const { Text } = Typography;
const ApiKeys = () => {
  const dispatch = useDispatch();
  const { apiKeys, isLoading } = useTypedSelector(getApiKeys);
  useEffect(() => {
    dispatch(listApiKeys());
  }, [dispatch]);

  return (
    <div>
      {isLoading && <p>loading...</p>}
      <List>
        {apiKeys &&
          apiKeys.map(key => (
            <ListItem key={key.apiKey}>
              <Text code copyable={{ text: key.apiKey }}>
                {key.apiKey.substring(0, 10)}...
                {key.apiKey.substring(key.apiKey.length - 10, key.apiKey.length - 1)}
              </Text>
              <IconWrapper onClick={() => dispatch(deleteApiKey(key))}>
                <Icons.Trash />
              </IconWrapper>
            </ListItem>
          ))}
      </List>
      <Button style={{ borderRadius: 50 }} onClick={() => dispatch(createApiKey())}>
        Create api key
      </Button>
    </div>
  );
};

const List = styled.ul`
  list-style-type: none;
`;

const ListItem = styled.li`
  display: flex;
  div {
    margin-left: 10px;
  }
`;

export default ApiKeys;
