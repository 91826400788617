import { Route, Redirect, RouteProps } from 'react-router';
import React from 'react';

import { useTypedSelector } from '../hooks';
import { getIsTenantAdmin } from '../modules';

interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  redirect: string;
}

const TenantAdminRoute = ({
  component: Component,
  redirect,
  ...rest
}: PrivateRouteProps) => {
  const isTenantAdmin = useTypedSelector(getIsTenantAdmin);
  return (
    <Route
      {...rest}
      render={props =>
        isTenantAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default TenantAdminRoute;
