import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { CheckCircleOutlined } from '@ant-design/icons';

import FileSelector from '../../FileSelector';
import { StyledButton, BaseButton } from '../../../styled';
import theme from '../../../theme';

import { Title } from './Title';

type Props = {
  onImageSelected: (dataUrl: string) => void;
  onTextMessageCreated: (textMessage: string) => void;
  onStartDiscussion: () => void;
  canCreateDiscussion: boolean;
};

export const CreateInitialMessage = (props: Props) => {
  const {
    onImageSelected,
    onTextMessageCreated,
    onStartDiscussion,
    canCreateDiscussion,
  } = props;
  const [hasImage, setHasImage] = useState(false);

  const onFileSelected = (imgDataUrl: string) => {
    onImageSelected(imgDataUrl);
    setHasImage(true);
  };

  const { t } = useTranslation('CreateDiscussion');
  return (
    <Container>
      <GrowContainer>
        <Title>{t('helptext', { defaultValue: 'What do you need help with?' })}</Title>
        <TextArea
          rows={4}
          placeholder={t('Start writing')}
          onChange={e => onTextMessageCreated(e.target.value)}
        ></TextArea>
        <SmallTitle>
          {t(
            'You can upload an image of your problem to help us find a suitable mentor!',
          )}
        </SmallTitle>
        <FileSelector onFileSelected={onFileSelected}>
          <ButtonContainer>
            <SmallButton>
              {t('Upload an image')}
              {hasImage && <CheckCircleOutlined style={{ marginLeft: 5 }} />}
            </SmallButton>
          </ButtonContainer>
        </FileSelector>
      </GrowContainer>
      <BottomButton
        backgroundColor="#5B5F97"
        color="#ffffff"
        onClick={onStartDiscussion}
        disabled={!canCreateDiscussion}
      >
        {t('Start discussion now')}
      </BottomButton>
    </Container>
  );
};

const GrowContainer = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const BottomButton = styled(StyledButton)`
  align-self: flex-end;
`;

const SmallTitle = styled(Title)`
  font-size: 14px;
  margin: 10px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const SmallButton = styled(BaseButton)`
  background-color: #ffffff;
  color: #8c8c8c;
  font-size: 12px;
  padding: 10px 20px;
  margin-bottom: 20px;

  &:hover {
    background-color: ${theme.colors.orange};
  }
`;
