import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import theme from '../../../theme';
import { Button } from '../../_atoms/Button';
import Background from '../../../assets/images/background_grey.png';
import { useTypedSelector, useMetrics } from '../../../hooks';
import { getNickName, getIsMentor, getServiceName } from '../../../modules';

type Props = {
  onClick: () => void;
};
export const RightSideEmptyState = (props: Props) => {
  const { onClick } = props;
  const { t } = useTranslation('home');
  const savedNickName = useTypedSelector(getNickName);
  const serviceName = useTypedSelector(getServiceName);

  const isMentor = useTypedSelector(getIsMentor);
  const userText = 'Continue one of the discussion or start a new one';
  const mentorText = 'Continue one of the discussions';
  const metrics = useMetrics();

  const handleClick = () => {
    metrics.logEvent('discussion.new', { emptyState: true });
    onClick();
  };
  return (
    <Container>
      <CenteredDiv>
        <Title>
          {t('Welcome to {{serviceName}}, {{name}}!', {
            name: savedNickName || '',
            serviceName,
          })}
        </Title>
        <Paragraph>{t(isMentor ? mentorText : userText)}</Paragraph>
        {!isMentor && (
          <Button onClick={handleClick} active>
            {t('Snap your problem')}
          </Button>
        )}
      </CenteredDiv>
    </Container>
  );
};

const Paragraph = styled.p`
  font-size: 22px;
  text-align: center;
`;
const Title = styled.h1`
  font-size: 40px;
  color: ${theme.colors.white};
  font-weight: bold;
  text-align: center;
`;

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${Background});
  background-color: ${theme.colors.orange};
  color: ${theme.colors.white};
  & > div {
    max-width: 400px;
  }
`;
