import { combineEpics } from 'redux-observable';
import {
  filter,
  map,
  switchMap,
  catchError,
  mergeMap,
  takeUntil,
  mapTo,
  concatMap,
  debounceTime,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { combineLatest, of, empty, from } from 'rxjs';

import { SIGN_OUT } from '../User';
import { catchHandler } from '../utils';

import {
  reviewsUpdated,
  promptReview,
  sendRatingSuccess,
  searchTagsSuccess,
  fetchTemplateSuccess,
  fetchTemplateError,
} from './actions';
import {
  REVIEWS_SUBSCRIBED,
  REVIEWS_UPDATED,
  REVIEW_SEEN,
  RATING_SEND,
  SEND_GENERAL_USER_FEEDBACK,
  TAGS_SEARCH,
  FETCH_TEMPLATE,
} from './actionTypes';

import { RootEpic, getUserId } from '..';

export const reviewsSubscribed: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(REVIEWS_SUBSCRIBED)),
    switchMap(() => {
      const uid = getUserId(state$.value)!;
      const observables = [
        database.reviewQueue.pending.list(uid),
        database.reviewQueue.lastReviewed.get(uid),
      ];
      return combineLatest(observables).pipe(
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      );
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map((d: any) => reviewsUpdated(d[0], d[1])),
    catchError(catchHandler),
  );

export const reviewsUpdatedEpic: RootEpic = action$ =>
  action$.pipe(
    filter(isOfType(REVIEWS_UPDATED)),
    mergeMap(action => {
      const { pending } = action.payload;
      if (pending.length > 0) return of(promptReview(pending[0]));
      else return empty();
    }),
    catchError(catchHandler),
  );

export const reviewWasSeenEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(REVIEW_SEEN)),
    mergeMap(action => {
      const uid = getUserId(state$.value)!;
      return from(database.reviewQueue.pending.delete(action.payload.id, uid)).pipe(
        catchError(catchHandler),
      );
    }),
    mergeMap(() => empty()),
    catchError(catchHandler),
  );

export const ratingEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(RATING_SEND)),
    concatMap(({ payload }) => {
      return from(database.submitRating(payload)).pipe(
        mapTo(sendRatingSuccess()),
        catchError(catchHandler),
      );
    }),
    catchError(catchHandler),
  );

export const generalUserFeedbackEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(SEND_GENERAL_USER_FEEDBACK)),
    concatMap(({ payload: { data } }) => {
      return from(database.submitGeneralUserFeedback(data)).pipe(
        mapTo(sendRatingSuccess()),
        catchError(catchHandler),
      );
    }),
    catchError(catchHandler),
  );

export const mentorTagsSuggestionsEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(TAGS_SEARCH)),
    debounceTime(300),
    switchMap(({ payload: { value } }) => {
      return database.searchTags(value).pipe(
        map(result => searchTagsSuccess(result)),
        catchError(catchHandler),
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      );
    }),
    catchError(catchHandler),
  );
export const questionTemplatesEpic: RootEpic = (action$, state$, { db }) =>
  action$.pipe(
    filter(isOfType(FETCH_TEMPLATE)),
    switchMap(({ payload: { templateId } }) => {
      return from(db.fetchQuestionTemplateById(templateId)).pipe(
        map(fetchTemplateSuccess),
        catchError(e => of(fetchTemplateError(e.message))),
      );
    }),
    catchError(catchHandler),
  );

export const reviewsRootEpic = combineEpics(
  reviewsSubscribed,
  reviewsUpdatedEpic,
  reviewWasSeenEpic,
  ratingEpic,
  generalUserFeedbackEpic,
  mentorTagsSuggestionsEpic,
  questionTemplatesEpic,
);

// TODO when removing from the frontend, add a event handler that onDelete archives the queue for analytics purposes
