import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { StyledButton } from '../../styled';
const { TextArea } = Input;
const AnnouncementsInput = ({
  messages,
  onSave,
}: {
  messages?: string[];
  onSave: (messages: string[]) => void;
}) => {
  const [text, setText] = useState<string>('');

  useEffect(() => {
    if (messages) {
      setText(messages.join('\n'));
    }
  }, [messages, setText]);
  const { t } = useTranslation('Admin');

  const onSubmit = () => {
    const updatedMessages = text.split('\n');
    onSave(updatedMessages);
  };

  return (
    <>
      <TextAreaWrapper>
        <TextArea
          value={text}
          onChange={e => setText(e.target.value)}
          autoSize={{ minRows: 3, maxRows: 10 }}
        />
      </TextAreaWrapper>
      <StyledButton style={{ marginTop: 10, width: 100 }} onClick={onSubmit}>
        {t('Save')}
      </StyledButton>
    </>
  );
};

const TextAreaWrapper = styled.div`
  width: 100%;
  .ant-input {
    border-radius: 25px;
    padding: 12px 20px;
  }
`;

export default AnnouncementsInput;
