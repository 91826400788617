import React, { useRef } from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import loadImage from 'blueimp-load-image';

import { useMetrics } from '../hooks';

import { Icons } from './Icons';

export interface FileSelectorProps {
  onFileSelected: (imgDataUrl: string) => void;
  children?: React.ReactNode;
}
export const FileSelector = (props: FileSelectorProps) => {
  const {
    onFileSelected,
    children = (
      <IconButton>
        <Icons.Paperclip />
      </IconButton>
    ),
  } = props;

  const metrics = useMetrics();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      loadImage(
        files[0]!,
        img => {
          if (img instanceof HTMLCanvasElement) {
            onFileSelected(img.toDataURL());
          } else {
            Sentry.captureException(
              Error('Image captured was not returned in the canvas'),
            );
          }
        },
        { maxWidth: 700, orientation: true, meta: true }, // Options
      );
    }
  };

  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      <input
        data-testid="FileInput"
        ref={ref}
        type="file"
        name="myFile"
        multiple={false}
        accept="image/*"
        onChange={onChange}
        style={{ display: 'none' }}
      />
      <ChildWrapper
        data-testid="FileInputClickButton"
        onClick={() => {
          metrics.logEvent('selectFile');

          if (ref.current) {
            ref.current!.click();
          }
        }}
      >
        {children}
      </ChildWrapper>
    </>
  );
};

const IconButton = styled.button`
  padding: 8px;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
`;

const ChildWrapper = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FileSelector;
