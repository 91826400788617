import React, { memo } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../hooks';
import { getBlob, requestBlob } from '../../../modules';

interface ImageMessageProps {
  imageId: string;
  chatRoomId: string;
  onImageClicked: (imageUrl: string) => void;
}
const ImageLoader = ({ imageId, chatRoomId, onImageClicked }: ImageMessageProps) => {
  const dispatch = useDispatch();
  const blob = useTypedSelector(state => getBlob(imageId, state));
  React.useEffect(() => {
    dispatch(requestBlob(chatRoomId, imageId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickImage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (blob) onImageClicked(blob.objectUrl!);
  };

  return (
    <ImageContainer onClick={onClickImage}>
      <img
        alt="Message"
        src={blob && blob.objectUrl}
        style={!blob ? { visibility: 'hidden' } : {}}
      />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 25px;
  }
`;

export default memo(ImageLoader);
