import React from 'react';
import { Layout } from 'antd';

import { DesktopContainer } from '../DesktopContainer';
const { Sider, Content } = Layout;

type Props = {
  widthPercent?: number;
  standalone?: boolean;
  renderSideContent: () => React.ReactNode;
  renderMainContent: () => React.ReactNode;
};
export default (props: Props) => {
  const { renderSideContent, renderMainContent, widthPercent = 30, standalone } = props;
  return (
    <DesktopContainer standalone={standalone}>
      <Layout style={{ height: '100%' }}>
        <Sider
          width={`${widthPercent}%`}
          style={{
            backgroundColor: '#ffffff',
            height: '100%',
          }}
        >
          {renderSideContent()}
        </Sider>
        <Layout>
          <Content>{renderMainContent()}</Content>
        </Layout>
      </Layout>
    </DesktopContainer>
  );
};
