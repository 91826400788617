import React, { useEffect, useState, useCallback } from 'react';
import { notification, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useTypedSelector, useMetrics } from '../hooks';
import {
  NotificationState,
  getNotifications,
  getIsMentor,
  subscribeToNotifications,
  removeNotification,
} from '../modules';
import ClaimDiscussion from '../components/_organisms/ClaimDiscussion';

export const NotificationProvider = ({ children }: { children: React.ReactChild }) => {
  const { t } = useTranslation('home');
  const dispatch = useDispatch();
  const metrics = useMetrics();
  const isMentor = useTypedSelector(getIsMentor);
  const notificationState = useTypedSelector(getNotifications);
  const [openClaimDiscussion, setOpenClaimDiscussion] = useState<boolean>(false);
  const [matchMakingId, setMatcMakingId] = useState<string>();

  const close = useCallback(() => {
    metrics.logEvent('claimchat.notification', { close: true });
    dispatch(removeNotification());
  }, [metrics, dispatch]);

  const handleDone = () => {
    setOpenClaimDiscussion(false);
    setMatcMakingId(undefined);
  };

  useEffect(() => {
    if (isMentor) {
      dispatch(subscribeToNotifications());
    }
  }, [dispatch, isMentor]);

  useEffect(() => {
    if (notificationState.show) {
      metrics.logEvent('claimchat.notification', { prompt: true });
      const openNotificationWithIcon = (state: NotificationState) => {
        const key = `notification-${state.data.id}`;
        const confirmButton = (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              dispatch(removeNotification());
              setOpenClaimDiscussion(true);
              setMatcMakingId(state.data.id);
              metrics.logEvent('claimchat.notification', { cancelled: true });
              notification.close(key);
            }}
          >
            {t('Confirm')}
          </Button>
        );
        notification.info({
          message: state.title,
          description: state.body,
          duration: 30,
          btn: confirmButton,
          key,
          onClose: close,
        });
      };
      openNotificationWithIcon(notificationState);
    }
  }, [
    close,
    dispatch,
    metrics,
    notificationState.show,
    notificationState.data,
    t,
    notificationState,
  ]);

  return (
    <>
      {children}

      <ClaimDiscussion
        visible={openClaimDiscussion}
        matchMakingId={matchMakingId}
        onDone={handleDone}
      />
    </>
  );
};
export default NotificationProvider;
