export const SET_USER = 'USER/SET_USER';
export const LOAD_AUTH = 'USER/LOAD_AUTH';
export const AUTH_LOADED_NO_USER = 'USER/AUTH_LOADED_NO_USER';
export const SIGN_IN = 'USER/SIGN_IN';
export const SIGN_IN_SUCCESS = 'USER/SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'USER/SIGN_IN_ERROR';
export const SIGN_OUT = 'USER/SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'USER/SIGN_OUT_SUCCESS';
export const REGISTER = 'USER/REGISTER';
export const REGISTER_ERROR = 'USER/REGISTER_ERROR';
export const REGISTER_SUCCESS = 'USER/REGISTER_SUCCESS';
export const SIGN_IN_GOOGLE = 'USER/SIGN_IN_GOOGLE';
export const NO_USER_FOR_REDIRECT = 'USER/NO_USER_FOR_REDIRECT';
export const SIGN_IN_GOOGLE_REDIRECTED_RETURN = 'USER/SIGN_IN_GOOGLE_REDIRECTED_RETURN';
export const IS_DONE_LOADING = 'USER/IS_DONE_LOADING';
export const RESET_PASSWORD_EMAIL = 'USER/RESET_PASSWORD_EMAIL';
export const RESET_PASSWORD_EMAIL_SUCCESS = 'USER/RESET_PASSWORD_EMAIL_SUCCESS';
export const DELETE_USER = 'USER/DELETE_USER';
export const DELETE_USER_SUCCESS = 'USER/DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'USER/DELETE_USER_FAILURE';

export const GET_OAUTH_TOKEN = 'USER/GET_OAUTH_TOKEN';
export const GET_OAUTH_TOKEN_SUCCESS = 'USER/GET_OAUTH_TOKEN_SUCCESS';
export const GET_OAUTH_TOKEN_FAILURE = 'USER/GET_OAUTH_TOKEN_FAILURE';
export const RESET = 'RESET_APP';
export const INIT_FRESHCHAT = 'INIT_FRESHCHAT';

// export const AAD_LOGIN_SUCCESS = 'AAD_LOGIN_SUCCESS';
