type Props = {
  condition: boolean;
  truthyWrapper: (children: JSX.Element) => JSX.Element;
  falsyWrapper?: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
};

export default (props: Props) => {
  const { condition, truthyWrapper, falsyWrapper, children } = props;
  return condition
    ? truthyWrapper(children)
    : falsyWrapper
    ? falsyWrapper(children)
    : children;
};
