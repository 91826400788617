import styled from 'styled-components';

import theme from '../theme';

export const BaseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 1000px;

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  cursor: pointer;
  touch-action: manipulation;

  &:active {
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    color: ${theme.colors.white};
  }
`;

export const StyledButton = styled(BaseButton)<{
  backgroundColor?: string;
  disabled?: boolean;
  color?: string;
  fontSize?: string;
  size?: 'small' | 'default';
}>`
  color: ${({ color }) => (color ? color : theme.colors.white)};
  width: inherit;
  background-color: ${({ backgroundColor, disabled }) => {
    if (disabled) return 'grey';
    if (backgroundColor) return backgroundColor;
    return '#9ea2be';
  }};
  padding: 10px 40px;
  font-weight: 600;
  height: 48px;

  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
`;
