import { notEmpty } from '../../utils/typeUtils';
import { TrendFilter } from '../../components/AdminReports/TopicFilter';

import { ChatRoomMetrics, PopularTag } from './types';
import { percentile, calculatePercentile } from './statistics';

const withGlobalPercentiles = <T extends LinearData>(data: T[]) => {
  const p95 = percentile(data, 95);
  const p99 = percentile(data, 99);
  return data.map(d => ({ ...d, globalP95: p95, globalP99: p99 }));
};

export type LinearData = {
  x: string;
  y: number;
  timestamp: number;
};
export const groupByDay = (
  data: ChatRoomMetrics[],
  aggregate: (values: ChatRoomMetrics[]) => number,
) => {
  const groups = groupDay(data);

  return Object.keys(groups).map(key => {
    const values = groups[key];
    return {
      x: key,
      y: aggregate(values),
      timestamp: new Date(key).getTime(),
    };
  });
};

type GroupedData<T> = {
  x: string;
  timestamp: number;
  values: T[];
};

export const groupByDayWithTimestamp = (
  data: ChatRoomMetrics[],
): GroupedData<ChatRoomMetrics>[] => {
  const groups = groupDay(data);

  return Object.keys(groups).map(key => {
    return {
      x: key,
      values: groups[key],
      timestamp: new Date(key).getTime(),
    };
  });
};

const groupDay = (data: ChatRoomMetrics[]) => {
  const toDay = (date: Date) =>
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  const groups = data.reduce<{ [id: string]: ChatRoomMetrics[] }>((acc, cur) => {
    const key = toDay(cur.completedAt!);
    if (!cur.completedAt) return acc;
    return {
      ...acc,
      [key]: acc[key] ? [...acc[key], cur] : [cur],
    };
  }, {});
  return groups;
};

export const countTopics = (data: ChatRoomMetrics[]): { [topic: string]: number } => {
  const groups = data.reduce<{ [topic: string]: number }>((acc, cur) => {
    const key = cur.topics[0];
    return {
      ...acc,
      [key]: acc[key] ? acc[key] + 1 : 1,
    };
  }, {});
  return groups;
};

export const messagesPerDay = (data: ChatRoomMetrics[]) =>
  groupByDay(data, values => values.reduce((acc, cur) => acc + cur.totalMessages, 0));

export const discussionsPerDay = (data: ChatRoomMetrics[]) =>
  groupByDay(data, values => values.length);

export const imagesPerDay = (data: ChatRoomMetrics[]) =>
  groupByDay(data, values => values.reduce((acc, cur) => acc + cur.totalImages, 0));

export const completedByMentorPercentage = (data: ChatRoomMetrics[]) => {
  const byMentor = data.filter(d => d.completedBy === d.mentorId).length;
  const byStudent = data.length - byMentor;

  return [
    { name: 'By mentor', value: byMentor },
    { name: 'By student', value: byStudent },
  ];
};

const numberAvg = (values: number[]) => {
  const total = values.length;
  const sum = values.reduce((acc, cur) => acc + (cur || 0), 0);
  return total ? sum / total : 0;
};

const toMinutes = (millis: number) => millis / (1000 * 60);

const withLocalPercentiles = <T>(
  data: GroupedData<T>[],
  mapper: (d: T) => number | null | undefined,
) => {
  return data.map(d => {
    const validData = d.values.map(mapper).filter(notEmpty);
    const avg = toMinutes(numberAvg(validData));
    return {
      ...d,
      avg,
      y: avg,
      p95: toMinutes(calculatePercentile(validData, 95)),
      p99: toMinutes(calculatePercentile(validData, 99)),
    };
  });
};

export const timeToClaimedPerDay = (
  data: ChatRoomMetrics[],
  filterOptions: FilterOptions,
) =>
  withGlobalPercentiles(
    withLocalPercentiles(
      groupByDayWithTimestamp(filterClaimed(data, filterOptions)).sort(
        (a, b) => a.timestamp - b.timestamp,
      ),
      d => d.timeToClaimed,
    ),
  );

export const timeToCompletedPerDay = (
  data: ChatRoomMetrics[],
  filterOptions: FilterOptions,
) =>
  withGlobalPercentiles(
    withLocalPercentiles(
      groupByDayWithTimestamp(filterCompleted(data, filterOptions)).sort(
        (a, b) => a.timestamp - b.timestamp,
      ),
      d => d.duration,
    ),
  );

export type StackedAreaData = {
  trends: TrendFilter[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
};

export const dicussionsPerTopicPerDay = (
  data: ChatRoomMetrics[],
  filterOptions: FilterOptions,
) => {
  const { start, end } = filterOptions;
  const filterOutCancelled = data
    .filter(d => d.mentorId !== 'not-identified')
    .filter(d => d.completedAt && isBetween(d.completedAt, start, end));
  const groups = groupDay(filterOutCancelled);
  const cleanedData = Object.keys(groups).map(key => ({
    name: key,
    timestamp: new Date(key).getTime(),
    ...countTopics(groups[key]),
  }));

  return cleanedData.sort((a, b) => a.timestamp - b.timestamp);
};

export type FilterOptions = {
  start: Date;
  end: Date;
  topicFilter: string[];
};

const isBetween = (value: Date, start: Date, end: Date) => {
  return value.getTime() >= start.getTime() && value.getTime() <= end.getTime();
};

export const countTags = (
  data: PopularTag[],
  options: FilterOptions,
): { name: string; value: number }[] => {
  const { topicFilter, start, end } = options;
  const groups = data
    .filter(d => topicFilter.includes(d.topics[0]) && isBetween(d.timestamp, start, end))
    .reduce<{ [tag: string]: number }>((acc, cur) => {
      const key = cur.tag;
      return {
        ...acc,
        [key]: acc[key] ? acc[key] + 1 : 1,
      };
    }, {});
  return Object.keys(groups)
    .map(tag => ({ name: tag, value: groups[tag] }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 10);
};

const filterCompleted = (data: ChatRoomMetrics[], filterOptions: FilterOptions) => {
  const { topicFilter, start, end } = filterOptions;
  return data.filter(
    d =>
      topicFilter.includes(d.topics[0]) &&
      d.completedAt &&
      isBetween(d.completedAt, start, end),
  );
};
const filterClaimed = (data: ChatRoomMetrics[], filterOptions: FilterOptions) => {
  const { topicFilter, start, end } = filterOptions;
  return data.filter(
    d =>
      topicFilter.includes(d.topics[0]) &&
      d.claimedAt &&
      isBetween(d.claimedAt, start, end),
  );
};
