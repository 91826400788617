import { Metrics } from '../../services/metrics';

export const createLocationLoggerMiddleware = () => {
  const logger = Metrics.getLogger();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return () => (next: any) => (action: any) => {
    if (action.type === '@@router/LOCATION_CHANGE') {
      const newPath = action.payload.location.pathname;
      logger.logEvent('location_change', {
        newPath,
      });
    }
    const result = next(action);
    return result;
  };
};
