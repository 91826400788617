import 'firebase/storage';
import firebase from 'firebase/app';
const IMAGES_PATH = 'images';
const IMAGES_CONFIG_PATH = 'config';
const CUSTOM_LOGO_FILE_NAME = 'logo.png';
export default class Files {
  rootRef: firebase.storage.Reference;
  constructor(tenantId: string) {
    this.rootRef = firebase
      .storage()
      .ref('tenants')
      .child(tenantId);
  }

  public uploadDataUrlImage = async (
    imageName: string,
    chatRoomId: string,
    dataurl: string,
  ) => {
    const snapshot = await this.rootRef
      .child(IMAGES_PATH)
      .child(chatRoomId)
      .child(imageName)
      .putString(dataurl, 'data_url');
    return snapshot;
  };

  public getImageDownloadUrl = async (chatRoomId: string, imageId: string) => {
    const imageRef = this.rootRef
      .child(IMAGES_PATH)
      .child(chatRoomId)
      .child(imageId);
    return imageRef.getDownloadURL();
  };

  admin = {
    getCustomLogoUrl: async () => {
      const imageRef = this.rootRef
        .child(IMAGES_CONFIG_PATH)
        .child(CUSTOM_LOGO_FILE_NAME);
      const url = await imageRef.getDownloadURL();
      return url;
    },
    uploadCustomLogo: async (dataurl: string) => {
      const snapshot = await this.rootRef
        .child(IMAGES_CONFIG_PATH)
        .child(CUSTOM_LOGO_FILE_NAME)
        .putString(dataurl, 'data_url');
      return snapshot;
    },
  };
}
