import { combineEpics } from 'redux-observable';
import { of, empty, timer } from 'rxjs';
import {
  filter,
  mergeMap,
  map,
  catchError,
  tap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { AuthenticationActions } from 'react-aad-msal';

import { Metrics } from '../../services/metrics';
import { SIGN_OUT } from '../User';
import { getIsMentor } from '../Auth';
import { SET_AVAILABLE } from '../UserType';

import {
  receiveQueue,
  receiveQueueError,
  claimQueueItemSuccess,
  claimQueueItemError,
  claimDiscussionSuccess,
  claimDiscussionError,
  requestQueue,
} from './actions';
import {
  QUEUE_REQUESTED,
  QUEUE_ITEM_CLAIMED,
  DISCUSSION_CLAIMED,
  QUEUE_RECEIVED_ERROR,
} from './actionTypes';

import { RootEpic } from '..';

export const queueEpic: RootEpic = (action$, _, { backendService }) =>
  action$.pipe(
    filter(isOfType([QUEUE_REQUESTED, SET_AVAILABLE])),
    switchMap(() =>
      timer(0, 60000).pipe(
        takeUntil(action$.pipe(filter(isOfType([SIGN_OUT, QUEUE_RECEIVED_ERROR])))),
      ),
    ),
    mergeMap(() =>
      backendService.fetchUnclaimedQueue().pipe(
        map(receiveQueue),
        catchError(() => {
          return of(receiveQueueError());
        }),
      ),
    ),
    catchError(() => {
      return of(receiveQueueError());
    }),
  );

export const onUserSetSubscribeToQueue: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isOfType(AuthenticationActions.LoginSuccess)),
    mergeMap(() => {
      if (!getIsMentor(state$.value)) return empty();
      return of(requestQueue());
    }),
  );

export const claimDiscussionEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(DISCUSSION_CLAIMED)),
    mergeMap(action =>
      backendService
        .claimSession({
          id: action.payload.chatRoomId,
        })
        .pipe(
          tap(({ chatRoomId }) =>
            Metrics.getLogger().logEvent('claimDiscussionSuccess', {
              chatRoomId,
            }),
          ),
          map(({ chatRoomId }) => claimDiscussionSuccess(chatRoomId)),
          catchError(e => {
            Metrics.getLogger().logEvent('claimDiscussionError');
            return of(claimDiscussionError(e.message));
          }),
        ),
    ),
  );

export const claimAbandonedSessionEpic: RootEpic = (
  action$,
  state$,
  { backendService },
) =>
  action$.pipe(
    filter(isOfType(QUEUE_ITEM_CLAIMED)),
    mergeMap(({ payload: { sessionId } }) =>
      backendService
        .claimAbandonedSession({
          sessionId,
        })
        .pipe(
          map(({ chatRoomId }) => claimQueueItemSuccess(chatRoomId)),
          catchError(e => {
            Metrics.getLogger().logEvent('claimAbandonedDiscussionError', {
              message: e.message,
            });
            return of(
              claimQueueItemError(
                'Could not claim this discussion. It was most likely already claimed.',
              ),
            );
          }),
        ),
    ),
  );
export const mentorRootEpic = combineEpics(
  queueEpic,
  claimAbandonedSessionEpic,
  claimDiscussionEpic,
  onUserSetSubscribeToQueue,
);
