import React from 'react';

export default () => (
  <svg
    width="29"
    height="24"
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.35135 9.28C1.05027 9.28 0 10.3163 0 11.6C0 12.8837 1.05027 13.92 2.35135 13.92C3.65243 13.92 4.7027 12.8837 4.7027 11.6C4.7027 10.3163 3.65243 9.28 2.35135 9.28ZM2.35135 0C1.05027 0 0 1.03627 0 2.32C0 3.60373 1.05027 4.64 2.35135 4.64C3.65243 4.64 4.7027 3.60373 4.7027 2.32C4.7027 1.03627 3.65243 0 2.35135 0ZM2.35135 18.56C1.05027 18.56 0 19.6117 0 20.88C0 22.1483 1.06595 23.2 2.35135 23.2C3.63676 23.2 4.7027 22.1483 4.7027 20.88C4.7027 19.6117 3.65243 18.56 2.35135 18.56ZM8.62162 22.4267H27.4324C28.2946 22.4267 29 21.7307 29 20.88C29 20.0293 28.2946 19.3333 27.4324 19.3333H8.62162C7.75946 19.3333 7.05405 20.0293 7.05405 20.88C7.05405 21.7307 7.75946 22.4267 8.62162 22.4267ZM8.62162 13.1467H27.4324C28.2946 13.1467 29 12.4507 29 11.6C29 10.7493 28.2946 10.0533 27.4324 10.0533H8.62162C7.75946 10.0533 7.05405 10.7493 7.05405 11.6C7.05405 12.4507 7.75946 13.1467 8.62162 13.1467ZM7.05405 2.32C7.05405 3.17067 7.75946 3.86667 8.62162 3.86667H27.4324C28.2946 3.86667 29 3.17067 29 2.32C29 1.46933 28.2946 0.773333 27.4324 0.773333H8.62162C7.75946 0.773333 7.05405 1.46933 7.05405 2.32Z"
      fill="currentColor"
    />
  </svg>
);
