import React, { useMemo } from 'react';
import { BarChart, ResponsiveContainer, XAxis, YAxis, Bar } from 'recharts';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';

import { PopularTag } from '../../services/database/types';
import { countTags } from '../../services/database/utils';
import theme from '../../theme';

import { TrendFilter } from './TopicFilter';

const aggregates = {
  countTags,
};

type AggregateType = typeof aggregates;

export type ReportType = keyof AggregateType;

export default ({
  dateFilter,
  trendFilter,
  data,
  type,
}: {
  data: PopularTag[];
  type: ReportType;
  trendFilter: TrendFilter[];
  dateFilter: [Moment, Moment];
}) => {
  const { t } = useTranslation('reports');
  const rawData = useMemo(() => {
    const options = {
      start: dateFilter[0].toDate(),
      end: dateFilter[1].toDate(),
      topicFilter: trendFilter.map(t => t.value),
    };
    return aggregates[type](data, options);
  }, [data, type, trendFilter, dateFilter]);

  if (rawData.length === 0)
    return (
      <Empty>
        <h4>{t('No data yet')}</h4>
      </Empty>
    );

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          layout="vertical"
          data={rawData}
          margin={{
            top: 5,
            right: 30,
            left: 150,
            bottom: 5,
          }}
        >
          <XAxis dataKey="value" type="number" />
          <YAxis dataKey="name" type="category" />
          <Bar dataKey="value" fill={theme.colors.chart.p95} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

const Empty = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
