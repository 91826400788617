import { combineEpics } from 'redux-observable';
import { filter, mergeMap, catchError, tap, delay } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { from, of } from 'rxjs';
import * as Sentry from '@sentry/browser';

import { addChatRoomToUser, selectChatRoom } from '../ChatRooms';
import { Metrics } from '../../services/metrics';
import { canCreateDiscussionReset } from '../Payments';
import { getUserId } from '../Auth';

import { START_DISCUSSION } from './actionTypes';
import { toChatRoomAdditionalInfo, getCreateDiscussion } from './reducer';
import { startDiscussionFailed, startDiscussionSuccess } from './actions';

import { RootEpic } from '..';

export const onDiscussionReadyCreateEpic: RootEpic = (action$, state$, { discussions }) =>
  action$.pipe(
    filter(isOfType(START_DISCUSSION)),
    mergeMap(() => {
      const uid = getUserId(state$.value)!;
      const additionalInfo = toChatRoomAdditionalInfo(state$.value);
      const discussionData = getCreateDiscussion(state$.value);
      return from(discussions.create(discussionData, additionalInfo, uid)).pipe(
        tap(({ id }) => {
          Metrics.getLogger().logEvent('chatRoomCreatedSuccess', {
            data: id,
          });
        }),
        delay(2000),
        mergeMap(({ id }) =>
          of(
            startDiscussionSuccess(id),
            addChatRoomToUser(id),
            selectChatRoom(id),
            canCreateDiscussionReset(),
          ),
        ),
        catchError(e => {
          Sentry.captureException(e);
          return of(startDiscussionFailed(e.message));
        }),
      );
    }),
  );
export const createDiscussionEpic = combineEpics(onDiscussionReadyCreateEpic);
