import React from 'react';

export default () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20.8874V25.2779C0 25.6823 0.317734 26 0.722122 26H5.11262C5.30037 26 5.48813 25.9278 5.61811 25.7834L21.3893 10.0267L15.9733 4.61075L0.216637 20.3674C0.0722123 20.5119 0 20.6852 0 20.8874ZM25.5776 5.83836C26.1408 5.2751 26.1408 4.36523 25.5776 3.80197L22.198 0.422441C21.6348 -0.140814 20.7249 -0.140814 20.1616 0.422441L17.5187 3.06541L22.9346 8.48132L25.5776 5.83836Z"
      fill="currentColor"
    />
  </svg>
);
