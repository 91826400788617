import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

export default () => {
  return (
    <Loader>
      <Spin />
    </Loader>
  );
};

const Loader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;
