import { Auth } from '../../providers/AuthSubscriber';
import { OAuthProvider } from '../../services/backendService';
import { AppState } from '../rootReducer';

import {
  SET_USER,
  LOAD_AUTH,
  AUTH_LOADED_NO_USER,
  RESET_PASSWORD_EMAIL_SUCCESS,
  SIGN_IN_ERROR,
  REGISTER_ERROR,
  DELETE_USER_SUCCESS,
  REGISTER,
  SIGN_IN,
  REGISTER_SUCCESS,
  RESET_PASSWORD_EMAIL,
  SIGN_OUT_SUCCESS,
} from './actionTypes';

import { RootAction } from '..';

export type Claims = {
  admin?: boolean;
  mentor?: boolean;
  tenantId?: string;
  tenantAdmin?: boolean;
  provider?: string;
};

interface UserState {
  auth: Partial<Auth>;
  claims?: Claims;
  isLoading: boolean;
  hasSentResetPasswordEmail: boolean;
  isSendingResetPasswordEmail: boolean;
  isSigningIn: boolean;
  signInError?: string;
  registerError?: string;
  isRegistering: boolean;
}

const initState: UserState = {
  isLoading: true,
  auth: null,
  hasSentResetPasswordEmail: false,
  isSendingResetPasswordEmail: false,
  isRegistering: false,
  isSigningIn: false,
};
export const userReducer = (
  state: UserState = initState,
  action: RootAction,
): UserState => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        isLoading: false,
        auth: action.payload.auth,
        claims: action.payload.claims,
        isRegistering: false,
      };
    case LOAD_AUTH:
      return {
        ...state,
        isLoading: true,
        auth: null,
      };
    case AUTH_LOADED_NO_USER:
      return {
        ...state,
        isLoading: false,
        auth: null,
      };
    case RESET_PASSWORD_EMAIL:
      return {
        ...state,
        isSendingResetPasswordEmail: true,
      };
    case RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        hasSentResetPasswordEmail: true,
        isSendingResetPasswordEmail: false,
      };
    case SIGN_IN: {
      return { ...state, isSigningIn: true };
    }
    case SIGN_IN_ERROR:
      return {
        ...state,
        signInError: action.payload,
        isSigningIn: false,
      };
    case REGISTER:
      return {
        ...state,
        isRegistering: true,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        registerError: action.payload,
        isRegistering: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registerError: undefined,
        isRegistering: false,
      };
    case SIGN_OUT_SUCCESS: {
      return { ...initState, isLoading: false };
    }
    case DELETE_USER_SUCCESS: {
      return initState;
    }
    default:
      return state;
  }
};

// selectors

// The below are in use but dont need to be deleted yet.
export const getSignInError = (state: AppState) => state.user.signInError;
export const getRegisterError = (state: AppState) => state.user.registerError;
export const getRegisterState = (state: AppState) => ({
  isRegistering: getIsRegistering(state),
  error: getRegisterError(state),
});
export const getIsRegistering = (state: AppState) => state.user.isRegistering;
export const getEmailSigninState = (state: AppState) => ({
  error: state.user.signInError,
  isLoading: state.user.isSigningIn,
});
export const getAuthProvider = (state: AppState) =>
  state.user && state.user.claims && (state.user.claims.provider as OAuthProvider);

export const getResetPasswordState = (state: AppState) => ({
  isSending: state.user.isSendingResetPasswordEmail,
  sendSuccess: state.user.hasSentResetPasswordEmail,
});
