import { createAction } from 'typesafe-actions';
import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';

import {
  PaymentsConfirmRegistrationType,
  APIPaymentCustomer,
  AvailableSubscriptionDTO,
} from '../../services/backendService';
import {
  PaymentSubscription,
  AvailableSubscriptionRef,
} from '../../services/database/types';
import { ById } from '../Admin';

import * as ActionTypes from './actionTypes';

export const requestStripeUrl = createAction(ActionTypes.STRIPE_REDIRECT_URL_REQUESTED);
export const requestStripeUrlError = createAction(
  ActionTypes.STRIPE_REDIRECT_URL_ERROR,
  action => (error: string) => action({ error }),
);
export const requestStripeUrlSuccess = createAction(
  ActionTypes.STRIPE_REDIRECT_URL_SUCCESS,
  action => (url: string) => action({ url }),
);

export const confirmOauth = createAction(
  ActionTypes.PAYMENTS_CONFIRM,
  action => (code: string, state: string) => action({ code, state }),
);
export const confirmOauthError = createAction(
  ActionTypes.PAYMENTS_CONFIRM_ERROR,
  action => (error: string) => action({ error }),
);
export const confirmOauthSuccess = createAction(
  ActionTypes.PAYMENTS_CONFIRM_SUCCESS,
  action => (data: PaymentsConfirmRegistrationType) => action({ data }),
);

export const getSavedAccount = createAction(ActionTypes.PAYMENTS_SAVED_ACCOUNT);
export const getSavedAccountError = createAction(
  ActionTypes.PAYMENTS_SAVED_ACCOUNT_ERROR,
  action => (error: string) => action({ error }),
);
export const getSavedAccountSuccess = createAction(
  ActionTypes.PAYMENTS_SAVED_ACCOUNT_SUCCESS,
  action => (data?: { id: string }) => action({ data }),
);

export const createBillingCustomer = createAction(
  ActionTypes.PAYMENTS_CREATE_CUSTOMER,
  action => (paymentMethodId: string, planId: string, card: StripeCardNumberElement) =>
    action({ paymentMethodId, planId, card }),
);
export const createBillingCustomerSuccess = createAction(
  ActionTypes.PAYMENTS_CREATE_CUSTOMER_SUCCESS,
  action => (success: boolean) => action({ success }),
);

export const createBillingCustomerError = createAction(
  ActionTypes.PAYMENTS_CREATE_CUSTOMER_ERROR,
  action => (error: string) => action({ error }),
);

export const initStripe = createAction(ActionTypes.INIT_STRIPE);
export const initStripeDone = createAction(
  ActionTypes.INIT_STRIPE_DONE,
  action => (stripe?: Stripe) => action({ stripe }),
);

export const getSubscription = createAction(ActionTypes.SUBSCRIPTION_PLAN);
export const getSubscriptionSuccess = createAction(
  ActionTypes.SUBSCRIPTION_PLAN_SUCCESS,
  action => (subscription?: PaymentSubscription) => action({ subscription }),
);
export const getSubscriptionError = createAction(
  ActionTypes.SUBSCRIPTION_PLAN_ERROR,
  action => (error: string) => action({ error }),
);

export const getCustomer = createAction(ActionTypes.PAYMENT_CUSTOMER);
export const getCustomerSuccess = createAction(
  ActionTypes.PAYMENT_CUSTOMER_SUCCESS,
  action => (customer: APIPaymentCustomer) => action({ customer }),
);
export const getCustomerError = createAction(
  ActionTypes.PAYMENT_CUSTOMER_ERROR,
  action => (error: string) => action({ error }),
);

export const setCard = createAction(
  ActionTypes.SET_CARD,
  action => (name: string, email: string) => action({ name, email }),
);

export const cancelSubscription = createAction(
  ActionTypes.CANCEL_SUBSCRIPTION,
  action => (subscriptionId: string) => action({ subscriptionId }),
);
export const cancelSubscriptionSuccess = createAction(
  ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
  action => (customer: APIPaymentCustomer) => action({ customer }),
);
export const cancelSubscriptionError = createAction(
  ActionTypes.CANCEL_SUBSCRIPTION_ERROR,
  action => (error: string) => action({ error }),
);

export const reactivateSubscription = createAction(
  ActionTypes.REACTIVATE_SUBSCRIPTION,
  action => (subscriptionId: string) => action({ subscriptionId }),
);
export const reactivateSubscriptionSuccess = createAction(
  ActionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS,
  action => (customer: APIPaymentCustomer) => action({ customer }),
);
export const reactivateSubscriptionError = createAction(
  ActionTypes.REACTIVATE_SUBSCRIPTION_ERROR,
  action => (error: string) => action({ error }),
);

export const updatePaymentDetails = createAction(
  ActionTypes.UPDATE_PAYMENT_DETAILS,
  action => (paymentMethodId: string) => action({ paymentMethodId }),
);
export const updatePaymentDetailsSuccess = createAction(
  ActionTypes.UPDATE_PAYMENT_DETAILS_SUCCESS,
  action => (customer: APIPaymentCustomer) => action({ customer }),
);
export const updatePaymentDetailsError = createAction(
  ActionTypes.UPDATE_PAYMENT_DETAILS_ERROR,
  action => (error: string) => action({ error }),
);

export const saveAvailableSubscription = createAction(
  ActionTypes.SAVE_AVAILABLE_SUBSCRIPTION,
  action => (data: AvailableSubscriptionRef) => action({ data }),
);
export const saveAvailableSubscriptionSuccess = createAction(
  ActionTypes.SAVE_AVAILABLE_SUBSCRIPTION_SUCCESS,
  action => (data: AvailableSubscriptionRef) => action({ data }),
);
export const saveAvailableSubscriptionError = createAction(
  ActionTypes.SAVE_AVAILABLE_SUBSCRIPTION_ERROR,
  action => (error: string) => action({ error }),
);

export const availableSubscriptionsSubscribe = createAction(
  ActionTypes.AVAILABLE_SUBSCRIPTIONS_SUBSCRIBE,
);
export const availableSubscriptionsReceive = createAction(
  ActionTypes.AVAILABLE_SUBSCRIPTIONS_RECEIVE,
  action => (data: ById<AvailableSubscriptionRef>) => action({ data }),
);
export const availableSubscriptionsReceiveError = createAction(
  ActionTypes.AVAILABLE_SUBSCRIPTIONS_RECEIVE_ERROR,
  action => (error: string) => action({ error }),
);

export const fetchPlans = createAction(ActionTypes.FETCH_PLANS);
export const fetchPlansSuccess = createAction(
  ActionTypes.FETCH_PLANS_SUCCESS,
  action => (plans: AvailableSubscriptionDTO[]) => action({ plans }),
);
export const fetchPlansError = createAction(
  ActionTypes.FETCH_PLANS_ERROR,
  action => (error: string) => action({ error }),
);

export const canCreateDiscussion = createAction(ActionTypes.CAN_CREATE_DISCUSSION);
export const canCreateDiscussionSuccess = createAction(
  ActionTypes.CAN_CREATE_DISCUSSION_SUCCESS,
  action => (ok: boolean) => action({ ok }),
);
export const canCreateDiscussionError = createAction(
  ActionTypes.CAN_CREATE_DISCUSSION_ERROR,
  action => (error: string) => action({ error }),
);

export const selectPlanForCheckout = createAction(
  ActionTypes.SELECT_PLAN_FOR_CHECKOUT,
  action => (planId: string) => action({ planId }),
);
export const canCreateDiscussionReset = createAction(
  ActionTypes.CAN_CREATE_DISCUSSION_RESET,
);
