import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

type Props = {
  text: string;
};
export default (props: Props) => {
  return (
    <Tooltip title={props.text}>
      <QuestionCircleOutlined />
    </Tooltip>
  );
};
