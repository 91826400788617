import React from 'react';

export default () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6858 18.894C12.1727 19.3754 12.1727 20.3381 11.6858 20.8195C11.1989 21.0602 10.4685 21.0602 9.98162 20.8195L0.486908 11.4327C-4.39243e-07 10.9513 -4.49764e-07 10.7106 -4.60285e-07 10.4699C-4.70805e-07 10.2292 -4.81326e-07 9.98854 0.243454 9.74785L9.73817 0.361028C10.2251 -0.120347 10.9554 -0.120347 11.4423 0.361028C12.1727 0.601716 12.1727 1.32378 11.6858 1.80516L4.13872 9.26648L20 9.26647C20.7304 9.26647 21.2173 9.74785 21.2173 10.4699C21.2173 11.192 20.7304 11.6734 20 11.6734L4.13872 11.6734L11.6858 18.894Z"
      fill="currentColor"
    />
  </svg>
);
