import React from 'react';

export default () => (
  <svg
    width="26"
    height="32"
    viewBox="0 0 26 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1998 6.54927C19.1998 5.25395 18.8157 3.98771 18.0961 2.91069C17.3764 1.83367 16.3536 0.994235 15.1568 0.498536C13.9601 0.00283769 12.6433 -0.12686 11.3728 0.125845C10.1024 0.37855 8.93544 1.00231 8.01951 1.91824C7.10358 2.83417 6.47982 4.00114 6.22711 5.27157C5.97441 6.54201 6.10411 7.85885 6.59981 9.05557C7.0955 10.2523 7.93494 11.2751 9.01196 11.9948C10.089 12.7144 11.3552 13.0985 12.6505 13.0985C13.5106 13.0985 14.3622 12.9291 15.1568 12.6C15.9514 12.2709 16.6734 11.7885 17.2816 11.1803C17.8897 10.5721 18.3721 9.85016 18.7013 9.05557C19.0304 8.26097 19.1998 7.40933 19.1998 6.54927ZM22.6024 30.1544C23.3863 29.8638 24.0648 29.3443 24.5498 28.6634C25.0348 27.9825 25.304 27.1715 25.3224 26.3357C25.3224 19.3385 19.6478 14.7625 12.6505 14.7625C5.65328 14.7625 0 19.3385 0 26.3357C0.0184322 27.1715 0.287618 27.9825 0.772631 28.6634C1.25765 29.3443 1.9361 29.8638 2.71997 30.1544C5.89162 31.3901 9.26802 32.0162 12.6719 31.9997C16.0757 32.0162 19.4521 31.3901 22.6238 30.1544H22.6024Z"
      fill="currentColor"
    />
  </svg>
);
