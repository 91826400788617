import React from 'react';
import styled from 'styled-components';

import AdminUserTable from '../Admin/AdminUserTable';

export const AdminUsers = () => {
  return (
    <Container>
      <AdminUserTable />
    </Container>
  );
};

export default AdminUsers;

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 40px 60px;
`;
