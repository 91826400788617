import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import { MsalAuthProvider } from 'react-aad-msal';

import PrivateRoute from './hoc/PrivateRoute';
import AuthSubscriber from './providers/AuthSubscriber';
import RootSubscriber from './providers/RootSubscriber';
import NotFound from './pages/NotFound';
import NotificationProvider from './providers/NotificationProvider';
import Loader from './components/Loader';
import PwaInstallProvider from './providers/PwaInstallProvider';
import ReviewNotificationProvider from './providers/ReviewNotificationProvider';
import TenantAdminRoute from './hoc/TenantAdminRoute';
import AdminPage from './pages/AdminPage';
import FirstTimeLoginProvider from './providers/FirstTimeLoginProvider';
const Main = lazy(() => import('./pages/Main' /* webpackChunkName: "main-app" */));
const LoginPage = lazy(() =>
  import('./pages/LoginPage' /* webpackChunkName: "login-page" */),
);
const PaymentOAuthCallback = lazy(() =>
  import('./pages/PaymentOAuthCallback' /* webpackChunkName: "payment-oauth-callback" */),
);
const ForgotPasswordPage = lazy(() =>
  import('./pages/ForgotPasswordPage' /* webpackChunkName: "forgot-password" */),
);
const ClaimChat = lazy(() =>
  import('./pages/ClaimChat' /* webpackChunkName: "claim-chat-page" */),
);

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  height: 100%;
  position: relative;
`;

const App = ({ authProvider }: { authProvider: MsalAuthProvider }) => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <PwaInstallProvider>
          <AuthSubscriber authProvider={authProvider}>
            <NotificationProvider>
              <RootSubscriber>
                <FirstTimeLoginProvider>
                  <Root>
                    <ReviewNotificationProvider>
                      <Suspense fallback={<Loader />}>
                        <Switch>
                          <PrivateRoute
                            path="/"
                            exact
                            component={() => <Redirect to="/main" />}
                            redirect="/login"
                          />
                          <PrivateRoute path="/main" component={Main} redirect="/login" />
                          <PrivateRoute
                            path="/claim-chat/:id"
                            component={ClaimChat}
                            redirect="/"
                          />
                          <TenantAdminRoute
                            path="/admin/"
                            component={AdminPage}
                            redirect="/"
                          />
                          <PrivateRoute
                            path="/billing/oauth"
                            component={PaymentOAuthCallback}
                            redirect="/"
                            exact
                          />
                          <Route path="/login" component={LoginPage} exact />
                          <Route path="/register" component={LoginPage} exact />
                          <Route path="/forgot-password" component={ForgotPasswordPage} />
                          <Route path="*" component={NotFound} />
                        </Switch>
                      </Suspense>
                    </ReviewNotificationProvider>
                  </Root>
                </FirstTimeLoginProvider>
              </RootSubscriber>
            </NotificationProvider>
          </AuthSubscriber>
        </PwaInstallProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
