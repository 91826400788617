import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  fetchReports,
  getReports,
  getUserRatings,
  getPopularTags,
  fetchPopularTags,
} from '../../modules';
import { useTypedSelector } from '../../hooks';
import { SectionTitle } from '../_atoms/SectionTitle';
import { ResultCard } from '../_molecules/ResultCard';

import LineChartPerDay from './LineChartPerDay';
import DiscussionsReport from './DiscussionsReport';
import Comments from './Comments';
import ReportsLoader from './ReportsLoader';
import BarChart from './BarChart';
import TopicFilter, { TrendFilter } from './TopicFilter';
const { RangePicker } = DatePicker;

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Admin');
  const { isLoading, data } = useTypedSelector(getReports);
  const { totalRating } = useTypedSelector(getUserRatings);
  const { popularTags, isLoading: popularTagsLoading } = useTypedSelector(getPopularTags);

  const [trendFilter, setTrendFilter] = useState<TrendFilter[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dateFilter, setDateFilter] = useState<[any, any]>([
    moment().subtract(30, 'days'),
    moment(),
  ]);

  useEffect(() => {
    if (!data) dispatch(fetchReports());
  }, [dispatch, data]);

  useEffect(() => {
    if (!popularTags) dispatch(fetchPopularTags());
  }, [dispatch, popularTags]);

  const handleFilterChange = useCallback((values: TrendFilter[]) => {
    setTrendFilter(values);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDatesChange = (values: any) => {
    setDateFilter(values);
  };

  return (
    <Row style={{ padding: '40px 60px' }}>
      <Col span={24}>
        <SectionTitle>{t('Filters')}</SectionTitle>
      </Col>
      <Col span={24}>
        <TopicFilter onFilterChange={handleFilterChange} />
      </Col>
      <Col span={24} style={{ padding: 20 }}>
        <RangePicker
          value={dateFilter}
          onChange={dates => dates && handleDatesChange(dates)}
        />
      </Col>
      <Col span={24}>
        <SectionTitle>{t('Total discussions')}</SectionTitle>
      </Col>
      <Col span={24}>
        {isLoading && <ReportsLoader />}
        {data && (
          <DiscussionsReport
            trendFilter={trendFilter}
            dateFilter={dateFilter}
            data={data}
            type={'dicussionsPerTopicPerDay'}
          />
        )}
      </Col>
      <Col span={24}>
        <SectionTitle>{t('Average waiting time')}</SectionTitle>
      </Col>
      <Col span={24}>
        {isLoading && <ReportsLoader />}
        {data && (
          <LineChartPerDay
            dateFilter={dateFilter}
            trendFilter={trendFilter}
            data={data}
            type={'timeToClaimedPerDay'}
          />
        )}
      </Col>
      <Col span={24}>
        <SectionTitle>{t('Average discussion duration')}</SectionTitle>
      </Col>
      <Col span={24}>
        {isLoading && <ReportsLoader />}
        {data && (
          <LineChartPerDay
            dateFilter={dateFilter}
            trendFilter={trendFilter}
            data={data}
            type={'timeToCompletedPerDay'}
          />
        )}
      </Col>
      <Col span={24}>
        <SectionTitle>{t('Trending discussion tags by mentors')}</SectionTitle>
      </Col>
      <Col span={24}>
        {popularTagsLoading && <ReportsLoader />}
        {popularTags && (
          <BarChart
            dateFilter={dateFilter}
            trendFilter={trendFilter}
            data={popularTags}
            type={'countTags'}
          />
        )}
      </Col>
      <Col span={24}>
        <SectionTitle>{t('Feedback from students')}</SectionTitle>
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={12}>
            <ResultCard
              title={t('Avg rating')}
              value={totalRating && `${totalRating.avg.toFixed(2)} / 5`}
            />
          </Col>
          <Col span={12}>
            <ResultCard
              title={t('Total received feedbacks')}
              value={totalRating?.total}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Comments />
      </Col>
    </Row>
  );
};
