import React, { useMemo } from 'react';
import {
  LineChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';

import { ChatRoomMetrics } from '../../services/database/types';
import {
  timeToClaimedPerDay,
  timeToCompletedPerDay,
} from '../../services/database/utils';
import theme from '../../theme';

import EmptyReport from './EmptyReport';
import { TrendFilter } from './TopicFilter';

const aggregates = {
  timeToClaimedPerDay,
  timeToCompletedPerDay,
};

type AggregateType = typeof aggregates;

export type ReportType = keyof AggregateType;

export default ({
  trendFilter,
  dateFilter,
  data,
  type,
}: {
  data: ChatRoomMetrics[];
  type: ReportType;
  trendFilter: TrendFilter[];
  dateFilter: [Moment, Moment];
}) => {
  const { t } = useTranslation('Admin');
  const rawData = useMemo(() => {
    const options = {
      start: dateFilter[0].toDate(),
      end: dateFilter[1].toDate(),
      topicFilter: trendFilter.map(t => t.value),
    };
    return aggregates[type](data, options);
  }, [data, type, trendFilter, dateFilter]);

  const legendFormatter = (value: string) => {
    return t(value);
  };
  if (rawData.length === 0) return <EmptyReport />;
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={rawData} margin={{ top: 20, right: 20, bottom: 20, left: 40 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis unit=" min" />
        <XAxis dataKey="x" interval={2} />
        <Tooltip />
        <Legend formatter={legendFormatter} />
        <Line
          type="natural"
          dataKey="avg"
          stroke={theme.colors.chart.avg}
          strokeWidth={3}
        />
        <Line type="monotone" dataKey="p95" stroke={theme.colors.chart.p95} />
        <Line type="monotone" dataKey="p99" stroke={theme.colors.chart.p99} />
        <Line type="monotone" dataKey="globalP95" stroke={theme.colors.chart.globalP95} />
        <Line type="monotone" dataKey="globalP99" stroke={theme.colors.chart.globalP99} />
      </LineChart>
    </ResponsiveContainer>
  );
};
