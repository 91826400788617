import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Modal, Button } from 'antd';

import { Icons } from '../../Icons';
import { Steps } from '../../_atoms/Steps';
import Background from '../../../assets/images/background_grey.png';
import theme from '../../../theme';
import { CircleButton, CenteredContainer } from '../../../styled';
import {
  useTypedSelector,
  useIsDesktop,
  useMetrics,
  usePrevious,
  useQuery,
} from '../../../hooks';
import {
  getCreateDiscussion,
  getTopics,
  selectTopic,
  selectSubTopic,
  nextStep,
  addImage,
  initCreateDiscussion,
  addTextMessage,
  getCanCreateDiscussion,
  canCreateDiscussion,
  canCreateDiscussionState,
} from '../../../modules';
import LoaderModal from '../../LoaderModal';

import { SelectOption } from './SelectOption';
import { CreateInitialMessage } from './CreateInitialMessage';

type Props = {};

export const NewDiscussion: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { search } = useQuery();
  const { t } = useTranslation('CreateDiscussion');
  const discussionState = useTypedSelector(getCreateDiscussion);
  const isCreateDiscussionFormValid = useTypedSelector(getCanCreateDiscussion);
  const createDiscussionAPIState = useTypedSelector(canCreateDiscussionState);
  const selectableTopics = useTypedSelector(getTopics);
  const [showMissingSubscriptionModal, setShowMissingSubscriptionModal] = useState(false);
  const isDesktop = useIsDesktop();
  const { path } = useRouteMatch();
  const history = useHistory();
  const metrics = useMetrics();
  const handleCancel = () => {
    history.push('/main');
    dispatch(initCreateDiscussion());
    metrics.logEvent('discussion.cancel');
  };

  const handleTopicSelected = (topicId: string, subTopicId: string) => {
    metrics.logEvent('discussion.topicSelected', { topicId, subTopicId });
    dispatch(selectTopic(topicId));
    dispatch(selectSubTopic(subTopicId));
    dispatch(nextStep());
  };

  const handleImageSelected = (dataUrl: string) => {
    metrics.logEvent('discussion.addImage');
    dispatch(addImage(dataUrl));
  };

  const handleTextMessageCreated = (value: string) => {
    dispatch(addTextMessage(value));
  };

  const prevCanCreateDiscussionState = usePrevious(createDiscussionAPIState);

  const handleStartDiscussion = () => {
    metrics.logEvent('discussion.start');
    dispatch(canCreateDiscussion());
  };

  useEffect(() => {
    if (
      prevCanCreateDiscussionState?.isLoading &&
      !createDiscussionAPIState.isLoading &&
      (createDiscussionAPIState.error || !createDiscussionAPIState.ok)
    ) {
      setShowMissingSubscriptionModal(true);
    }
  }, [prevCanCreateDiscussionState, createDiscussionAPIState, dispatch]);

  const { step, isCreatingDiscussion, createdChatRoomId } = discussionState;

  useEffect(() => {
    if (createdChatRoomId) {
      if (isDesktop) {
        history.replace(`${path}?chatRoomId=${createdChatRoomId}`);
      } else {
        history.replace(`/main/chatroom/${createdChatRoomId}`);
      }
      dispatch(initCreateDiscussion());
    }
  }, [createdChatRoomId, dispatch, history, isDesktop, path]);

  const steps = [
    <SelectOption topics={selectableTopics || {}} onSelect={handleTopicSelected} />,
    <CreateInitialMessage
      onImageSelected={handleImageSelected}
      onTextMessageCreated={handleTextMessageCreated}
      onStartDiscussion={handleStartDiscussion}
      canCreateDiscussion={
        isCreateDiscussionFormValid && !createDiscussionAPIState.isLoading
      }
    />,
  ];
  return (
    <Container>
      <TopNav>
        <StyledCircleButton
          backgroundColor="#FFFFFF"
          color="#D3D3D3"
          onClick={handleCancel}
        >
          <Icons.Close />
        </StyledCircleButton>
      </TopNav>
      <MainContainer>{steps[step]}</MainContainer>
      <BottomNav>
        <Steps numsteps={steps.length} currentStep={discussionState.step} />
      </BottomNav>
      <LoaderModal
        visible={!!isCreatingDiscussion}
        text={t('createDiscussion', {
          defaultValue: 'Creating the discussion. Please wait a moment.',
        })}
      />
      <Modal
        footer={() => null}
        title={t('Missing subscription')}
        visible={showMissingSubscriptionModal}
        centered
        onCancel={() => {
          metrics.logEvent('missingSubscription', { accept: false });
          setShowMissingSubscriptionModal(false);
        }}
      >
        <CenteredContainer style={{ flexDirection: 'column' }}>
          <p>{t('You need a plan to start discussions')}</p>
          <Button
            onClick={() => {
              setShowMissingSubscriptionModal(false);
              metrics.logEvent('missingSubscription', { accept: true });
              history.push(`/main/profile/billing${search}`);
            }}
            type="primary"
          >
            {t('Upgrade my plan')}
          </Button>
        </CenteredContainer>
      </Modal>
    </Container>
  );
};

const TopNav = styled.div`
  height: 60px;
  flex: 0;
  width: 100%;
  padding: 30px 30px 10px 30px;
  display: flex;
  align-items: center;
`;

const MainContainer = styled.div`
  padding: 10px 30px;
  flex: 1;
  min-height: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
`;

const BottomNav = styled.div`
  flex: 0;
  height: 60px;
  padding: 5px 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-image: url(${Background});
  background-color: ${theme.colors.orange};
`;

const StyledCircleButton = styled(CircleButton)`
  &:hover {
    background-color: ${theme.colors.orange};
  }
`;
