export const CHATROOMS_REQUESTED = 'CHATROOMS/REQUESTED';
export const CHATROOMS_RECEIVED = 'CHATROOMS/RECEIVED';

export const GET_NAMES_BY_ID = 'CHATROOMS/GET_NAMES_BY_ID';
export const GET_NAMES_BY_ID_FAILED = 'CHATROOMS/GET_NAMES_BY_ID_FAILED';
export const GET_NAMES_BY_ID_SUCCESS = 'CHATROOMS/GET_NAMES_BY_ID_SUCCESS';

export const ADD_CHATROOM_TO_USER = 'CHATROOMS/ADD_CHATROOM_TO_USER';

export const SET_USER_MESSAGE_TIMESTAMP = 'CHATOOMS/SET_USER_MESSAGE_TIMESTAMP';
export const CHATROOM_COMPLETED = 'CHATROOMS/CHATROOM_COMPLETED';
export const CHATROOM_UPDATED = 'CHATROOMS/CHATROOM_UPDATED';
export const CHATROOM_EXTRA_INFO_UPDATED = 'CHATROOMS/CHATROOM_EXTRA_INFO_UPDATED';

export const CHATROOM_SUBSCRIBE = 'CHATROOMS/CHATROOM_SUBSCRIBE';
export const CHATROOM_EXTRA_INFO_SUBSCRIBE = 'CHATROOMS/CHATROOM_EXTRA_INFO_SUBSCRIBE';

export const SEND_MESSAGE = 'CHATROOMS/SEND_MESSAGE';
export const UPLOAD_IMAGE = 'CHATROOMS/UPLOAD_IMAGE';
export const MENTOR_RECEIVED = 'CHATROOMS/mentor_received';
export const CHATROOM_SELECTED = 'CHATROOMS/CHATROOM_SELECTED';
export const DESELECT_CHATROOM = 'CHATROOMS/DESELECT_CHATROOM';

export const SELECT_TAB = 'CHATROOMS/SELECT_TAB';

export const SET_DISCUSSION_LIVE_INFO = 'CHATROOMS/SET_DISCUSSION_LIVE_INFO';
export const SUBSCRIBE_DISCUSSION_LIVE_INFO = 'CHATROOMS/SUBSCRIBE_DISCUSSION_LIVE_INFO';
export const RECEIVE_DISCUSSION_LIVE_INFO = 'CHATROOMS/RECEIVE_DISCUSSION_LIVE_INFO';

export const RECEIVE_TAGS = 'CHATROOMS/RECEIVE_TAGS';
export const REQUEST_TAGS = 'CHATROOMS/REQUEST_TAGS';

export const START_DISCUSSION_TIMER = 'CHATROOMS/START_DISCUSSION_TIMER';
export const METERED_DISCUSSION_RECEIVED = 'CHATROOMS/METERED_DISCUSSION_RECEIVED';
