import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import { useDispatch } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { ChatRoomsOverview } from '../../_organisms/ChatRoomsOverview';
import { Icons } from '../../Icons';
import { FloatingActionButton } from '../../_atoms/FloatingActionButton';
import {
  getActiveChatRoomsStripped,
  getSelectedChatRoom,
  selectChatRoom,
  getCompletedChatRoomStripped,
  setUnAvailable,
  setAvailable,
  getIsMentor,
  getIsAvailable,
  getUnclaimedDiscussionCount,
  UIDiscussion,
} from '../../../modules';
import { useTypedSelector, useIsDesktop, useQuery, useMetrics } from '../../../hooks';
import { MentorButton } from '../../_atoms/MentorButton';
import InfoBanner from '../../_organisms/InfoBanner';

type Props = {
  hideProfile?: boolean;
  hideFloatingActionButton?: boolean;
};
export const OverviewController = (props: Props) => {
  const { hideFloatingActionButton = false, hideProfile = false } = props;
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { search } = useQuery();
  const activeChatRooms = useTypedSelector(getActiveChatRoomsStripped);
  const completedChatRooms = useTypedSelector(getCompletedChatRoomStripped);
  const selectedChatRoom = useTypedSelector(getSelectedChatRoom);
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const metrics = useMetrics();
  const isMentor = useTypedSelector(getIsMentor);
  const isAvailable = useTypedSelector(getIsAvailable);
  const unClaimedDiscussionCount = useTypedSelector(getUnclaimedDiscussionCount);

  const handleNewButtonClicked = () => {
    metrics.logEvent('discussion.new');
    if (isDesktop) {
      history.push(`${path}?newDiscussion=true`);
    } else {
      history.push(`${path}/newDiscussion`);
    }
  };

  const handleChatRoomSelected = useCallback(
    (chatRoom: UIDiscussion) => {
      dispatch(selectChatRoom(chatRoom.id));
      if (isDesktop) {
        history.push(`${path}?chatRoomId=${chatRoom.id}`);
      } else {
        history.push(`${path}/chatroom/${chatRoom.id}`);
      }
    },
    [dispatch, isDesktop, history, path],
  );
  return (
    <Container>
      {hideProfile ? (
        <Row
          style={{
            padding: 15,
            flex: '0 0 auto',
            backgroundColor: '#fffdfb',
          }}
          justify="space-between"
          align="middle"
        ></Row>
      ) : (
        <Row
          style={{
            padding: 20,
            flex: '0 0 auto',
          }}
          justify="space-between"
          align="middle"
        >
          <LogoContainer>
            <Icons.Logo />
          </LogoContainer>
          <FloatingActionButton
            icon="Profile"
            onClick={() => history.push(`${url}/profile${search}`)}
            active={false}
            size="small"
            unseenCount={
              isMentor && unClaimedDiscussionCount > 0
                ? unClaimedDiscussionCount
                : undefined
            }
          />
        </Row>
      )}
      <InfoBanner />
      <ChatRoomsOverview
        activeItems={activeChatRooms}
        finishedItems={completedChatRooms}
        selectedChatRoom={selectedChatRoom}
        onChatRoomSelected={handleChatRoomSelected}
      />
      {!hideFloatingActionButton && (
        <FloatingButtonsContainer>
          {isMentor ? (
            <MentorButton
              isAvailable={isAvailable}
              onClick={() => dispatch(isAvailable ? setUnAvailable() : setAvailable())}
            />
          ) : (
            <FloatingActionButton
              icon="New"
              onClick={handleNewButtonClicked}
              active
              type="navigation"
              size="large"
            />
          )}
        </FloatingButtonsContainer>
      )}
    </Container>
  );
};

const FloatingButtonsContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
`;

const LogoContainer = styled.div`
  padding-left: 20px;
  & svg {
    height: 50px;
    width: 120px;
    color: #9d9d9d;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
