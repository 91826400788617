import firebase from 'firebase/app';
import 'firebase/database';

import { ById, Topic, NotificationTemplate } from '../modules';

//PATHS
const CHAT_ROOM_PATH = 'chatrooms';
const MESSAGES_PATH = 'messages';

export class DB {
  public db: firebase.database.Database;
  tenantId: string;
  timeStamp = firebase.database.ServerValue.TIMESTAMP;

  constructor(tenantId: string) {
    this.db = firebase.database();
    this.tenantId = tenantId;
  }

  getTenantId = async (): Promise<string> => {
    return this.tenantId;
  };

  private rootTenantPath = (tenantId: string) => `tenants/${tenantId}`;

  rootRef = async () => {
    const tenantId = await this.getTenantId();
    return this.db.ref(this.rootTenantPath(tenantId));
  };

  public fetchQuestionTemplateById = async (templateId: string) => {
    const rootRef = await this.rootRef();

    const snap = await rootRef
      .child(`/server/notifications/templates/${templateId}`)
      .once('value');
    return snap.val() as NotificationTemplate;
  };

  topicRef = async () => this.rootRef().then(ref => ref.child('server/topics'));

  updateTopics = async (topics: ById<Topic>) => {
    const ref = await this.topicRef();
    await ref.set(topics);
  };

  /**
   * BELOW THIS ARE CALLS THAT NEEDS TO BE MIGRATED
   */

  private addRootEntity = async <T>(path: string, object: T, id?: string) => {
    try {
      const rootRef = await this.rootRef();
      const key = id || rootRef.child(path).push().key;

      const updates: { [str: string]: T } = {};
      updates[`/${path}/${key}`] = { id: key!, ...object };
      return { key, updates: await rootRef.update(updates) };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {};
    }
  };

  /**
   * CHATROOMS START
   */

  public addChatRoom = (chatRoom: ChatRoomEntity, id?: string) => {
    return this.addRootEntity<ChatRoomEntity>(CHAT_ROOM_PATH, chatRoom, id);
  };

  public createChatRoomId = async () => {
    const rootRef = await this.rootRef();
    const key = rootRef.child(CHAT_ROOM_PATH).push().key;
    return key as string;
  };

  public setChatRoomCompleted = async (chatRoomId: string, completedBy: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updates: { [str: string]: any } = {};
    updates[`${CHAT_ROOM_PATH}/${chatRoomId}/isComplete`] = true;
    updates[`${CHAT_ROOM_PATH}/${chatRoomId}/completedBy`] = completedBy;
    updates[`${CHAT_ROOM_PATH}/${chatRoomId}/completedAt`] = new Date().getTime();
    const rootRef = await this.rootRef();
    return rootRef.update(updates);
  };

  public sendMessage = async (message: Message, chatRoomId: string) => {
    const rootRef = await this.rootRef();
    if (!(message.imageId || message.text))
      throw Error('Must specify either an image or a text in the message');
    // TODO ADD METRIC
    const path = `${CHAT_ROOM_PATH}/${chatRoomId}/${MESSAGES_PATH}`;
    const key = rootRef
      .child(CHAT_ROOM_PATH)
      .child(chatRoomId)
      .child(MESSAGES_PATH)
      .push().key;
    const updates: { [str: string]: Message } = {};
    updates[`${path}/${key}`] = {
      ...message,
      id: key!,
      timeStamp: new Date().getTime(),
    };
    await rootRef.update(updates);
    return { id: key! };
  };

  /**
   * CHATROOMS END
   */
}

export type PendingReview = {
  id: string;
  createdAt: number;
  templateId: string;
  metadata?: {
    mentorId: string;
    chatRoomId: string;
  };
};

export interface DeletedUser {
  uid: string;
  timestamp: number;
}

export interface User {
  id?: string;
  name: string;
}

export interface UserType {
  uid: string;
  id: string;
  instanceIds?: ById<string>;
  isAvailable?: boolean;
  mentorPreferences?: MentorPreferences;
  nickName?: string;
  lastSeenInfoBanner?: Date;
}

export interface Mentor {
  id?: string;
  name?: string;
  chatRooms?: { [chatRoomId: string]: string };
  userType?: UserType;
}

export interface ChatRoomEntity {
  id?: string;
  userId: string;
  mentorId?: string;
  isComplete: boolean;
  completedBy?: string;
  claimedAt: number | null;
  createdAt?: number;
  completedAt?: number;
  messages?: ById<Message>;
  // Todo must ensure that this exists somewhere in the future
  additionalInfo?: AdditionalInfo;
}

export type AdditionalInfo = {
  product: string;
  topic: string[];
  questionType: string;
};

export type MentorPreferences = {
  topics: {
    [topicName: string]: ById<string>;
  };
};

export interface Message {
  id?: string;
  text?: string;
  imageId?: string;
  createdBy: string;
  timeStamp?: number;
}

export interface QuestionnaireForm {
  id?: string;
  0?: 'yes' | 'no';
  1?: number;
  2?: 'yes' | 'no';
  3?: string;
  name: string;
}

export interface GeneralReview {
  userId: string;
  timestamp: number;
  stars: number;
  comment?: string;
}

export type MentorReviewUI = {
  mentorId: string;
  stars: number;
  comment?: string;
  chatRoomId?: string;
};
export interface ReviewUI {
  stars: number;
  comment?: string;
  chatRoomId?: string;
}

type MentorReview = GeneralReview & {
  chatRoomId?: string;
  mentorId: string;
};
export interface MentorReviewDB {
  [mentorId: string]: {
    [chatRoomId: string]: MentorReview;
  };
}

export type UserFeedback = {
  userFeedback: {
    [uid: string]: {
      byTemplateId: {
        [templateId: string]: {
          [responseId: string]: UserFeedbackResponse;
        };
      };
    };
  };
};

export type UserFeedbackResponse = {
  id: string;
  templateId: string;
  createdBy: string;
  createdAt: number;
  value: number | string;
  comment?: string;
  answerId?: string;
  metadata?: {
    [key: string]: string;
  };
};

export type MatchmakingElement = {
  createdAt: number;
  updatedAt: number;
  chatRoomId: string;
  mentors: { [id: string]: string };
  isClaimed: boolean;
  claimedBy?: string;
  id: string;
  chatRoomData?: ChatRoomEntity;
};
