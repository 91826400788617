import React from 'react';
import styled from 'styled-components';

import theme from '../../../theme';
import { Icons } from '../../Icons';
import { UnseenLabel } from '../../../styled/UnseenLabel';

type ButtonSize = 'small' | 'default' | 'large';
type ButtonType = 'navigation' | 'default';
interface Props {
  onClick: () => void;
  icon: 'New' | 'Profile' | 'Send2' | 'Close';
  active: boolean;
  type?: ButtonType;
  size?: ButtonSize;
  unseenCount?: number;
}

const getSize = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return 48;
    case 'large':
      return 68;
    default:
      return 56;
  }
};

const getColors = (
  buttonType: ButtonType,
  active: boolean,
): { color: string; backgroundColor: string } => {
  if (buttonType === 'default' && active)
    return { color: theme.colors.orange, backgroundColor: theme.colors.white };
  if (buttonType === 'default' && !active)
    return { color: theme.colors.grey, backgroundColor: theme.colors.white };
  if (buttonType === 'navigation' && active)
    return { color: theme.colors.white, backgroundColor: theme.colors.orange };
  if (buttonType === 'navigation' && !active)
    return { color: theme.colors.orange, backgroundColor: theme.colors.white };
  return { color: theme.colors.black, backgroundColor: theme.colors.googleRed };
};

export const FloatingActionButton = (props: Props) => {
  const {
    onClick,
    icon,
    active,
    size = 'default',
    type = 'default',
    unseenCount,
  } = props;
  const { color, backgroundColor } = getColors(type, active);
  const Icon = Icons[icon];
  return (
    <StyledButton
      onClick={onClick}
      width={getSize(size)}
      color={color}
      backgroundColour={backgroundColor}
    >
      <Icon />
      {unseenCount && unseenCount > 0 && <UnseenTag>{unseenCount}</UnseenTag>}
    </StyledButton>
  );
};

const UnseenTag = styled(UnseenLabel)`
  position: absolute;
  top: -3px;
  right: -3px;
`;

const StyledButton = styled.button<{
  width: number;
  color: string;
  backgroundColour: string;
}>`
  color: ${({ color }) => color};
  background-color: ${({ backgroundColour }) => backgroundColour};
  position: relative;
  border: none;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: ${props => props.width}px;
  height: ${props => props.width}px;

  cursor: pointer;
  touch-action: manipulation;

  & svg {
    height: ${props => props.width / 2.5}px;
    width: ${props => props.width / 2.5}px;
  }

  &:active {
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #ffac68;
    color: ${theme.colors.white};
  }
`;
