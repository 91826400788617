import { Epic } from 'redux-observable';

import { AppDependencies } from '../store';

import { ChatRoomAction } from './ChatRooms';
import { NotificationAction } from './Notifications';
import { UserTypeAction } from './UserType';
import { UserAction } from './User';
import { EnvironmentAction } from './Environment';
import { BlobsAction } from './Blobs';
import { ServerAction } from './Server';
import { ReviewsAction } from './Reviews';
import { AdminAction } from './Admin';
import { AppState } from './rootReducer';
import { MentorAction } from './Mentor';
import { CreateDiscussionAction } from './CreateDiscussion';
import { PaymentsAction } from './Payments';
import { AuthAction } from './Auth';

export type RootAction =
  | ChatRoomAction
  | NotificationAction
  | UserTypeAction
  | UserAction
  | EnvironmentAction
  | BlobsAction
  | ServerAction
  | ReviewsAction
  | AdminAction
  | MentorAction
  | CreateDiscussionAction
  | AuthAction
  | PaymentsAction;

export type RootEpic = Epic<RootAction, RootAction, AppState, AppDependencies>;

export * from './Notifications';
export * from './ChatRooms';
export * from './UserType';
export * from './User';
export * from './Environment';
export * from './Blobs';
export * from './Server';
export * from './Reviews';
export * from './Admin';
export * from './Mentor';
export * from './CreateDiscussion';
export * from './Payments';
export * from './Auth';

export * from './rootReducer';
export * from './rootEpic';
