import { combineReducers } from 'redux';

import { AppState } from '../rootReducer';
import { MatchMakingWithChatRoom } from '../../services/backendService';

import {
  QUEUE_RECEIVED,
  QUEUE_REQUESTED,
  QUEUE_RECEIVED_ERROR,
  QUEUE_ITEM_CLAIMED,
  QUEUE_ITEM_CLAIMED_ERROR,
  QUEUE_ITEM_CLAIMED_SUCCESS,
  QUEUE_ITEM_CLAIMED_RESET,
  DISCUSSION_CLAIMED,
  DISCUSSION_CLAIMED_SUCCESS,
  DISCUSSION_CLAIMED_ERROR,
  DISCUSSION_CLAIMED_RESET,
} from './actionTypes';

import { RootAction } from '..';

type LoadedState = {
  isLoading: boolean;
  error?: string;
};

type QueueState = LoadedState & {
  items: MatchMakingWithChatRoom[];
};

const initialState: QueueState = {
  isLoading: false,
  items: [],
};

export const queueReducer = (state = initialState, action: RootAction): QueueState => {
  switch (action.type) {
    case QUEUE_REQUESTED:
      return { ...state, isLoading: true };
    case QUEUE_RECEIVED_ERROR:
      return { ...state, isLoading: false };
    case QUEUE_RECEIVED:
      return { ...state, isLoading: false, items: action.payload.items };
    case QUEUE_ITEM_CLAIMED_SUCCESS:
      return {
        ...state,
        items: state.items.filter(item => item.chatRoomId !== action.payload.chatRoomId),
      };
    default:
      return state;
  }
};

type ClaimQueueState = LoadedState & {
  claimedChatRoomId?: string;
};
const claimQueueState = {
  isLoading: false,
};
export const claimQueueReducer = (
  state = claimQueueState,
  action: RootAction,
): ClaimQueueState => {
  switch (action.type) {
    case QUEUE_ITEM_CLAIMED:
      return { ...state, isLoading: true };
    case QUEUE_ITEM_CLAIMED_RESET:
      return claimQueueState;
    case QUEUE_ITEM_CLAIMED_ERROR:
      return {
        ...state,
        isLoading: false,
        claimedChatRoomId: undefined,
        error: action.payload.message,
      };
    case QUEUE_ITEM_CLAIMED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        claimedChatRoomId: action.payload.chatRoomId,
      };
    default:
      return state;
  }
};

type ClaimDiscussionState = LoadedState & {
  claimedChatRoomId?: string;
};

const claimDiscussionState = {
  isLoading: false,
};

export const claimDiscussionReducer = (
  state = claimDiscussionState,
  action: RootAction,
): ClaimDiscussionState => {
  switch (action.type) {
    case DISCUSSION_CLAIMED:
      return { ...state, isLoading: true };
    case DISCUSSION_CLAIMED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        claimedChatRoomId: action.payload.chatRoomId,
      };
    case DISCUSSION_CLAIMED_ERROR:
      return {
        ...state,
        isLoading: false,
        claimedChatRoomId: undefined,
        error: action.payload.message,
      };
    case DISCUSSION_CLAIMED_RESET:
      return claimQueueState;
    default:
      return state;
  }
};

export const mentorReducer = combineReducers({
  queue: queueReducer,
  claimQueue: claimQueueReducer,
  claimDiscussion: claimDiscussionReducer,
});

// selectors
export const getQueueItems = (state: AppState) => state.mentor.queue.items;
export const isQueueLoading = (state: AppState) => state.mentor.queue.isLoading;
export const isClaimQueueItemLoading = (state: AppState) =>
  state.mentor.claimQueue.isLoading;

export const getClaimedQueueChatRoomId = (state: AppState) =>
  state.mentor.claimQueue.claimedChatRoomId;
export const getClaimError = (state: AppState) => state.mentor.claimQueue.error;

export const getClaimDiscussion = (state: AppState) => state.mentor.claimDiscussion;
export const getUnclaimedDiscussionCount = (state: AppState) =>
  getQueueItems(state).length;
