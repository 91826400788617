import React, { useMemo } from 'react';
import {
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';
import { Moment } from 'moment';

import { ChatRoomMetrics } from '../../services/database/types';
import { dicussionsPerTopicPerDay } from '../../services/database/utils';

import EmptyReport from './EmptyReport';
import { TrendFilter } from './TopicFilter';

const aggregates = {
  dicussionsPerTopicPerDay,
};

type AggregateType = typeof aggregates;

export type ReportType = keyof AggregateType;

export default ({
  trendFilter,
  dateFilter,
  data,
}: {
  data: ChatRoomMetrics[];
  type: ReportType;
  trendFilter: TrendFilter[];
  dateFilter: [Moment, Moment];
}) => {
  const cleanedData = useMemo(() => {
    const options = {
      start: dateFilter[0].toDate(),
      end: dateFilter[1].toDate(),
      topicFilter: trendFilter.map(t => t.value),
    };
    return dicussionsPerTopicPerDay(data, options);
  }, [data, dateFilter, trendFilter]);

  if (data.length === 0) return <EmptyReport />;
  return (
    <Container>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={cleanedData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {trendFilter.map(({ value }) => {
            const color = '#' + (((1 << 24) * Math.random()) | 0).toString(16);
            return (
              <Area
                key={value}
                type="monotone"
                dataKey={value}
                stackId="1"
                stroke={color}
                fill={color}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
