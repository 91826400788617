import styled from 'styled-components';

export const AntStyleOverrider = styled.div<{ disableGlobalPadding?: boolean }>`
  .ant-collapse {
    border: none;
    background-color: transparent;
  }
  .ant-collapse-header {
    background-color: #cfc5bb;
    border-radius: 7px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: 350;
    font-size: 17px;
    color: #ffffff;
  }

  .ant-collapse-item {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
  }
  .ant-collapse > .ant-collapse-item:last-child {
    border-radius: 7px;
  }

  .ant-collapse-content {
    border-top: none;
    .ant-collapse-content-box {
      padding: ${({ disableGlobalPadding }) => (disableGlobalPadding ? '0px' : '16px')};
    }
  }
`;
