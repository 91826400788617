import React from 'react';
import styled from 'styled-components';
import { Modal, Spin } from 'antd';

type Props = {
  visible: boolean;
  text: string;
};
const LoaderModal = (props: Props) => {
  const { visible, text } = props;
  return (
    <StyledModal
      title=""
      visible={visible}
      footer={null}
      centered
      mask
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOk={() => {}}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel={() => {}}
    >
      <Body>
        <Text>{text}</Text>
        <Spin size="default" />
      </Body>
    </StyledModal>
  );
};

export default LoaderModal;

const Text = styled.p`
  margin: 10px;
  text-align: center;
  font-size: 16px;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-close {
    display: none;
  }
`;
