export const FETCH_USERS = 'ADMIN/FETCH_USERS';
export const FETCH_INCOMPLETE_CHATROOMS = 'ADMIN/FETCH_INCOMPLETE_CHATROOMS';
export const FETCH_COMPLETED_CHATROOMS = 'ADMIN/FETCH_COMPLETED_CHATROOMS';

export const FETCH_USERTYPES = 'ADMIN/FETCH_USERTYPES';
export const FETCH_USER_FEEDBACKS = 'ADMIN/FETCH_USER_FEEDBACKS';
export const RECEIVE_USERS = 'ADMIN/RECEIVE_USERS';
export const RECEIVE_INCOMPLETE_CHATROOMS = 'ADMIN/RECEIVE_INCOMPLETE_CHATROOMS';
export const RECEIVE_COMPLETED_CHATROOMS = 'ADMIN/RECEIVE_COMPLETED_CHATROOMS';
export const RECEIVE_USERTYPES = 'ADMIN/RECEIVE_USERTYPES';
export const RECEIVE_USER_FEEDBACKS = 'ADMIN/RECEIVE_USER_FEEDBACKS';
export const CONNECT_USERS_AND_USERTYPES = 'ADMIN/CONNECT_USER_AND_USERTYPES';
export const CONVERT_TO_MENTOR = 'ADMIN/CONVERT_TO_MENTOR';
export const CONVERT_TO_MENTOR_SUCCESS = 'ADMIN/CONVERT_TO_MENTOR_SUCCESS';
export const CONVERT_TO_MENTOR_FAILURE = 'ADMIN/CONVERT_TO_MENTOR_FAILURE';

export const UPDATE_LOGIN_PROVIDERS = 'ADMIN/UPDATE_LOGIN_PROVIDERS';

export const FETCH_UNCLAIMED_CHATROOMS = 'ADMIN/FETCH_UNCLAIMED_CHATROOMS';
export const RECEIVE_UNCLAIMED_CHATROOMS = 'ADMIN/RECEIVE_UNCLAIMED_CHATROOMS';
export const SAVE_SERVER_TOPICS = 'ADMIN/SAVE_SERVER_TOPICS';
export const SAVE_DEFAULT_LANGUAGE = 'ADMIN/SAVE_DEFAULT_LANGUAGE';
export const SAVE_DEFAULT_ROLE = 'ADMIN/SAVE_DEFAULT_ROLE';

export const SUBSCRIBE_MENTOR_ACCESS_REQUESTS = 'ADMIN/SUBSCRIBE_MENTOR_ACCESS_REQUESTS';
export const RECEIVE_MENTOR_ACCESS_REQUESTS = 'ADMIN/RECEIVE_MENTOR_ACCESS_REQUESTS';

export const UPLOAD_CUSTOM_LOGO = 'SERVER/UPLOAD_CUSTOM_LOGO';
export const UPLOAD_CUSTOM_LOGO_SUCCESS = 'SERVER/UPLOAD_CUSTOM_LOGO_SUCCESS';
export const UPLOAD_CUSTOM_LOGO_ERROR = 'SERVER/UPLOAD_CUSTOM_LOGO_ERROR';

export const FETCH_REPORTS = 'ADMIN/FETCH_REPORTS';
export const RECEIVE_REPORTS = 'ADMIN/RECEIVE_REPORTS';

export const FETCH_RATINGS = 'ADMIN/FETCH_RATINGS';
export const RECEIVE_RATINGS = 'ADMIN/RECEIVE_RATINGS';

export const FETCH_POPULAR_TAGS = 'ADMIN/FETCH_POPULAR_TAGS';
export const RECEIVE_POPULAR_TAGS = 'ADMIN/RECEIVE_POPULAR_TAGS';

export const SAVE_ANNOUNCEMENT = 'ADMIN/SAVE_ACCOUNCEMENT';
export const FETCH_ANNOUNCEMENTS = 'ADMIN/FETCH_ANNOUNCEMENTS';
export const FETCH_ANNOUNCEMENTS_SUCCESS = 'ADMIN/FETCH_ANNOUNCEMENTS_SUCCESS';
export const FETCH_ANNOUNCEMENTS_ERROR = 'ADMIN/FETCH_ANNOUNCEMENTS_ERROR';

export const SAVE_SERVICE_NAME = 'ADMIN/SAVE_SERVICE_NAME';

export const ADMIN_DELETE_USER = 'ADMIN/DELETE_USER';
export const ADMIN_DELETE_USER_SUCCESS = 'ADMIN/DELETE_USER_SUCCESS';
export const ADMIN_DELETE_USER_ERROR = 'ADMIN/DELETE_USER_ERROR';

export const SAVE_METERED_DISCUSSION_CONFIG = 'ADMIN/SAVE_METERED_DISCUSSION_CONFIG';
export const SAVE_METERED_DISCUSSION_CONFIG_SUCCESS =
  'ADMIN/SAVE_METERED_DISCUSSION_CONFIG_SUCCESS';
export const SAVE_METERED_DISCUSSION_CONFIG_ERROR =
  'ADMIN/SAVE_METERED_DISCUSSION_CONFIG_ERROR';

export const GET_METERED_DISCUSSION_CONFIG = 'ADMIN/GET_METERED_DISCUSSION_CONFIG';
export const GET_METERED_DISCUSSION_CONFIG_SUCCESS =
  'ADMIN/GET_METERED_DISCUSSION_CONFIG_SUCCESS';
export const GET_METERED_DISCUSSION_CONFIG_ERROR =
  'ADMIN/GET_METERED_DISCUSSION_CONFIG_ERROR';

// API KEYS ACTION TYPES

export const CREATE_API_KEY = 'ADMIN/CREATE_API_KEY';
export const CREATE_API_KEY_SUCCESS = 'ADMIN/CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_ERROR = 'ADMIN/CREATE_API_KEY_ERROR';

export const LIST_API_KEYS = 'ADMIN/LIST_API_KEYS';
export const LIST_API_KEYS_SUCCESS = 'ADMIN/LIST_API_KEYS_SUCCESS';
export const LIST_API_KEYS_ERROR = 'ADMIN/LIST_API_KEYS_ERROR';

export const DELETE_API_KEY = 'ADMIN/DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS = 'ADMIN/DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_ERROR = 'ADMIN/DELETE_API_KEY_ERROR';
