import { combineReducers } from 'redux';

import { PendingReview } from '../../services/db';
import { AppState } from '../rootReducer';
import { APIState } from '../utils';
import { ById } from '../Admin';

import {
  REVIEWS_UPDATED,
  PROMPT_REVIEW,
  REVIEW_SEEN,
  RATING_SEND_ERROR,
  RATING_SEND,
  RATING_SEND_SUCCESS,
  TAGS_SEARCH,
  TAGS_SEARCH_SUCCESS,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_ERROR,
} from './actionTypes';

import { RootAction } from '..';
export type PendingReviews = { [id: string]: PendingReview };
export interface ReviewState {
  pending?: PendingReview[];
  lastReviewed: number;
  promptedReview?: PendingReview;
}

const initialState: ReviewState = {
  pending: [],
  lastReviewed: 0,
};

export const reviewsNotificationsReducer = (
  state = initialState,
  action: RootAction,
): ReviewState => {
  switch (action.type) {
    case REVIEWS_UPDATED:
      return { ...state, ...action.payload };
    case PROMPT_REVIEW:
      return { ...state, promptedReview: action.payload };
    case REVIEW_SEEN:
      return { ...state, promptedReview: undefined };
    default:
      return state;
  }
};

type RatingState = APIState;
export const ratingReducer = (
  state = {
    isLoading: false,
  },
  action: RootAction,
): RatingState => {
  switch (action.type) {
    case RATING_SEND_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    case RATING_SEND:
      return { ...state, isLoading: true };
    case RATING_SEND_SUCCESS:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

type SuggesterState = APIState & {
  values: string[];
};
export const suggesterReducer = (
  state = {
    isLoading: false,
    values: [] as string[],
  },
  action: RootAction,
): SuggesterState => {
  switch (action.type) {
    case TAGS_SEARCH:
      return { ...state, isLoading: true };
    case TAGS_SEARCH_SUCCESS:
      return { ...state, values: action.payload.data, isLoading: false };
    default:
      return state;
  }
};

export type NotificationTemplate = {
  id: string;
  title: string;
  description: string;
  questions: Question[];
};

type QuestionType = 'stars' | 'slider' | 'yesno' | 'tags';

export type Option = {
  value: string | number;
  displayName: string;
};

export type Question = {
  type: QuestionType;
  text: string;
  comment?: boolean;
  commentPlaceholder?: string;
  options: Option[];
};

type TemplateState = APIState & {
  byId: ById<NotificationTemplate>;
};
export const templateReducer = (
  state = {
    isLoading: false,
    byId: {},
  },
  action: RootAction,
): TemplateState => {
  switch (action.type) {
    case FETCH_TEMPLATE:
      return { ...state, isLoading: true };
    case FETCH_TEMPLATE_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case FETCH_TEMPLATE_SUCCESS: {
      const { template } = action.payload;
      return {
        ...state,
        byId: { ...state.byId, [template.id]: template },
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export const reviewsReducer = combineReducers({
  notifications: reviewsNotificationsReducer,
  ratings: ratingReducer,
  suggested: suggesterReducer,
  templates: templateReducer,
});

// selectors
export const getPromptedReview = (state: AppState) =>
  state.reviews.notifications.promptedReview;

export const getRatings = (state: AppState) => state.reviews.ratings;
export const getSuggestedValues = (state: AppState) => state.reviews.suggested;
export const templateById = (
  state: AppState,
  id?: string,
): { isLoading: boolean; error?: string; template?: NotificationTemplate } => {
  const { templates } = state.reviews;
  if (!id) return { isLoading: templates.isLoading };
  return {
    isLoading: templates.isLoading,
    template: templates.byId[id],
    error: templates.error,
  };
};
