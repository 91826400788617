import styled from 'styled-components';

const MessageBubble = styled.div`
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin: 0;
  max-width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
`;

export default MessageBubble;
