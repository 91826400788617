import styled from 'styled-components';

export const EllipsisOverflowP = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const EllipsisOverflowH4 = styled.h4`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
