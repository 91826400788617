import React from 'react';
export interface HomeProps {
  color: string;
  size: number;
}
const Home = (props: HomeProps) => {
  const { color, size = 24 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="24 / basic / settings">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3202 26.1213L26.0917 23.3498L25.1156 19.8037L25.5408 18.7881L28.75 16.9773V13.0581L25.5499 11.2397L25.1318 10.2258L26.1202 6.67794L23.3468 3.90854L19.8004 4.88435L18.787 4.45947L16.976 1.25H13.0568L11.2384 4.45005L10.2248 4.86808L6.67748 3.87851L3.90838 6.64761L4.88435 10.1946L4.45947 11.2079L1.25 13.0189V16.937L4.44717 18.7595L4.86565 19.7742L3.87746 23.3215L6.64761 26.0916L10.1946 25.1157L11.208 25.5406L13.0191 28.7487H16.9373L18.7584 25.5515L19.7729 25.1331L23.3202 26.1213ZM23.5658 17.032L22.4761 19.6347L23.2952 22.6107L22.5847 23.3212L19.615 22.4939L17.0112 23.5678L15.4842 26.2487H14.4786L12.9642 23.5661L10.3644 22.476L7.38682 23.2953L6.67752 22.586L7.5048 19.6163L6.43101 17.0127L3.74999 15.4845V14.4788L6.43386 12.9644L7.52398 10.3645L6.70468 7.38684L7.4125 6.67902L10.3822 7.50745L12.9869 6.4332L14.5116 3.75001H15.5161L17.0306 6.43389L19.6305 7.52401L22.6087 6.70454L23.3198 7.41459L22.4926 10.3837L23.5668 12.9882L26.25 14.5129V15.5174L23.5658 17.032ZM15 20C12.2386 20 10 17.7614 10 15C10 12.2386 12.2386 9.99999 15 9.99999C17.7614 9.99999 20 12.2386 20 15C20 17.7614 17.7614 20 15 20ZM17.5 15C17.5 16.3807 16.3807 17.5 15 17.5C13.6193 17.5 12.5 16.3807 12.5 15C12.5 13.6193 13.6193 12.5 15 12.5C16.3807 12.5 17.5 13.6193 17.5 15Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Home;
