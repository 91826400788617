import React from 'react';
export default () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9496 10.4095C19.9496 9.71776 19.8935 9.0223 19.7739 8.3418H10.1758V12.2603H15.6722C15.4441 13.5241 14.7112 14.6421 13.6381 15.3525V17.895H16.9173C18.8429 16.1227 19.9496 13.5054 19.9496 10.4095Z"
        fill="#4285F4"
      />
      <path
        d="M10.1773 20.3528C12.9218 20.3528 15.2362 19.4517 16.9225 17.8963L13.6434 15.3537C12.7311 15.9744 11.5533 16.3259 10.1811 16.3259C7.52635 16.3259 5.27544 14.5349 4.46781 12.127H1.08398V14.748C2.81142 18.1842 6.32985 20.3528 10.1773 20.3528Z"
        fill="#34A853"
      />
      <path
        d="M4.46254 12.1265C4.03629 10.8627 4.03629 9.49423 4.46254 8.23044V5.60938H1.08245C-0.360817 8.48469 -0.360817 11.8723 1.08245 14.7476L4.46254 12.1265Z"
        fill="#FBBC04"
      />
      <path
        d="M10.1773 4.02763C11.6281 4.00519 13.0302 4.55109 14.0809 5.55315L16.9861 2.64792C15.1465 0.920489 12.7049 -0.0292266 10.1773 0.000685734C6.32985 0.000685734 2.81142 2.16933 1.08398 5.60924L4.46407 8.23031C5.26797 5.81863 7.52261 4.02763 10.1773 4.02763Z"
        fill="#EA4335"
      />
    </svg>
  );
};
