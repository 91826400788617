import { MentorPreferences } from '../../services/db';
import { AppState } from '../rootReducer';

import { SET_USER_TYPE } from './actionTypes';

import { RootAction } from '..';

export interface UserTypeState {
  id: string;
  pushNotificationToken?: string;
  uid: string;
  isAvailable: boolean;
  mentorPreferences?: MentorPreferences;
  nickName?: string;
  lastSeenInfoBanner?: Date;
}

export const userTypeReducer = (
  state = {},
  action: RootAction,
): Partial<UserTypeState> => {
  switch (action.type) {
    case SET_USER_TYPE:
      return action.payload;
    default:
      return state;
  }
};

// selectors
export const getIsAvailable = (state: AppState) => state.userType.isAvailable;
export const selectMentorPreferences = (state: AppState) =>
  state.userType.mentorPreferences;

export const getNickName = (state: AppState) => state.userType.nickName;
export const getLastSeenInfoBanner = (state: AppState) =>
  state.userType.lastSeenInfoBanner;
