import React from 'react';
export default () => {
  return (
    <svg
      width="29"
      height="31"
      viewBox="0 0 29 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.7793 13.8126C28.7793 14.1874 28.633 14.5348 28.3679 14.7999L14.8113 28.3657C13.2573 29.9197 11.1913 30.7698 8.99738 30.7698C6.80346 30.7698 4.73751 29.9105 3.18348 28.3657C1.62944 26.8116 0.779297 24.7548 0.779297 22.5517C0.779297 20.3578 1.62944 18.2919 3.18348 16.7378L18.1022 1.81913C19.2814 0.649037 20.8355 0 22.4992 0C24.1629 0 25.7169 0.649037 26.8962 1.81913C29.3186 4.24159 29.3186 8.18152 26.8962 10.604L13.6869 23.8133C12.8825 24.6177 11.8038 25.0656 10.652 25.0656C9.5093 25.0656 8.43062 24.6177 7.61704 23.8133C6.8126 22.9997 6.36467 21.9301 6.36467 20.7783C6.36467 19.6357 6.8126 18.557 7.61704 17.7434L18.7878 6.58178C19.0529 6.31668 19.4003 6.17042 19.7751 6.17042C20.1498 6.17042 20.4972 6.31668 20.7623 6.58178C21.3108 7.13026 21.3108 8.01698 20.7623 8.55632L9.60071 19.7271C9.01567 20.3121 9.01567 21.2537 9.60071 21.8387C9.8841 22.1221 10.2589 22.2775 10.652 22.2775C11.0542 22.2775 11.429 22.1221 11.7032 21.8387L24.9125 8.62945C26.2471 7.29481 26.2471 5.12831 24.9125 3.80281C24.2726 3.16291 23.4133 2.8064 22.4992 2.8064C21.585 2.8064 20.7258 3.16291 20.0859 3.80281L5.15801 18.7215C3.04636 20.8332 3.04636 24.2795 5.15801 26.3911C6.18184 27.415 7.54391 27.9726 8.99738 27.9726C10.4417 27.9726 11.8038 27.4058 12.8276 26.382L26.3934 12.8162C26.6585 12.5511 27.0059 12.4048 27.3807 12.4048C27.7555 12.4048 28.1028 12.5511 28.3679 12.8162C28.633 13.0904 28.7793 13.4378 28.7793 13.8126Z"
        fill="currentColor"
      />
    </svg>
  );
};
