import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ProfileCircle from '../_atoms/ProfileCircle';

export default ({ comments }: { comments: string[] }) => {
  const { t } = useTranslation('Admin');

  return (
    <>
      {comments.map((comment, i) => (
        <UserFeedbackItem key={i}>
          <div
            style={{
              flex: 0,
            }}
          >
            <ProfileCircle width={40} />
          </div>
          <TextContainer>
            <h3>{t('Anonymous')}</h3>
            <p>{comment}</p>
          </TextContainer>
        </UserFeedbackItem>
      ))}
    </>
  );
};

const UserFeedbackItem = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  &:first-child {
    margin-right: 20px;
  }
`;

const TextContainer = styled.div`
  margin-left: 20px;
  & h3 {
    color: #a5a5a5;
  }

  & p {
    margin: 0;
  }
`;
