import { createAction } from 'typesafe-actions';

import { MatchMakingWithChatRoom } from '../../services/backendService';

import * as ActionTypes from './actionTypes';

export const requestQueue = createAction(ActionTypes.QUEUE_REQUESTED);
export const receiveQueueError = createAction(ActionTypes.QUEUE_RECEIVED_ERROR);

export const receiveQueue = createAction(
  ActionTypes.QUEUE_RECEIVED,
  action => (items: MatchMakingWithChatRoom[]) => action({ items }),
);

export const claimQueueItem = createAction(
  ActionTypes.QUEUE_ITEM_CLAIMED,
  action => (sessionId: string) => action({ sessionId }),
);
export const claimQueueItemError = createAction(
  ActionTypes.QUEUE_ITEM_CLAIMED_ERROR,
  action => (message: string) => action({ message }),
);
export const claimQueueItemReset = createAction(ActionTypes.QUEUE_ITEM_CLAIMED_RESET);

export const claimQueueItemSuccess = createAction(
  ActionTypes.QUEUE_ITEM_CLAIMED_SUCCESS,
  action => (chatRoomId: string) => action({ chatRoomId }),
);

export const claimDiscussion = createAction(
  ActionTypes.DISCUSSION_CLAIMED,
  action => (chatRoomId: string) => action({ chatRoomId }),
);

export const claimDiscussionSuccess = createAction(
  ActionTypes.DISCUSSION_CLAIMED_SUCCESS,
  action => (chatRoomId: string) => action({ chatRoomId }),
);

export const claimDiscussionError = createAction(
  ActionTypes.DISCUSSION_CLAIMED_ERROR,
  action => (message: string) => action({ message }),
);
export const claimDiscussionReset = createAction(ActionTypes.DISCUSSION_CLAIMED_RESET);
