import React from 'react';

export default () => (
  <svg
    width="25"
    height="32"
    viewBox="0 0 25 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5469 0.921875C15.9531 0.328125 15.1562 0 14.3281 0H3.125C1.40625 0 0 1.40625 0 3.125V28.125C0 29.8438 1.39062 31.25 3.10937 31.25H21.875C23.5938 31.25 25 29.8438 25 28.125V10.6719C25 9.84375 24.6719 9.04688 24.0781 8.46875L16.5469 0.921875ZM17.1875 25H7.8125C6.95312 25 6.25 24.2969 6.25 23.4375C6.25 22.5781 6.95312 21.875 7.8125 21.875H17.1875C18.0469 21.875 18.75 22.5781 18.75 23.4375C18.75 24.2969 18.0469 25 17.1875 25ZM17.1875 18.75H7.8125C6.95312 18.75 6.25 18.0469 6.25 17.1875C6.25 16.3281 6.95312 15.625 7.8125 15.625H17.1875C18.0469 15.625 18.75 16.3281 18.75 17.1875C18.75 18.0469 18.0469 18.75 17.1875 18.75ZM14.0625 9.375V2.34375L22.6562 10.9375H15.625C14.7656 10.9375 14.0625 10.2344 14.0625 9.375Z"
      fill="currentColor"
    />
  </svg>
);
