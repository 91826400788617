import React, { useEffect, useState } from 'react';
import { Col, Row, Result } from 'antd';
import { useDispatch } from 'react-redux';

import {
  requestStripeUrl,
  getOAuthState,
  getSavedAccount,
  getSavedAccountState,
  availableSubscriptionsSubscribe,
  getAllAvailableSubscriptions,
  saveAvailableSubscription,
} from '../../modules';
import { useTypedSelector } from '../../hooks';
import { AvailableSubscriptionRef } from '../../services/database/types';
import SnapmentorProductForm from '../_molecules/SnapmentorProductForm';
import { SectionTitle } from '../_atoms/SectionTitle';
import { StyledButton } from '../../styled';

export default () => {
  const { isLoading: isLoadingUrl, url } = useTypedSelector(getOAuthState);
  const {
    isLoading: isLoadingSavedAccount,
    data: savedAccount,
    error: savedAccountError,
  } = useTypedSelector(getSavedAccountState);

  const { data, isLoading } = useTypedSelector(getAllAvailableSubscriptions);

  const handleEdit = (availableSubscription: AvailableSubscriptionRef) => {
    dispatch(saveAvailableSubscription(availableSubscription));
    setNewProduct(undefined);
  };

  const [newProduct, setNewProduct] = useState<AvailableSubscriptionRef>();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestStripeUrl());
    dispatch(getSavedAccount());
  }, [dispatch]);

  useEffect(() => {
    if (!data && !isLoading) {
      dispatch(availableSubscriptionsSubscribe());
    }
  }, [data, isLoading, dispatch]);

  return (
    <Row style={{ padding: '40px 60px', minHeight: '100%' }} gutter={[40, 40]}>
      <Col span={12}>
        <SectionTitle>Setup available subscriptions from stripe</SectionTitle>
        {data &&
          Object.values(data).map(data => (
            <SnapmentorProductForm
              key={data.productId}
              product={data}
              onEdit={handleEdit}
            />
          ))}
        {!newProduct && (
          <StyledButton
            style={{ marginTop: 10, width: 200 }}
            onClick={() => setNewProduct({ priceIds: [], productId: '' })}
          >
            Add product
          </StyledButton>
        )}
        {newProduct && <SnapmentorProductForm product={newProduct} onEdit={handleEdit} />}
      </Col>
      <Col span={12}>
        {url && !savedAccount && !isLoadingSavedAccount && (
          <StyledButton
            style={{ marginTop: 10, width: 400 }}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              window.location = url;
            }}
          >
            Click to connect your stripe account
          </StyledButton>
        )}
        {isLoadingUrl && <p>Loading...</p>}

        {savedAccount && (
          <Result
            style={{ padding: 10 }}
            status="success"
            title="Your stripe account is connected"
          />
        )}
        {isLoadingSavedAccount && <p>loading...</p>}
        {savedAccountError && <p>{savedAccountError}</p>}
        <div>
          <p style={{ marginTop: 40 }}>
            In case you want to remove an available subscription, please contact:
            <b> jens.seip@snapmentor.no</b>
          </p>
        </div>
      </Col>
    </Row>
  );
};
