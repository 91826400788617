import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { useTranslation } from 'react-i18next';

import { getLoginProviders, fallbackProviders, LoginProviders } from '../../../modules';
import { useTypedSelector } from '../../../hooks';
import { StyledButton } from '../../../styled';

const Provider = ({
  name,
  value,
  onChange,
}: {
  name: string;
  value: boolean;
  onChange: SwitchChangeEventHandler;
}) => {
  const { t } = useTranslation('admin');
  return (
    <FlexContainer>
      <span>{t(name)}:</span>
      <Switch checked={value} onChange={onChange} />
    </FlexContainer>
  );
};
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 10%;
  padding-right: 50%;
  span {
    text-transform: capitalize;
    font-size: 18px;
    color: #6c6c6c;
  }
`;

type Props = {
  onSave: (loginProviders: LoginProviders) => void;
};
export default (props: Props) => {
  const loginProviders = useTypedSelector(getLoginProviders);
  const { t } = useTranslation('Admin');
  const providers = loginProviders || fallbackProviders;
  const [localProviders, setLocalProviders] = useState(providers);
  useEffect(() => {
    setLocalProviders(providers);
  }, [providers]);
  const onSave = () => {
    props.onSave(localProviders);
  };
  return (
    <Container>
      {Object.entries(localProviders).map(([name, value]) => (
        <Provider
          key={name}
          name={name}
          value={value}
          onChange={checked => setLocalProviders({ ...localProviders, [name]: checked })}
        />
      ))}
      <StyledButton style={{ marginTop: 20, width: 100 }} onClick={onSave}>
        {t('Save')}
      </StyledButton>
    </Container>
  );
};

const Container = styled.div``;
