import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';

import { useTypedSelector } from '../../hooks';
import { getTopics, Topic, ById } from '../../modules';
import theme from '../../theme';

const CheckboxGroup = Checkbox.Group;

export type TrendFilter = {
  value: string;
  fill: string;
  label: string;
};

const selectAll = (topics: ById<Topic> | undefined): TrendFilter[] => {
  if (!topics) return [] as TrendFilter[];
  return Object.values(topics).map(topic => ({
    value: topic.id,
    fill: theme.colors.chart.p95,
    label: topic.name,
  }));
};
type Props = {
  onFilterChange: (keys: TrendFilter[]) => void;
};

export default (props: Props) => {
  const { onFilterChange } = props;
  const { t } = useTranslation('Admin');
  const topics = useTypedSelector(getTopics);

  const [options, setOptions] = useState<TrendFilter[]>([]);
  const [selected, setSelected] = useState<TrendFilter[]>([]);
  const [checkAll, setCheckAll] = useState(true);
  const [indeterminate, setIndeterminate] = useState(true);

  useEffect(() => {
    const selectedTopics = topics ? selectAll(topics) : [];
    setOptions(selectedTopics);
    onFilterChange(selectedTopics);
  }, [topics, onFilterChange]);

  const handleChange = (trendKeys: CheckboxValueType[]) => {
    const newTrends = options.filter(t => trendKeys.includes(t.value));
    setSelected(newTrends);
    setCheckAll(newTrends.length === options.length);
    setIndeterminate(!!newTrends.length && newTrends.length < options.length);
    onFilterChange(newTrends);
  };

  const handleCheckAll = (checked: boolean) => {
    setIndeterminate(false);
    const selected = checked ? selectAll(topics) : [];
    setSelected(selected);
    setCheckAll(checked);
    onFilterChange(selected);
  };

  return (
    <CheckboxContainer>
      <Row>
        <Col span={24}>
          <Checkbox
            indeterminate={indeterminate}
            onChange={e => handleCheckAll(e.target.checked)}
            checked={checkAll}
          >
            {t('Check all')}
          </Checkbox>
        </Col>
        <CheckboxGroup
          style={{ width: '100%' }}
          value={selected.map(t => t.value)}
          onChange={e => handleChange(e)}
        >
          <Row>
            {options.map(option => (
              <Col span={6}>
                <Checkbox value={option.value}>{option.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </CheckboxGroup>
      </Row>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 0 20px;
`;
