import { createAction } from 'typesafe-actions';

import * as ActionTypes from './actionTypes';

export const receiveBlob = createAction(
  ActionTypes.BLOB_RECEIVED,
  action => (blob: Blob, id: string) => action({ blob, id }),
);

export const requestBlob = createAction(
  ActionTypes.BLOB_REQUESTED,
  action => (chatRoomId: string, id: string) => action({ id, chatRoomId }),
);
export const requestCustomLogo = createAction(ActionTypes.CUSTOM_LOGO_REQUESTED);

export const receiveCustomLogo = createAction(
  ActionTypes.CUSTOM_LOGO_RECEIVED,
  action => (blob: Blob) => action({ blob }),
);
