export const config = {
  firebase: {
    apiKey: 'AIzaSyBXCYWsZ_lsaw4gsuLRknu7w4NoiWuxU1U',
    authDomain: 'app.snapmentor.no',
    databaseURL: 'https://snapmentor-prod.firebaseio.com',
    projectId: 'snapmentor-prod',
    storageBucket: 'snapmentor-prod.appspot.com',
    messagingSenderId: '139743671678',
    appId: '1:139743671678:web:c97ee1cbfab43887',
    measurementId: 'G-V5F9PZCX67',
  },
  cloudMessaging: {
    vapidKey:
      'BNg04rRat94mcemMQgPwQe35EaJvOf9LkkaKqNoUa3Yyx4c6WEKShKb6JudmK38VLpmubwKfe781BRYSRnezhrg',
  },
  backend: {
    url: 'https://europe-west1-snapmentor-prod.cloudfunctions.net',
    externalApiUrl: 'https://api.snapmentor.no',
  },
  sentry: {
    dsn: 'https://a46739c4f3e147b6a07b3f8636ee043a@sentry.io/1757705',
  },
  clientIds: {
    GOOGLE: '139743671678-dsk490voc4pblen8jn4d9rtecbq94ndt.apps.googleusercontent.com',
    FEIDE: '11e50bf0-17fb-47ab-860f-ab1022c545be',
    VIPPS: '0eb6b0d4-69fb-4b81-81ce-31f91ed76d6a',
  },
  appVersion: process.env.REACT_APP_VERSION,
  stripe: {
    publishableKey: 'pk_live_KqY7Og9T3kZdWfnNzHf2FYEK00b4VMogqD',
    clientId: 'ca_H8hlynf8T7uBN0uJJyKXHkmAdM7qaPeP',
  },
  mixpanel: {
    token: 'b46a26a9b370ce2c5bdf57ee1092b62b',
  },
  fallbackTenant: process.env.FALLBACK_TENANT || 'metered-dev',
  azure: {
    tenant: 'snapmentorprod.onmicrosoft.com',
    tenantName: 'snapmentorprod',
    clientId: '47e477d5-a501-44cd-8599-817b011b2a81',
    fallbackPolicy: 'B2C_1A_signup_signin',
    scopes: [
      'https://snapmentorprod.onmicrosoft.com/47e477d5-a501-44cd-8599-817b011b2a81/User.Read',
      'offline_access',
    ],
  },
};
