import React from 'react';
import styled from 'styled-components';
import { VariableSizeList as VirtualList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { FinishedChatRoom, UIDiscussion } from '../../../modules';
import { ChatRoomListItem } from '../ChatRoomListItem';
import { useWindowSize } from '../../../hooks';

export const ChatContext = React.createContext<{
  setSize: (index: number, size: number) => void;
  windowWidth: number;
}>({ setSize: () => null, windowWidth: 0 });

type Props = {
  items: FinishedChatRoom[];
  selected?: UIDiscussion;
  onChatRoomSelected: (chatRoom: UIDiscussion) => void;
  emptyState: React.ReactNode;
};
const DynamicVirtualList = (props: Props) => {
  const { items, onChatRoomSelected, emptyState } = props;
  const listRef = React.useRef<VirtualList>(null);
  const sizeMap = React.useRef<{ [index: number]: number }>({});
  const setSize = React.useCallback((index, size) => {
    const oldSize = sizeMap.current[index];
    sizeMap.current = { ...sizeMap.current, [index]: size };
    if (oldSize !== size) {
      listRef.current?.resetAfterIndex(index, true);
    }
  }, []);
  const getSize = React.useCallback(index => {
    const size = sizeMap.current[index];
    return size || 70;
  }, []);

  const [windowWidth] = useWindowSize();

  if (items.length === 0) return <>{emptyState}</>;
  return (
    <ChatContext.Provider value={{ setSize, windowWidth }}>
      <AutoSizer
        style={{
          listStyleType: 'none',
          //Make sure AutoSizer is rendered without any uneccessary container divs. Safari doesnt like it.
        }}
      >
        {({ height, width }) => (
          <VirtualList
            height={height}
            itemCount={items.length}
            itemSize={getSize}
            width={width}
            ref={listRef}
          >
            {({ index, style }) => {
              const item = items[index];
              return (
                <ListItem style={style} onClick={() => onChatRoomSelected(item)}>
                  <ChatRoomListItem {...item} index={index} />
                </ListItem>
              );
            }}
          </VirtualList>
        )}
      </AutoSizer>
    </ChatContext.Provider>
  );
};

const ListItem = styled.li`
  width: 100%;
  margin: 10px 0;
  padding: 10px 20px;
`;

export default DynamicVirtualList;
