import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

import { SubTopic } from '../../../modules';

type Props = SubTopic & {
  onChange: (value: string) => void;
};

export const SubTopicInput: React.FC<Props> = props => {
  const { name, onChange } = props;

  const [localName, setLocalName] = useState<string>(name || '');

  return (
    <Container>
      <Input
        placeholder="Name"
        value={localName}
        onChange={e => setLocalName(e.target.value)}
        onBlur={() => onChange(localName)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  .ant-input:hover,
  .ant-input:focus {
    border-color: black;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  }
`;
