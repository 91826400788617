import React from 'react';
export interface Props {
  color: string;
  size?: number;
}
const ChevronRight = (props: Props) => {
  const { color, size = 24 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5859 12L7.29297 4.70712L8.70718 3.29291L17.4143 12L8.70718 20.7071L7.29297 19.2929L14.5859 12Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronRight;
