import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../styled';

const { Option } = Select;
type Props = {
  onSave: (language: string) => void;
  selectedLang?: string;
};

export const DefaultLanguage: React.FC<Props> = props => {
  const { onSave, selectedLang } = props;
  const { t } = useTranslation('Admin');
  const [lang, setLang] = useState<string>('en');

  useEffect(() => {
    if (selectedLang) setLang(selectedLang);
  }, [selectedLang, setLang]);
  return (
    <Container>
      <SelectWrapper>
        <Select value={lang} onChange={(lang: string) => setLang(lang)}>
          <Option value="en">{t('English')}</Option>
          <Option value="nb-NO">{t('Norsk bokmål')}</Option>
        </Select>
      </SelectWrapper>
      <StyledButton style={{ width: 100 }} onClick={() => onSave(lang)}>
        {t('Save')}
      </StyledButton>
    </Container>
  );
};

const SelectWrapper = styled.div`
  width: 100%;
  .ant-select {
    width: 100%;
  }
  & div.ant-select-selector {
    border-radius: 25px !important;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin: 10px 0;
  }
`;
