import { LinearData } from './utils';

export const percentile = (data: LinearData[], percentileNum: number) => {
  if (data.length === 0) return 0;
  if (percentileNum > 100 || percentileNum < 0) return null;
  const percentile = percentileNum / 100;

  const sorted = [...data].sort((a, b) => a.y - b.y); // asc

  const index = percentile * data.length;
  if (index % 1 !== 0) {
    const localIndex = Number(index.toFixed(0));
    return sorted[localIndex - 1].y;
  } else {
    return (sorted[index - 1].y + sorted[index].y) / 2;
  }
};

export const calculatePercentile = (values: number[], percentileNum: number) => {
  if (percentileNum > 100 || percentileNum < 0) return -1;
  if (values.length === 0) return 0;
  const percentile = percentileNum / 100;

  const sorted = [...values].sort((a, b) => a - b); // asc

  const index = percentile * values.length;
  if (index % 1 !== 0) {
    const localIndex = Math.ceil(index);
    return sorted[localIndex - 1];
  } else {
    return (sorted[index - 1] + sorted[index]) / 2;
  }
};
