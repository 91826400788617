import { combineReducers } from 'redux';
import { Stripe } from '@stripe/stripe-js';

import { AppState } from '../rootReducer';
import { APIState, DEFAULT_API_STATE } from '../utils';
import {
  PaymentsConfirmRegistrationType,
  APIPaymentCustomer,
  AvailableSubscriptionDTO,
} from '../../services/backendService';
import {
  PaymentSubscription,
  AvailableSubscriptionRef,
} from '../../services/database/types';
import { ById } from '../Admin';

import {
  STRIPE_REDIRECT_URL_REQUESTED,
  STRIPE_REDIRECT_URL_ERROR,
  STRIPE_REDIRECT_URL_SUCCESS,
  PAYMENTS_CONFIRM,
  PAYMENTS_CONFIRM_ERROR,
  PAYMENTS_CONFIRM_SUCCESS,
  PAYMENTS_SAVED_ACCOUNT,
  PAYMENTS_SAVED_ACCOUNT_ERROR,
  PAYMENTS_SAVED_ACCOUNT_SUCCESS,
  PAYMENTS_CREATE_CUSTOMER_ERROR,
  PAYMENTS_CREATE_CUSTOMER_SUCCESS,
  PAYMENTS_CREATE_CUSTOMER,
  INIT_STRIPE,
  INIT_STRIPE_DONE,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PLAN_SUCCESS,
  SUBSCRIPTION_PLAN_ERROR,
  PAYMENT_CUSTOMER,
  PAYMENT_CUSTOMER_SUCCESS,
  PAYMENT_CUSTOMER_ERROR,
  SET_CARD,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_ERROR,
  REACTIVATE_SUBSCRIPTION,
  UPDATE_PAYMENT_DETAILS,
  UPDATE_PAYMENT_DETAILS_SUCCESS,
  UPDATE_PAYMENT_DETAILS_ERROR,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_ERROR,
  FETCH_PLANS,
  SELECT_PLAN_FOR_CHECKOUT,
  CAN_CREATE_DISCUSSION,
  CAN_CREATE_DISCUSSION_SUCCESS,
  CAN_CREATE_DISCUSSION_ERROR,
  AVAILABLE_SUBSCRIPTIONS_RECEIVE,
  AVAILABLE_SUBSCRIPTIONS_SUBSCRIBE,
  SAVE_AVAILABLE_SUBSCRIPTION,
  SAVE_AVAILABLE_SUBSCRIPTION_SUCCESS,
  AVAILABLE_SUBSCRIPTIONS_RECEIVE_ERROR,
  SAVE_AVAILABLE_SUBSCRIPTION_ERROR,
} from './actionTypes';

import { RootAction } from '..';

type OAuthState = APIState & {
  url?: string;
};

export const oAuthReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): OAuthState => {
  switch (action.type) {
    case STRIPE_REDIRECT_URL_REQUESTED:
      return { ...state, isLoading: true };
    case STRIPE_REDIRECT_URL_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case STRIPE_REDIRECT_URL_SUCCESS:
      return { ...state, isLoading: false, url: action.payload.url };
    default:
      return state;
  }
};

type ConfirmState = APIState & {
  data?: PaymentsConfirmRegistrationType;
};

export const confirmReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): ConfirmState => {
  switch (action.type) {
    case PAYMENTS_CONFIRM:
      return { ...state, isLoading: true };
    case PAYMENTS_CONFIRM_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case PAYMENTS_CONFIRM_SUCCESS:
      return { ...state, isLoading: false, data: action.payload.data };
    default:
      return state;
  }
};

type SavedAccountState = APIState & {
  data?: { id: string; terms?: string };
};

export const savedAccountReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): SavedAccountState => {
  switch (action.type) {
    case PAYMENTS_SAVED_ACCOUNT:
      return { ...state, isLoading: true };
    case PAYMENTS_SAVED_ACCOUNT_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case PAYMENTS_SAVED_ACCOUNT_SUCCESS:
      return { ...state, isLoading: false, data: action.payload.data };
    default:
      return state;
  }
};

type CustomerState = APIState & {
  success?: boolean;
};

export const customerReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): CustomerState => {
  switch (action.type) {
    case PAYMENTS_CREATE_CUSTOMER:
      return { ...state, isLoading: true };
    case PAYMENTS_CREATE_CUSTOMER_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case PAYMENTS_CREATE_CUSTOMER_SUCCESS:
      return { ...state, isLoading: false, success: action.payload.success };
    default:
      return state;
  }
};

type StripeState = APIState & {
  stripe?: Stripe;
};

export const stripeReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): StripeState => {
  switch (action.type) {
    case INIT_STRIPE:
      return { ...state, isLoading: true };
    case INIT_STRIPE_DONE:
      return { ...state, isLoading: false, stripe: action.payload.stripe };
    default:
      return state;
  }
};

type SubscriptionState = APIState & {
  subscription?: PaymentSubscription;
};

export const subscriptionReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): SubscriptionState => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN:
      return { ...state, isLoading: true };
    case SUBSCRIPTION_PLAN_SUCCESS:
      return { ...state, isLoading: false, subscription: action.payload.subscription };
    case SUBSCRIPTION_PLAN_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    default:
      return state;
  }
};

type PlansState = APIState & {
  plans?: AvailableSubscriptionDTO[];
};

export const plansReducer = (
  state: PlansState = DEFAULT_API_STATE,
  action: RootAction,
): PlansState => {
  switch (action.type) {
    case FETCH_PLANS:
      return { ...state, isLoading: true };
    case FETCH_PLANS_SUCCESS:
      return { ...state, isLoading: false, plans: action.payload.plans };
    case FETCH_PLANS_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    default:
      return state;
  }
};

type CanCreateDiscussionState = APIState & {
  ok?: boolean;
};

export const canCreateDiscussionReducer = (
  state: CanCreateDiscussionState = DEFAULT_API_STATE,
  action: RootAction,
): CanCreateDiscussionState => {
  switch (action.type) {
    case CAN_CREATE_DISCUSSION:
      return { ...state, isLoading: true, error: undefined };
    case CAN_CREATE_DISCUSSION_SUCCESS:
      return { ...state, isLoading: false, ok: action.payload.ok, error: undefined };
    case CAN_CREATE_DISCUSSION_ERROR:
      return { ...state, isLoading: false, ok: undefined, error: action.payload.error };
    default:
      return state;
  }
};

type PlanCheckoutState = {
  selectedPlanId?: string;
};

export const planCheckoutReducer = (
  state: PlanCheckoutState = {},
  action: RootAction,
): PlanCheckoutState => {
  switch (action.type) {
    case SELECT_PLAN_FOR_CHECKOUT:
      return { ...state, selectedPlanId: action.payload.planId };
    default:
      return state;
  }
};

type AvailableSubscriptionsState = APIState & {
  data?: ById<AvailableSubscriptionRef>;
};

export const availableSubscriptionsReducer = (
  state: AvailableSubscriptionsState = DEFAULT_API_STATE,
  action: RootAction,
): AvailableSubscriptionsState => {
  switch (action.type) {
    case AVAILABLE_SUBSCRIPTIONS_RECEIVE:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case AVAILABLE_SUBSCRIPTIONS_SUBSCRIBE:
      return { ...state, isLoading: true };
    case SAVE_AVAILABLE_SUBSCRIPTION:
    case SAVE_AVAILABLE_SUBSCRIPTION_SUCCESS: {
      const data = state.data ? { ...state.data } : {};
      return {
        ...state,
        isLoading: false,
        data: {
          ...data,
          [action.payload.data.productId]: action.payload.data,
        },
      };
    }
    case AVAILABLE_SUBSCRIPTIONS_RECEIVE_ERROR:
    case SAVE_AVAILABLE_SUBSCRIPTION_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    default:
      return state;
  }
};

type PaymentCustomerState = APIState & {
  customer?: APIPaymentCustomer;
};

export const paymentCustomerReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): PaymentCustomerState => {
  switch (action.type) {
    case PAYMENT_CUSTOMER:
      return { ...state, isLoading: true };
    case PAYMENT_CUSTOMER_SUCCESS:
      return { ...state, isLoading: false, customer: action.payload.customer };
    case PAYMENT_CUSTOMER_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case CANCEL_SUBSCRIPTION:
      return { ...state, isLoading: true };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return { ...state, isLoading: false, customer: action.payload.customer };
    case CANCEL_SUBSCRIPTION_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case REACTIVATE_SUBSCRIPTION:
      return { ...state, isLoading: true };
    case REACTIVATE_SUBSCRIPTION_SUCCESS:
      return { ...state, isLoading: false, customer: action.payload.customer };
    case REACTIVATE_SUBSCRIPTION_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case UPDATE_PAYMENT_DETAILS:
      return { ...state, isLoading: true };
    case UPDATE_PAYMENT_DETAILS_SUCCESS:
      return { ...state, isLoading: false, customer: action.payload.customer };
    case UPDATE_PAYMENT_DETAILS_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    default:
      return state;
  }
};

type CardState = {
  name?: string;
  email?: string;
};

export const cardReducer = (state = {}, action: RootAction): CardState => {
  switch (action.type) {
    case SET_CARD: {
      const { name, email } = action.payload;
      return { ...state, name, email };
    }
    default:
      return state;
  }
};

export const paymentsReducer = combineReducers({
  oauth: oAuthReducer,
  confirm: confirmReducer,
  savedAccount: savedAccountReducer,
  customer: customerReducer,
  stripe: stripeReducer,
  subscription: subscriptionReducer,
  paymentCustomer: paymentCustomerReducer,
  card: cardReducer,
  availableSubscriptions: availableSubscriptionsReducer,
  plans: plansReducer,
  planCheckout: planCheckoutReducer,
  canCreateDiscussion: canCreateDiscussionReducer,
});

// selectors
export const getOAuthState = (state: AppState) => state.payments.oauth;
export const getConfirmOAuthState = (state: AppState) => state.payments.confirm;
export const getSavedAccountState = (state: AppState) => state.payments.savedAccount;
export const getTerms = (state: AppState) => state.payments.savedAccount.data?.terms;
export const getBillingCustomerState = (state: AppState) => state.payments.customer;
export const getStripeState = (state: AppState) => state.payments.stripe;
export const getSubscriptionState = (state: AppState) => state.payments.subscription;
export const getPaymentCustomer = (state: AppState) => state.payments.paymentCustomer;
export const getCardBillingDetails = (state: AppState) => state.payments.card;
export const getTenantSubscriptionPlanId = (state: AppState) =>
  state.payments.subscription.subscription?.planId;

export const getAllAvailableSubscriptions = (state: AppState) =>
  state.payments.availableSubscriptions;
export const getAllPlans = (state: AppState) => state.payments.plans;
export const getPlanCheckout = (state: AppState) => state.payments.planCheckout;
export const getPlan = (state: AppState, planId?: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!planId) return undefined;
  const plans = getAllPlans(state);
  if (!plans.plans) return undefined;
  return plans.plans.find(p => p.id === planId);
};

export const getActiveSubscription = (state: AppState) => {
  const subscriptions = state.payments.paymentCustomer.customer?.subscriptions.data;
  if (!subscriptions) return undefined;
  if (subscriptions.length === 0) return undefined;
  const subscription = subscriptions[0];
  return subscription;
};
export const canCreateDiscussionState = (state: AppState) =>
  state.payments.canCreateDiscussion;

export const getProduct = (state: AppState, productId?: string) => {
  if (!productId) return undefined;
  const plans = getAllPlans(state);
  if (!plans.plans) return undefined;

  const plan = plans.plans.find(plan => productId === plan.product.id);
  return plan?.product;
};
