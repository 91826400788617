import { combineEpics } from 'redux-observable';
import { filter, switchMap, map, takeUntil, catchError, tap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { object } from 'rxfire/database';
import { combineLatest, from } from 'rxjs';

import { SIGN_OUT } from '../User';
import { catchHandler } from '../utils';
import i18n, { getDefaultLanguageFromLocalStorage } from '../../singletons/i18n';
import { getIsMentor } from '../Auth';

import { serverDataChanged, publicDataReceived } from './actions';
import { SERVER_DATA_SUBSCRIBED, PUBLIC_DATA_SUBSCRIBED } from './actionTypes';

import { RootEpic } from '..';

const snapShot$ = <T>(ref: firebase.database.Query, key: string) =>
  object(ref).pipe(map(({ snapshot }) => ({ key, value: snapshot.val() as T })));

export const serverSubscribed: RootEpic = (action$, state$, { db, database }) =>
  action$.pipe(
    filter(isOfType(SERVER_DATA_SUBSCRIBED)),
    switchMap(() => {
      const observables = [
        from(db.topicRef()).pipe(switchMap(ref => snapShot$(ref, 'topics'))),
        database.announcements
          .get(getIsMentor(state$.value) ? 'mentors' : 'students')
          .pipe(map(announcement => ({ key: 'announcement', value: announcement }))),
      ];
      return combineLatest(observables).pipe(
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
        catchError(catchHandler),
      );
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map((d: any[]) =>
      serverDataChanged(d.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {})),
    ),
  );

export const publicSubscribed: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(PUBLIC_DATA_SUBSCRIBED)),
    switchMap(() =>
      database.getPublic().pipe(
        map(value => publicDataReceived(value)),
        tap(action => {
          if (
            action.payload.defaultLanguage?.lang &&
            getDefaultLanguageFromLocalStorage() === null
          ) {
            const language = action.payload.defaultLanguage?.lang;
            i18n.changeLanguage(language);
          }
        }),
        catchError(catchHandler),
      ),
    ),
  );

export const serverRootEpic = combineEpics(serverSubscribed, publicSubscribed);
