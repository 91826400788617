import React, { useState } from 'react';
import { Pagination, Row, Col } from 'antd';

import { getUserRatings } from '../../modules';
import { useTypedSelector } from '../../hooks';

import UserFeedbackItem from './UserFeedbackItem';
import ReportsLoader from './ReportsLoader';
import EmptyReport from './EmptyReport';

export default () => {
  const { isLoading, allComments } = useTypedSelector(getUserRatings);

  const [page, setPage] = useState(1);

  const numItemsPerPage = 10;
  const total = allComments ? allComments.length : 0;

  const startIndex = (page - 1) * numItemsPerPage;
  const endIndex =
    startIndex + numItemsPerPage > total ? total : startIndex + numItemsPerPage;

  const currentComments = allComments
    ? [...allComments].splice(startIndex, endIndex)
    : [];
  if (!allComments || (allComments && allComments.length === 0)) return <EmptyReport />;

  return (
    <div>
      {isLoading && <ReportsLoader />}
      {!isLoading && (
        <Row style={{ padding: '0px 60px' }}>
          <Col span={12}>
            {allComments && (
              <UserFeedbackItem
                comments={currentComments.slice(0, Math.ceil(currentComments.length / 2))}
              />
            )}
          </Col>
          <Col span={12}>
            {allComments && (
              <UserFeedbackItem
                comments={currentComments.slice(
                  Math.ceil(currentComments.length / 2),
                  currentComments.length,
                )}
              />
            )}
          </Col>
          <Col span={24}>
            <Row justify={'end'}>
              <Pagination current={page} onChange={setPage} total={total} />
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};
