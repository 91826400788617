import React, { useEffect } from 'react';

import { useTypedSelector } from '../hooks';
import { getIsSignedIn } from '../modules';

const FirstTimeLoginProvider = ({ children }: { children: React.ReactChild }) => {
  const isSignedIn = useTypedSelector(getIsSignedIn);

  useEffect(() => {
    const secondTimeAad = localStorage.getItem('@snapmentor/secondTimeAad');
    if (isSignedIn && secondTimeAad === null) {
      localStorage.setItem('@snapmentor/secondTimeAad', 'seen');
    }
  }, [isSignedIn]);

  return <>{children}</>;
};

export default FirstTimeLoginProvider;
