import React from 'react';
export interface Props {
  color: string;
  size?: number;
}
const Key = (props: Props) => {
  const { color, size = 24 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="24 / security / key">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 9C8 5.13401 11.134 2 15 2C18.866 2 22 5.13401 22 9C22 12.866 18.866 16 15 16H13V18H11V20H9V22H2V16.5858L8.14801 10.4378C8.04995 9.96847 8 9.48731 8 9ZM11 16V14H15C17.7614 14 20 11.7614 20 9C20 6.23858 17.7614 4 15 4C12.2386 4 10 6.23858 10 9C10 9.49863 10.0727 9.98638 10.2141 10.4529L10.3879 11.0263L4 17.4142V20H7V18H9V16H11ZM13 9C13 10.1046 13.8954 11 15 11C16.1046 11 17 10.1046 17 9C17 7.89543 16.1046 7 15 7C13.8954 7 13 7.89543 13 9Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Key;
