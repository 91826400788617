import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Button, notification } from 'antd';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import icon from '../assets/images/icon.png'; // Tell Webpack this JS file uses this image
import theme from '../theme';
import { useMetrics, useTypedSelector } from '../hooks';
import { updateServiceWorker, getServiceWorker } from '../modules';

const ADD_2_HOME_SCREEN = 'a2hs';

interface UserChoice {
  timestamp: number;
  userChoice: boolean;
}

export const PwaInstallProvider = ({ children }: { children: React.ReactChild }) => {
  const { t } = useTranslation('pwa');
  const { isNewVersionAvailable, isUpdating } = useTypedSelector(getServiceWorker);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const eventRef = useRef<any>();
  const metrics = useMetrics();

  const dispatch = useDispatch();
  useEffect(() => {
    if (isNewVersionAvailable && !isUpdating) {
      const key = `open-isNewVersionAvailable`;
      const onSuccess = () => {
        dispatch(updateServiceWorker());
        metrics.logEvent('Upgrade.Accepted');
        notification.close(key);
      };
      const btn = (
        <Button type="primary" size="small" onClick={onSuccess}>
          {t('Upgrade now')}
        </Button>
      );
      notification.open({
        message: t('Snapmentor update'),
        description: t('updateText', {
          defaultValue:
            'We just released a new version for you. Click to upgrade. It only takes 1.3 seconds ;)',
        }),
        btn,
        duration: 30,
        key,
        onClick: onSuccess,
        onClose: () => {
          metrics.logEvent('Upgrade.Declined');
          notification.close(key);
        },
      });
    }
  }, [dispatch, isNewVersionAvailable, metrics, t, isUpdating]);

  const [showPrompt, setShowPrompt] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onInstall = (e: any) => {
      // For older browsers
      e.preventDefault();
      // TODO ADD METRIC
      // See if the app is already installed, in that case, do nothing
      if (
        (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
        window.navigator.standalone === true
      ) {
        return false;
      }
      eventRef.current = e;
      // Set the state variable to make button visible
      const userChoice = localStorage.getItem(ADD_2_HOME_SCREEN);
      if (!userChoice) {
        setTimeout(() => {
          setShowPrompt(true);
        }, 2000);
      } else {
        const choiceObj = JSON.parse(userChoice!) as UserChoice;
        if (moment().valueOf() > choiceObj.timestamp) {
          setTimeout(() => {
            setShowPrompt(true);
          }, 2000);
        }
      }
    };

    const appinstalled = () => {
      metrics.logEvent('appinstalled');
    };
    window.addEventListener('beforeinstallprompt', onInstall);
    window.addEventListener('appinstalled', appinstalled);
    return () => {
      window.removeEventListener('beforeinstallprompt', onInstall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const installPwa = () => {
    if (eventRef) {
      eventRef.current.prompt();
      setShowPrompt(false);
    }
  };

  const onClose = () => {
    const timeStamp = moment()
      .add(2, 'days')
      .valueOf();
    localStorage.setItem(
      ADD_2_HOME_SCREEN,
      JSON.stringify({ timestamp: timeStamp, userChoice: false }),
    );
    metrics.logEvent('appNotInstalled');
    setShowPrompt(false);
  };

  return (
    <>
      {children}
      {showPrompt && (
        <InstallContainer>
          <Information>
            <Image src={icon} />
            <TextContainer>
              <h3>Snapmentor</h3>
              <p>{window.location.origin}</p>
            </TextContainer>
            <div role="button" style={{ flex: 0 }} onClick={onClose}>
              <CloseOutlined />
            </div>
          </Information>
          <Button type="primary" onClick={installPwa}>
            {t('Install the app')}
          </Button>
        </InstallContainer>
      )}
    </>
  );
};

const Image = styled.img`
  height: 50px;
  width: 50px;
  flex: 0;
`;

const InstallContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.4);
  @media screen and (min-width: 500px) {
    width: 400px;
    bottom: 10%;
  }
`;

const Information = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  flex: 1;
  padding: 0 10px;
`;

export default PwaInstallProvider;
