import { DB, Message, AdditionalInfo, ChatRoomEntity } from '../db';
import Database from '../database';
import Files from '../files';
import { CreateDiscussionState } from '../../modules';

const toInitialChatRoom = (
  userId: string,
  additionalInfo: AdditionalInfo,
): ChatRoomEntity => ({
  userId,
  isComplete: false,
  additionalInfo,
  claimedAt: null,
});

export default class Discussions {
  constructor(private oldDB: DB, private newDB: Database, private files: Files) {}

  // Read from NEW
  // Write to OLD AND NEW

  public async create(
    state: CreateDiscussionState,
    additionalInfo: AdditionalInfo,
    uid: string,
  ) {
    // Create the id for the chatroom
    const id = await this.oldDB.createChatRoomId();
    // Create an initial chatroom DTO
    const chatRoom = toInitialChatRoom(uid, additionalInfo);

    const messages: Message[] = [];

    // Find an image message and upload before creating the discussion
    if (state.imageId) {
      const imageId = new Date().getTime().toString();
      await this.files.uploadDataUrlImage(imageId, id, state.imageId);
      messages.push({
        createdBy: uid,
        imageId,
        timeStamp: new Date().getTime(),
      });
    }

    // Add the text message
    messages.push({
      createdBy: uid,
      text: state.textMessage,
      timeStamp: new Date().getTime(),
    });

    const chat: ChatRoomEntity = {
      ...chatRoom,
      messages: messages.reduce((acc, cur, i) => ({ ...acc, [i]: cur }), {}),
    };
    // Finally create the chatrom in the old database
    await this.oldDB.addChatRoom(chat, id);

    const chatCopy = { ...chat };
    delete chatCopy.messages;
    const chatMetaData = chatCopy;
    await this.newDB.discussions.create(id, chatMetaData, messages);
    return { id };
  }

  public async sendMessage(message: Message, discussionId: string) {
    message.timeStamp = new Date().getTime();
    const { id } = await this.oldDB.sendMessage(message, discussionId);
    await this.newDB.discussions.sendMessage(discussionId, { ...message, id });
  }

  public async sendMessageWithImage(discussionId: string, uid: string, data: string) {
    const imageId = new Date().getTime().toString();
    await this.files.uploadDataUrlImage(imageId, discussionId, data);
    await this.sendMessage(
      {
        createdBy: uid,
        imageId,
      },
      discussionId,
    );
  }

  public async resolve(discussionId: string, completedBy: string) {
    await this.newDB.discussions.resolve(discussionId, completedBy);
    await this.oldDB.setChatRoomCompleted(discussionId, completedBy);
  }

  public listDiscussions(uid: string, discussionIds: string[]) {
    return this.newDB.discussions.listDiscussions(discussionIds);
  }

  public subscribe(discussionId: string) {
    return this.newDB.discussions.subscribe(discussionId);
  }

  liveStats = {
    completed: () => this.newDB.discussions.getCompletedDiscussions(),
    inComplete: () => this.newDB.discussions.getInCompletedDiscussions(),
    unClaimed: () => this.newDB.discussions.getUnClaimedDiscussions(),
  };
}
