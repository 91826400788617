import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Tabs } from '../../_atoms/Tabs';
import { ChatRoomList } from '../../_molecules/ChatRoomList';
import {
  ActiveChatRoom,
  FinishedChatRoom,
  UIDiscussion,
  getNickName,
  getActiveTabId,
  selectTab,
  getServiceName,
} from '../../../modules';
import { ActiveDiscussionCard } from '../../_molecules/ActiveDiscussionCard';
import { CenteredContainer } from '../../../styled';
import { useTypedSelector } from '../../../hooks';
import DynamicVirtualList from '../../_molecules/DynamicVirtualList/DynamicVirtualList';
type Props = {
  activeItems: ActiveChatRoom[];
  finishedItems: FinishedChatRoom[];
  onChatRoomSelected: (chatRoom: UIDiscussion) => void;
  selectedChatRoom?: UIDiscussion;
};
export const ChatRoomsOverview = (props: Props) => {
  const { activeItems, finishedItems, selectedChatRoom, onChatRoomSelected } = props;
  const activeTabId = useTypedSelector(getActiveTabId);
  const serviceName = useTypedSelector(getServiceName);
  const dispatch = useDispatch();
  const nickName = useTypedSelector(getNickName);
  const { t } = useTranslation('home');
  const EmptyState = () => (
    <EmptyStateContainer>
      <h1>
        {t('Welcome to {{serviceName}}, {{name}}!', {
          name: nickName || '',
          serviceName,
        })}
      </h1>
      <p>
        {t('noActive', {
          defaultValue:
            "You don't have any active discussions at the moment. Start a new one!",
        })}
      </p>
    </EmptyStateContainer>
  );

  return (
    <>
      <TabsContainer>
        <Tabs active={activeTabId} setActive={tabId => dispatch(selectTab(tabId))}></Tabs>
      </TabsContainer>
      <ScrollingContainer>
        {activeTabId === 0 && (
          <ChatRoomList<ActiveChatRoom>
            items={activeItems}
            active
            selected={selectedChatRoom}
            onChatRoomSelected={onChatRoomSelected}
            renderItem={({
              id,
              name,
              latestMessage,
              initialMessage,
              avatarColor,
              selected,
            }) => (
              <ActiveDiscussionCard
                name={name}
                avatarColor={avatarColor}
                chatRoomId={id}
                initialMessage={initialMessage}
                latestMessage={latestMessage}
                active={selected}
              />
            )}
            emptyState={<EmptyState />}
          />
        )}
        {activeTabId === 1 && (
          <DynamicVirtualList
            items={finishedItems}
            selected={selectedChatRoom}
            onChatRoomSelected={onChatRoomSelected}
            emptyState={<EmptyState />}
          />
        )}
      </ScrollingContainer>
    </>
  );
};

export default ChatRoomsOverview;

const TabsContainer = styled.div`
  flex: 0 auto;
`;

const ScrollingContainer = styled.div`
  display: flex;
  min-height: 0;
  width: 100%;
  flex: auto;
  overflow-y: auto;
`;

const EmptyStateContainer = styled(CenteredContainer)`
  flex: auto;
  flex-direction: column;
  padding: 40px;
  text-align: center;
  h1: {
    color: #364045;
  }
  p {
    color: #c4c4c4;
  }
`;
