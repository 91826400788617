export const SELECT_TOPIC = 'CREATE_DISCUSSION/SELECT_TOPIC';
export const SELECT_SUBTOPIC = 'CREATE_DISCUSSION/SELECT_SUBTOPIC';
export const ADD_TEXT_MESSAGE = 'CREATE_DISCUSSION/ADD_TEXT_MESSAGE';
export const ADD_IMAGE = 'CREATE_DISCUSSION/ADD_IMAGE';
export const INIT = 'CREATE_DISCUSSION/INIT';
export const NEXT_STEP = 'CREATE_DISCUSSION/NEXT_STEP';
export const PREVIOUS_STEP = 'CREATE_DISCUSSION/PREVIOUS_STEP';
export const START_DISCUSSION = 'CREATE_DISCUSSION/START_DISCUSSION';
export const START_DISCUSSION_FAILED = 'CREATE_DISCUSSION/START_DISCUSSION_FAILED';
export const START_DISCUSSION_SUCCESS = 'CREATE_DISCUSSION/START_DISCUSSION_SUCCESS';
