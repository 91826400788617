import React from 'react';
import { Layout } from 'antd';

import { DesktopContainer } from '../DesktopContainer';
import theme from '../../../theme';
const { Sider, Content } = Layout;

type Props = {
  renderSideContent: () => React.ReactNode;
  renderMainContent: () => React.ReactNode;
};
export const AdminLayout = (props: Props) => {
  const { renderSideContent, renderMainContent } = props;
  return (
    <DesktopContainer>
      <Layout style={{ height: '100%' }}>
        <Sider
          width={'20%'}
          style={{
            backgroundColor: theme.colors.orange,
            height: '100%',
          }}
        >
          {renderSideContent()}
        </Sider>
        <Layout>
          <Content>{renderMainContent()}</Content>
        </Layout>
      </Layout>
    </DesktopContainer>
  );
};
