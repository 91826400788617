const colors = {
  green: '#008089',
  lightGrey: '#CFD6D7',
  yellow: '#E2A816',
  grey6: '#F2F2F2',
  greenGrey: '#F2F5F5',
  grey3: '#828282',
  googleRed: '#EA4335',
  orange: '#F99746',
  mainBackground: '#F9F8F6',
  tableBlue: '#a1c6da',
  pastelBlue: '#A1C6DA',
  pastelBrown: '#CCC3BA',
  pastelGreen: '#9EBEB8',
  pastelBeige: '#F0CFC4',
  pastelPurple: '#BE9EBB',
  pastelYellow: '#F0E1C4',
  purple: '#9EA2BE',
};

export const theme = {
  colors: {
    orange: colors.orange,
    primary: colors.green,
    purple: colors.purple,
    tertiary: colors.yellow,
    grey: colors.lightGrey,
    white: '#fff',
    black: '#000000',
    background: {
      light: colors.mainBackground,
    },
    lightGrey: colors.grey6,
    navigationGrey: colors.greenGrey,
    chatGrey: '#f8f8f8',
    iconGrey: colors.grey3,
    googleRed: colors.googleRed,
    feideBlue: '#1F4698',
    tableHeader: colors.tableBlue,
    text: {
      light: '#bdbdbd',
      dark: '#4F4F4F',
      medium: '#A9A8A8',
      darkGrey: '#737373',
      grey: '#8C8C8C',
    },
    newChatGrey: '#D3D3D3',
    chart: {
      p95: colors.pastelBlue,
      p99: colors.pastelGreen,
      avg: colors.orange,
      globalP95: colors.pastelBrown,
      globalP99: colors.pastelPurple,
    },
    avatar: [
      colors.pastelGreen,
      colors.pastelPurple,
      colors.pastelBrown,
      colors.pastelBlue,
      colors.pastelBeige,
      colors.pastelYellow,
    ],
  },
};

export default theme;
