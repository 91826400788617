import { combineEpics } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { switchMap, mergeMap, filter, catchError, tap, map } from 'rxjs/operators';
import { from, empty } from 'rxjs';
import { AuthenticationActions } from 'react-aad-msal';

import { catchHandler } from '../utils';
import { getLoginAuthority, getDefaultLanguage, PUBLIC_DATA_RECEIVED } from '../Server';
import { Metrics } from '../../services/metrics';
import { SIGN_OUT } from '../User';

import { onResetPasswordRedirect } from './actions';
import { getLoginHint } from './reducer';
import { getCustomTokenCache, setCustomTokenCache, clearCustomTokenCache } from './utils';

import { RootEpic } from '..';

const getCustomTokenEpic: RootEpic = (action$, state$, { backendService, auth }) =>
  action$.pipe(
    filter(isOfType(AuthenticationActions.LoginSuccess)),
    switchMap(({ payload: { jwtIdToken } }) => {
      const cachedIdToken = getCustomTokenCache();
      if (cachedIdToken && cachedIdToken.aadAccessToken === jwtIdToken) {
        return from(auth.signInWithCustomToken(cachedIdToken.fbCustomToken)).pipe(
          mergeMap(() => empty()),
        );
      } else {
        return backendService.getCustomToken().pipe(
          tap(jwt => {
            setCustomTokenCache({
              fbCustomToken: jwt.customToken,
              aadAccessToken: jwtIdToken,
              loginHint: getLoginHint(state$.value),
            });
          }),
          mergeMap(jwt => from(auth.signInWithCustomToken(jwt.customToken))),
          mergeMap(() => empty()),
          catchError(catchHandler),
        );
      }
    }),
    catchError(catchHandler),
  );

const handlePasswordResetErrorEpic: RootEpic = (action$, _) =>
  action$.pipe(
    filter(isOfType(AuthenticationActions.LoginError)),
    map(({ payload: { errorMessage } }) => {
      if (errorMessage.includes('AADB2C90118')) {
        setTimeout(() => {
          window.location.href = `${window.location.origin}/forgot-password`;
        }, 500);
      }
      return onResetPasswordRedirect();
    }),
    catchError(catchHandler),
  );

const handleTokenRefreshError: RootEpic = (action$, state$, { auth }) =>
  action$.pipe(
    filter(isOfType(AuthenticationActions.AcquiredIdTokenError)),
    tap(({ payload: { errorCode } }) => {
      // Catch if the token failed to be renewed. Then send the user to the idp selector.
      if (errorCode === 'token_renewal_error') {
        const counter = localStorage.getItem('@snapmentor/token_renewal_error') || '0';
        localStorage.setItem('@snapmentor/token_renewal_error', `${Number(counter) + 1}`);
        Metrics.getLogger().logEvent('TokenRenewal.Error', { counter });
        if (Number(counter) < 3) {
          // If we have sent the user three times to the idp without the user being able to login,
          // let the user see the error and have them select login.
          auth.getAuthProvider().login({
            loginHint: getLoginHint(state$.value),
            authority:
              localStorage.getItem('@snapmentor/lastLoginProvider') ||
              getLoginAuthority(state$.value),
            extraQueryParameters: {
              // eslint-disable-next-line @typescript-eslint/camelcase
              ui_locales: getDefaultLanguage(state$.value) || 'en',
            },
          });
        } else {
          Metrics.getLogger().logEvent('TokenRenewal.Skip');
        }
      }
    }),
    mergeMap(() => empty()),
    catchError(catchHandler),
  );

const resetAuthLoopEpic: RootEpic = action$ =>
  action$.pipe(
    filter(isOfType(AuthenticationActions.LoginSuccess)),
    tap(() => {
      localStorage.setItem('@snapmentor/token_renewal_error', `0`);
    }),
    mergeMap(() => empty()),
    catchError(catchHandler),
  );

export const onPublicDataReceivedStoreSignInPolicy: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isOfType([PUBLIC_DATA_RECEIVED])),
    tap(() => {
      const policy = getLoginAuthority(state$.value);
      localStorage.setItem('@snapmentor/lastLoginProvider', policy);
    }),
    mergeMap(() => empty()),
    catchError(catchHandler),
  );

export const onSignOutClearAuthCache: RootEpic = action$ =>
  action$.pipe(
    filter(isOfType([SIGN_OUT])),
    tap(() => {
      clearCustomTokenCache();
      localStorage.removeItem('@snapmentor/lastLoginProvider');
      localStorage.removeItem('@snapmentor/token_renewal_error');
    }),
    mergeMap(() => empty()),
  );

export const rootAuthEpic = combineEpics(
  getCustomTokenEpic,
  handlePasswordResetErrorEpic,
  handleTokenRefreshError,
  onPublicDataReceivedStoreSignInPolicy,
  resetAuthLoopEpic,
  onSignOutClearAuthCache,
);
