import React, { useState, useEffect } from 'react';
import { Form, Input, Button, InputNumber } from 'antd';

import { AvailableSubscriptionRef } from '../../../services/database/types';
import { ById } from '../../../modules';

type FieldData = {
  name: string;
  value?: string | number;
  touched: boolean;
  validating: boolean;
  errors: string[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FieldsToProduct = (fields: ById<any>): AvailableSubscriptionRef => {
  const product: AvailableSubscriptionRef = { productId: '', priceIds: [] };
  const values = Object.entries(fields);
  values.forEach(([name, value]) => {
    if (name === 'productId') product.productId = value as string;
    if (name.includes('price')) product.priceIds.push(value as string);
    if (name === 'unit-amount')
      product.unit = value ? { amount: value as number, type: 'minutes' } : undefined;
  });

  return product;
};

const ProductToFields = (product: AvailableSubscriptionRef): FieldData[] => {
  const fields: FieldData[] = [];
  fields.push({
    name: 'productId',
    value: product.productId,
    errors: [],
    touched: false,
    validating: false,
  });
  if (product.priceIds.length > 0) {
    product.priceIds.forEach(price => {
      fields.push({
        name: `price-${price}`,
        value: price,
        errors: [],
        touched: false,
        validating: false,
      });
    });
  } else {
    fields.push({
      name: `price-0`,
      value: '',
      errors: [],
      touched: false,
      validating: false,
    });
  }

  fields.push({
    name: `unit-amount`,
    value: product.unit?.amount,
    errors: [],
    touched: false,
    validating: false,
  });

  return fields;
};

type Props = {
  product: AvailableSubscriptionRef;
  onEdit: (product: AvailableSubscriptionRef) => void;
};
export default ({ product, onEdit }: Props) => {
  const [fields, setFields] = useState(ProductToFields(product));

  useEffect(() => {
    setFields(ProductToFields(product));
  }, [product]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFinish = (values: ById<any>) => {
    const output = FieldsToProduct(values);
    onEdit(output);
  };

  return (
    <Form
      fields={fields}
      onFinish={handleFinish}
      onValuesChange={change => {
        const newFields = fields.map(f =>
          f.name === Object.keys(change)[0]
            ? { ...f, value: Object.values(change)[0] }
            : f,
        );
        setFields(newFields);
      }}
    >
      <Form.Item
        name="productId"
        label="Product Id"
        rules={[{ required: true, message: 'Product id is required!' }]}
      >
        <Input />
      </Form.Item>
      {fields
        .filter(field => field.name.includes('price'))
        .map(priceField => (
          <Form.Item key={priceField.name} name={priceField.name} label="Price Id">
            <Input />
          </Form.Item>
        ))}
      <Form.Item name={'unit-amount'} label="Unit amount (minutes)">
        <InputNumber min={0} />
      </Form.Item>
      <Button
        type="ghost"
        style={{ marginBottom: 10, borderRadius: 20 }}
        onClick={e => {
          e.preventDefault();
          setFields([
            ...fields,
            {
              name: `price-${fields.length + 1}`,
              validating: false,
              errors: [],
              touched: false,
              value: '',
            },
          ]);
        }}
      >
        + Add price
      </Button>
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          style={{ marginBottom: 10, borderRadius: 20, padding: '0 60px' }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
