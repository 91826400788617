import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FloatingActionButton } from '../../_atoms/FloatingActionButton';
import { Message } from '../../../services/db';
import { sendMessage, uploadImage, setDiscussionLiveInfo } from '../../../modules';
import FileSelector from '../../FileSelector';
import { Icons } from '../../Icons';

type Props = {
  uid: string;
  chatRoomId: string;
};
export const MessageController = (props: Props) => {
  const { uid, chatRoomId } = props;
  const dispatch = useDispatch();
  const [msg, setMsg] = useState('');
  const { t } = useTranslation('chatroom');
  const handleSendClicked = () => {
    setMsg('');
    const newMessage: Message = { createdBy: uid, text: msg };
    dispatch(sendMessage(newMessage, chatRoomId));
    dispatch(setDiscussionLiveInfo(chatRoomId, false));
  };

  const handleImageFileSelected = (imgDataUrl: string) => {
    dispatch(uploadImage(chatRoomId, imgDataUrl, uid));
  };
  return (
    <Container>
      <InputContainer>
        <Input
          prefix={
            <FileSelector onFileSelected={handleImageFileSelected}>
              <IconContainer>
                <Icons.Paperclip />
              </IconContainer>
            </FileSelector>
          }
          size="small"
          placeholder={t('Type a message')}
          value={msg}
          onPressEnter={handleSendClicked}
          onBlur={() => dispatch(setDiscussionLiveInfo(chatRoomId, false))}
          onChange={e => {
            dispatch(setDiscussionLiveInfo(chatRoomId, e.target.value.length > 0));
            setMsg(e.target.value);
          }}
        />
      </InputContainer>
      <ButtonContainer>
        <FloatingActionButton active={false} onClick={handleSendClicked} icon="Send2" />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: transparent;
  padding: 0 20px 20px 20px;
  display: flex;
  align-items: center;
  width: 100%;
`;
const ButtonContainer = styled.div`
  flex: 0;
  margin-left: 10px;
`;

const InputContainer = styled.div`
  flex: 1;
  /* border-radius: 50px;
  padding: 10px 10px 10px 70px;
  height: 48px;
  border: none;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25); */
  .ant-input-affix-wrapper {
    border-radius: 50px;
    border: none;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .ant-input {
    margin-right: 20px;
    margin-left: 10px;
  }
  .ant-input-prefix {
    height: 50px;
    width: 50px;
    margin-left: 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
  width: 85%;
  border-radius: 50px;
  background-color: transparent;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #c4c4c4;
  &:active {
    background-color: rgba(0, 0, 0, 0.25);
    color: #ffffff;
  }
  & svg {
    width: 20px;
    height: 20px;
  }
  cursor: pointer;
`;
