import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { ResultCard } from '../_molecules/ResultCard';
import { GroupedAggregateCard } from '../_molecules/GroupedAggregateCard';
import {
  getAvailableMentors,
  getTotalMentors,
  getInCompleteChatRoomsCount,
  getCompletedChatRoomsCount,
  getUnClaimedChatRoomsCount,
  getAvailableMentorsByTopic,
  getTotalMentorsByTopic,
  getUnClaimedChatRoomsByTopic,
  getCancelledChatRoomsCount,
  getInQueueChatRoomsCount,
} from '../../modules';
import { useTypedSelector } from '../../hooks';
import { SectionTitle } from '../_atoms/SectionTitle';

export const AdminOverview = () => {
  const { t } = useTranslation('Admin');
  const availableMentors = useTypedSelector(getAvailableMentors);
  const totalMentors = useTypedSelector(getTotalMentors);
  const inQueueChatRoomsCount = useTypedSelector(getInQueueChatRoomsCount);
  const inCompleteChatRoomsCount = useTypedSelector(getInCompleteChatRoomsCount);
  const completedDiscussionsCount = useTypedSelector(getCompletedChatRoomsCount);
  const unclaimedDiscussionsCount = useTypedSelector(getUnClaimedChatRoomsCount);

  const availableMentorsByTopic = useTypedSelector(getAvailableMentorsByTopic);
  const allMentorsByTopic = useTypedSelector(getTotalMentorsByTopic);
  const unClaimedChatRoomsByTopic = useTypedSelector(getUnClaimedChatRoomsByTopic);
  const cancelledChatRoomsCount = useTypedSelector(getCancelledChatRoomsCount);
  return (
    <Row style={{ padding: '40px 60px' }}>
      <Col span={24}>
        <SectionTitle>{t('Sessions')}</SectionTitle>
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={6}>
            <ResultCard
              title={t('In queue')}
              value={inQueueChatRoomsCount}
              tooltipText={t('inQueueDescription', {
                defaultValue: 'Discussions currently waiting for a mentor to be assigned',
              })}
            />
          </Col>
          <Col span={6}>
            <ResultCard
              title={t('In progress')}
              value={inCompleteChatRoomsCount}
              tooltipText={t('inCompleteDescription', {
                defaultValue: 'Ongoing discussions between a mentor and a student',
              })}
            />
          </Col>
          <Col span={6}>
            <ResultCard
              title={t('Completed')}
              value={completedDiscussionsCount}
              tooltipText={t('completedDescription', {
                defaultValue:
                  'Discussions that have been completed either by the student or the mentor',
              })}
            />
          </Col>
          <Col span={6}>
            <ResultCard
              title={t('Cancelled')}
              value={cancelledChatRoomsCount}
              tooltipText={t('cancelledDescription', {
                defaultValue:
                  'Discussions that was cancelled by the student because no mentor was assigned',
              })}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <SectionTitle>{t('Mentors')}</SectionTitle>
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={12}>
            <GroupedAggregateCard
              title={t('Available')}
              headerColor={'#7FD4DA'}
              total={availableMentors.length}
              items={availableMentorsByTopic}
            />
          </Col>
          <Col span={12}>
            <GroupedAggregateCard
              title={t('Total')}
              total={totalMentors.length}
              headerColor={'#7FD4DA'}
              items={allMentorsByTopic}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <SectionTitle>{t('Students')}</SectionTitle>
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={12}>
            <GroupedAggregateCard
              title={t('in Queue')}
              headerColor={'#9ea2be'}
              total={unclaimedDiscussionsCount || 0}
              items={unClaimedChatRoomsByTopic}
            />
          </Col>
          <Col span={12}></Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AdminOverview;
