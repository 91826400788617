import { LoginProviders } from '../modules';

export function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const hasBothEmailAndOtherProvider = (providers: LoginProviders) => {
  const p = { ...providers };
  delete p.email;
  return Object.values(p).filter(p => p === true).length > 0 && providers.email;
};
