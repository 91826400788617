// export const is_opera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
// export const is_Edge = navigator.userAgent.indexOf("Edge") > -1;
// export const is_chrome = !!window.chrome && !is_opera && !is_Edge;
// export const is_explorer= typeof document !== 'undefined' && !!document.documentMode && !is_Edge;
// export const is_firefox = typeof window.InstallTrigger !== 'undefined';
import { config } from '../config/environments';
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isIos = !!navigator.userAgent.match(/iPad|iPhone|iPod/i);

export function isFacebookApp() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ua: any =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigator.userAgent || navigator.vendor || (window as any).opera;
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
}

export const getTenantIdFromSubdomain = (url: string) => {
  if (url.includes('localhost')) return config.fallbackTenant;
  const [origin] = url.split('.');
  const [, tenantId] = origin.split('https://');
  return tenantId;
};
