import React from 'react';
export interface HomeProps {
  color: string;
  size: number;
}
const Plus = (props: HomeProps) => {
  const { color, size = 24 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="24 / basic / plus">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 11H22V13H13V22H11V13H2V11H11V2H13V11Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Plus;
