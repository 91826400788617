export const REVIEWS_SUBSCRIBED = 'REVIEWS/SUBSCRIBED';
export const REVIEWS_UPDATED = 'REVIEWS/UPDATED';
export const PROMPT_REVIEW = 'REVIEWS/PROMPT';
export const REVIEW_SEEN = 'REVIEWS/SEEN';

export const RATING_SEND = 'REVIEWS/RATING_SEND';
export const RATING_SEND_SUCCESS = 'REVIEWS/RATING_SEND_SUCCESS';
export const RATING_SEND_ERROR = 'REVIEWS/RATING_SEND_ERROR';

export const SEND_GENERAL_USER_FEEDBACK = 'REVIEWS/SEND_GENERAL_USER_FEEDBACK';

export const TAGS_SEARCH = 'REVIEWS/TAGS_SEARCH';
export const TAGS_SEARCH_SUCCESS = 'REVIEWS/TAGS_SEARCH_SUCCESS';

export const FETCH_TEMPLATE = 'REVIEWS/FETCH_TEMPLATE';
export const FETCH_TEMPLATE_ERROR = 'REVIEWS/FETCH_TEMPLATE_ERROR';
export const FETCH_TEMPLATE_SUCCESS = 'REVIEWS/FETCH_TEMPLATE_SUCCESS';
